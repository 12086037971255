import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { Card } from 'react-bootstrap'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GhgToolTip from '../tooltip/GhgTooltip';
import {numberFormat} from "../../services/utilsService";
import { animated } from '@react-spring/web'
import { ResponsiveBar } from '@nivo/bar'
import { getCapitalized, formatNumberPosDecimalRegion } from '../../services/utilsService';


export default function NivoBar({data=[],title,
    pad=1,
    group_mode='grouped',
    show_values=true,
    x_legend="",
    y_legend="",
    x_series=[],
    description,function_click=()=>{}, palette='paired',
height=380, show_legend=true, region=numberFormat()
}){
    let legend_config=[];
    if (show_legend===true){
        legend_config=  [{
            anchor: 'bottom-left',
            direction: 'column',
            justify: false,
            translateX: 0,
            translateY: 0,
            itemWidth: 100,
            itemHeight: 20,
            itemsSpacing: 0,
            symbolSize: 20,
            itemDirection: 'left-to-right'
        }]
    }

    //calculate the total
    let total=0
    data.map((item)=>{
        total+=item['value'];
    });

  return (
    <Card>

        <Card.Body style={{'height':height + 'px'}}>
        <Row>
                <Col md={11} xl={11}>
                <div style={{'textAlign':'left','fontWeight':'bold'}}>
                    <h5>{title}</h5>
                    </div>
                </Col>
                <Col xl={1} md={1} style={{'textAlign':'center'}}>
                  <GhgToolTip title={title}
                  content_explanation={description}
                  />
               </Col>
            </Row>
            <Row style={{marginTop:'10px'}}>
                <Col style={{height:(height - 40) + 'px'}} xs={12} md={12} lg={12}>

                <ResponsiveBar
        data={data}
        keys={x_series}
        indexBy="month_year"
        valueFormat={value =>
                `${Number(((value)).toFixed(2)).toLocaleString(region, {
                    minimumFractionDigits: 2,
                })}`
  
           }
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        groupMode={group_mode}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: palette }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
      
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: x_legend,
            legendPosition: 'middle',
            legendOffset: 32
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: y_legend,
            legendPosition: 'middle',
            legendOffset: -40
        }}
        enableLabel={show_values}
        labelSkipWidth={12}
        labelSkipHeight={12}
       
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
   
       

    />


                </Col>

            </Row>


</Card.Body>
</Card>
  )

}
