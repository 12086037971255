//TODO: Create first version of service
import {getCookie} from 'react-use-cookie';
import moment from 'moment'
const region = getCookie('GHG-MEASURE-SEPARATOR') || 'de-DE';

//Convert general date to standart yyyy-mm-dd format used in the api call
export const convertAPIDateFormat=(value)=>{
    return moment(value).format("YYYY-MM-DD");
}

export const convertDateFormat=(value,format)=>{
    return moment(value).format(format);
}

export const numberFormat=()=>{
    return region;
}
//get the full URL

export const toAbsoluteUrl = (pathname) =>
{

    return process.env.PUBLIC_URL + pathname 
}

//Generate datatable column definition
export const getColDataTable =(record)=>{
    //discover the key for the json record
    let keys = Object.keys(record);
    let columns = [];
    keys.map((item)=>{
        columns.push({'header': item, 'field': item,'sorted':true})
    })
    return columns;

}

//Chart formater auxiliary functions

export const formatNumberinK=(value)=>{
    if (value){
        return Number((value/1000).toFixed(2));
    }else
    {
        return 0;
    }
}

export const formatNumberPosDecimal=(value,decimals)=>{
    if (value){
        return Number((value).toFixed(decimals));
    }else
    {
        return 0;
    }
}

export const formatNumberPosDecimalRegion=(value,decimals)=>{
    if (value){
        return Number((value).toFixed(decimals)).toLocaleString(region, {
            minimumFractionDigits: 0,
        });;
    }else
    {
        return 0;
    }
}





export const getTopRecordsPie=(data_list,top_records, column_id, column_value, label_others)=>{
    let result=[];
    if (data_list.length < top_records){
        top_records = data_list.length;
    }
    
    for(let i = 0; i < top_records-1; i++){
        result.push({label: data_list[i][column_id],
    value: data_list[i][column_value]})
  
   }

   //create a summary of the others records
   let total=0;
   for(let i = 10; i < data_list.length; i++){ 
    total+= data_list[i][column_value];
    }
    result.push({label:label_others, value: total})
    return result
}

//this function convert the origin data into the structure expected by the Apex pie chart
export const convertPieChartData=(data, serie_col, label_col)=>{
    let series=[];
    let label=[];
    data.map((item)=>{
        series.push(item[serie_col]);
        label.push(item[label_col]);
        return true;
    })
    return {series:series, label: label}

}

//Convert to scatter

export const convertScatter=(data,id_field, x_field,y_field)=>{
    let result=[]
    data.map((item)=>{
        result.push({"id":item[id_field],
        data:[
            {"x" :item[x_field],
            "y" :item[y_field]}
        ]
    }
        )

    })
    return result
}



export const convertAreaChartData=(data,serie_column, x_column , y_column)=>{
    let result=[];  
    //get unique series name
    let seriesNames = data.map( (value) => value[serie_column]).filter( (value, index, _arr) => _arr.indexOf(value) === index);
     //create the body
     seriesNames.map((item) =>{
             //data for boty
             let body_data=[]
             data.map((value)=>{     
                if (value[serie_column] === item){
                    //add the record to the array of values
                    body_data.push([value[x_column], value[y_column]])
                }
                return true
             })     
             //add the result
             result.push({name: item, data:body_data})
             return true;
     });
     return result;
}



export const convertLineChartData=(data,serie_column, x_column , y_column, upper_limit= null, lower_limit = null, stroke_width=4)=>{
    let result=[];  
    //get unique series name
    let seriesNames = data.map( (value) => value[serie_column]).filter( (value, index, _arr) => _arr.indexOf(value) === index);
    let x_categories =  data.map( (value) => value[x_column]).filter( (value, index, _arr) => _arr.indexOf(value) === index);  
    let strokes=[];
    //create the body
     seriesNames.map((item) =>{
             strokes.push(stroke_width)
             //data for body
             let body_data=[]
             data.map((value)=>{     
                if (value[serie_column] === item){                  
                    //add the record to the array of values
                    body_data.push(value[y_column])
                }
                return true
             })     
             //add the result
             result.push({name: item, data:body_data})
             return true;
     });
     if (upper_limit)
     {
        
        let values=[];
        for (let i=0; i<x_categories.length ; i++){
            values.push(upper_limit)
        }
        result.push({name:'Upper limit', data: values })
        strokes.push(1);
     }
     if (lower_limit)
     {
        let values=[];
        for (let i=0; i<x_categories.length ; i++){
            values.push(lower_limit)
        }
        result.push({name:'Lower limit', data: values })
        strokes.push(1);
     }

     return {series: result , categories: x_categories, strokes:strokes} 
}

export const convertlineBarChartData=(data, axis_col, series)=>{
    let result = [];
    let x_axis=[]; 
     series.map((item)=>{
        let body=[];
        let descriptions=[];   
        data.map((item2, _index)=>{
            body.push(item2[item.column_value])
            if ('columns_description' in item){

                item.columns_description.map((item3) => {
                    descriptions.push({'id':_index,
                    'name': item.name,
                    'label':item3.label,
                'value':item2[item3.value]
              
                })
    
                })
            }
            
          
            return true;         
        })
       result.push({name:item.name,
       type: item.type,
       descriptions: descriptions,
        data: body })
       return true;
     })
     //fill in the axis column
     data.map((item)=>{
        x_axis.push(item[axis_col]);
        return true;
     })    
     return ({'series':result , 'x_axis': x_axis})
}

export const convertBarDataLabel=(data, value_col, label_col)=>{
    let values=[];
    let categories=[]; 
     data.map((item)=>{
        values.push(item[value_col]);
        categories.push(item[label_col]);
        return true;
     })
     return {data:values , categories: categories}
}

//function to convert leaflet map
export const convertLeaftMap=(data,series)=>{
    let result=[]
    series.map((item,index1)=>{
        let detail=[];
        data.map((item2,index2)=>{
            let emission=0
            if (item['emission_field']){
                emission=item2[item['emission_field']]
            }
            detail.push({'latitude': item2[item['latitude_field']], 'longitude': item2[item['longitude_field']],
          'color': item['color'], 'icon': item['icon'],'id': index1 + '_' + index2,
                'popup':{'type': item['label_type'] ,'state': item2[item['state']],
                'city':item2[item['city']],
                    'emission': formatNumberPosDecimalRegion(emission,2)
                },
                'origin_latitude':   item2[item['origin_latitude']],
                'origin_longitude':  item2[item['origin_longitude']],
                'line_color':  item['line_color'],
                'line_color_dark':item['line_color_dark'],


            });
            return true;            
        })
        result.push({'serie': item['name'], 'detail': detail})
        return true;
    })
    return result

}

//function prepare pie Nivo

export const convertPieNIVO=(data,id_field,label_field, value_field)=>{
    let result=[];
    data.map((item)=>{
  
        result.push({'id': item[id_field],
        'label':  item[label_field],
        'value':item[value_field]})
        return true;
    })
    return result;
}

//function radial bar Nivo
export const convertRadialNivo=(data,key_field,category_field, subcategories, summary_categories)=>{
    let detail_leg=[];
    let summary_leg=[];
    category_field.map((item)=>{
        let detail=[];
        subcategories.map((item2)=>{
            data.map((item3)=>{
                if (item3[key_field]===item){
                    detail.push({'x': item2['label'],
                    'y':item3[item2['field']]
                    })
                }
                return true;
            })
            return true;
            })
        detail_leg.push({'id':item,'data':detail});
        return true;
        })

  //process summary
    summary_categories.map((item)=>{
        let total=0;
        data.map((item2)=>{
            total+=item2[item['field']];
            return true;
        })
        summary_leg.push({'id':item['field'],
            'value':total,
            'label':item['label']});
        return true;
    })

  return {'detail': detail_leg , 'summary': summary_leg}
}

export const convertSearchList=(data,id_column, value_column)=>{
    let result=[]
   // result.push({'labelKey':'ALL','value':'ALL'});
    data.map((item)=>{
        result.push({'labelKey': item[id_column],
        'value':item[value_column]
        })
        return true;
    })
    
    return result;
}


//remove
export const removeRecord=(data,column,value)=>{
    return data.filter((item)=>item[column]!==value)
}


//Units measurement according each system
//by default all the values and the calculation are done in kilometers and kilograms
const measure_systems = [{system:'INTERNATIONAL', units:[
    {label:'kilometres', unit:'km', type:'distance',unit_conversion: 1},
    {label:'kilogram', unit:'kg',  type:'weight',unit_conversion: 1}]},
    {system:'US_SYSTEM', units:[
        {label:'miles', unit:'mi', type:'distance',unit_conversion: 0.621371},
        {label:'pounds', unit:'lb', type:'weight',unit_conversion: 2.20462}]}
];

const current_measurement_system = getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL' //default value
const defined_measure = measure_systems.filter((item)=>item.system === current_measurement_system)[0]['units']

export const getLabelMeasurement=(type_measure)=>{
    //check which system is selected
     let measure_values = defined_measure.filter((item)=> item.type === type_measure)[0];
     return measure_values.label;
}

export const getUnitMeasurement=(type_measure)=>{
    //check which system is selected
     let measure_values = defined_measure.filter((item)=> item.type === type_measure)[0];
     return measure_values.unit;
}
export const getValueMeasurement=(type_measure, value)=>{
    //check which system is selected
     let measure_values = defined_measure.filter((item)=> item.type === type_measure)[0];
     return measure_values.unit_conversion * value;
}

export const getCapitalized=(value)=>{
    return value.charAt(0).toUpperCase()  + value.slice(1).toLowerCase();
}
