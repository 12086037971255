import React, {useState,useEffect}  from 'react'
import {CallAPI} from "../../../services/apiService";
import {getCookie} from 'react-use-cookie';
import {
    convertLeaftMap,
    getLabelMeasurement,
    getUnitMeasurement,
    getValueMeasurement,
    formatNumberPosDecimalRegion,
    convertlineBarChartData
} from "../../../services/utilsService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {Alert, Form} from "react-bootstrap";
import KPICard from "../../../components/statistic/KPICard";
import NivoCalendar from "../../../components/charts/NivoCalendar";
import GhgMap from "../../../components/geomap/GhgMap";
import CarrierSearchBox from "../../../components/carrierSearchBox/CarrierSearchBox";
import {useSelector} from "react-redux";
import PageTitle from '../../../components/pageTitle/pageTitle';
import JRCTable from '../../../components/JRCTable/JRCTable';
import {useSearchParams} from "react-router-dom";
import msgDialog from '../../../services/dialogService';
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import {convertAPIDateFormat, convertSearchList} from "../../../services/utilsService";
import GhgBarLineChartApex from '../../../components/charts/GhgBarLineChartApex';



export default function CarrierProfileOutbound(){
    const [searchParams, setSearchParams] = useSearchParams();
    const [startDate, setStartDate]=useState(getCookie('GHG-INITIAL-DATE'));
    const [endDate, setEndDate]=useState(getCookie('GHG-END-DATE'));
    //TODO: Link it with the Login consider using a Cookie for the Login with a expiration of 3 days
    const [client,setClient] = useState('hofer');

    const [carrierNameSelected, setCarrierNameSelected]= useState([searchParams.get('carrier_name')]);
    const [carrierSelected, setCarrierSelected]= useState([searchParams.get('carrier_id')]);
    const [productClassSelected, setProductClassSelected]=useState('ALL');
    const [carrierRoutes, setCarrierRoutes] = useState([]);
    const [carrierStat, setCarrierStat] = useState({})
    const [carriers,setCarriers] = useState([{}]);
    const [emissionByDate, setEmissionByDate] = useState([])
    const [rdcSelected, setRdcSelected]=useState()
    const [carrierMap, setCarrierMap]=useState({data:[],supplier:''});
    const [topRoutes, setTopRoutes] = useState(10);
    const [suppliersCarrier, setSupplierCarrier] = useState();
    const loginState = useSelector((state)=>state.login);
    //variables storage chart data
    const [emissionIntensity, setEmissionIntensity]=useState({series:[], x_axis:[]})
    const [emissionShipments, setEmissionShipments]=useState({series:[], x_axis:[]})
    const [emissionDistance, setEmissionDistance]=useState({series:[], x_axis:[]})
    const [emissionWeight, setEmissionWeight]=useState({series:[], x_axis:[]})

      

    //TODO: Combinar con valores de la tabla de busqueda
    const [selectedCountry , setSelectedCountry] = useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );
    const [calculationMethod,setCalculationMethod] = useState('basic');
    const [scope, setScope] = useState('inbound');



    //table parameters for supplier location
    const colTableRoutes = [{'header':'State','field':'supplier_state'},
        {'header':'City','field':'supplier_city', 'capitalize':true},
        {'header':'RDC State','field':'destination_state'},
        {'header':'RDC City','field':'destination_rdc', 'capitalize':true},
        {'header':'Average travelled distance (' + getUnitMeasurement('distance') + ')','field':'avg_distance_traveled', 'sorted':true, 'type':'integer'},
        {'header':'Total weight in tonnes','field':'sum_weight_tonnes' , 'sorted':true, 'type':'integer'},
        {'header':'Total emission (tCO2e)','field':'total_emission' , 'sorted':true, 'type':'integer'},
    ];
   //table suppliers attended by carrier
    const colTableSupplier = [{'header':'Supplier name','field':'supplier_name', 'capitalize':true},
        {'header':'Total emission tCO2e','field':'total_emission', 'sorted':true},
        {'header':'Average distance (' + getUnitMeasurement('distance') + ')' , 'field':'avg_distance_km', 'type':'integer', 'sorted':true },
        {'header':'Average weight tonnes ' , 'field':'avg_weight_tonnes' , 'type':'integer' , 'sorted':true }];


    let general_parameters={
            client_id:client, start:startDate, end:endDate ,
            carrier:  carrierSelected[0],
            level:calculationMethod, country:'at',        
            measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')    
        }

    const handleSubmit = event => {
            event.preventDefault(); //
        }
    
    const getCarrierCatalog=()=>{
            CallAPI('GET',"/api/visual/v2/cat_outbound_carriers/" ,general_parameters ).then((resp)=>{
                if (resp.status === 200){                           
                    setCarriers(convertSearchList(resp.data,'id','name'));
                }
            })
        }

    const getCarrierStat=()=>{
        CallAPI('GET',"/api/visual/v2/stat_by_carrier_outbound/",general_parameters ).then((resp)=>{
              if (resp.status === 200){
                setCarrierStat(resp.data);
            }})}



    const getEmissionIntensity=()=>{
        CallAPI('GET',"/api/visual/v2/evol_outbound_carriers_intensity/" ,general_parameters ).then((resp)=>{
            if (resp.status === 200){
                let resultBar = convertlineBarChartData(resp.data, 'month_year', 
                [{name:'Emissions',type:'column', column_value: 'total_emission',
                 columns_description: [{'label':'Total Emission (tCO2e)',
                'value':'total_emission'},
                 {'label':'Emission Intensity Factor','value':'avg_emission_intensity'}]
            
            },
                    {name:'Intensity',type:'line', column_value: 'avg_emission_intensity',
                    columns_description: [{'label':'Total Emission (tCO2e)',
                    'value':'total_emission'},
                     {'label':'Emission Intensity Factor','value':'avg_emission_intensity'}]
                }]);
                    setEmissionIntensity(resultBar);
                    
            }})}

    
    const getEmissionShipments=()=>{
                CallAPI('GET',"/api/visual/v2/evol_outbound_carriers_shipments/" ,general_parameters ).then((resp)=>{
                    if (resp.status === 200){
                        let resultBar = convertlineBarChartData(resp.data, 'month_year', 
                        [{name:'Emissions',type:'column', column_value: 'total_emission',
                        columns_description: [{'label':'Total Emission (tCO2e)',
                        'value':'total_emission'},
                         {'label':'Number of shipments','value':'number_shipments'}]
                    },
                            {name:'Number of Shipments',type:'line', column_value: 'number_shipments',
                            columns_description: [{'label':'Total Emission (tCO2e)',
                            'value':'total_emission'},
                             {'label':'Number of shipments','value':'number_shipments'}]
                        }]);
                         setEmissionShipments(resultBar);
                            
                    }})}
    
        
    const getEmissionDistance=()=>{
                        CallAPI('GET',"/api/visual/v2/evol_outbound_carriers_distance/" ,general_parameters ).then((resp)=>{
                            if (resp.status === 200){
                                let resultBar = convertlineBarChartData(resp.data, 'month_year',
                                 [{name:'Emissions',type:'column', column_value: 'total_emission',
                                 columns_description: [{'label':'Total Emission (tCO2e)',
                                 'value':'total_emission'},
                                  {'label':'Average Distance in Km','value':'avg_distance_km'}]
                                
                                },
                                    {name:'Average Distance km',type:'line', column_value: 'avg_distance_km',
                                    columns_description: [{'label':'Total Emission (tCO2e)',
                                    'value':'total_emission'},
                                     {'label':'Average Distance in Km','value':'avg_distance_km'}]
                                
                                }]);
                                  setEmissionDistance(resultBar);
                                    
                            }})}


    const getEmissionWeight=()=>{
                                CallAPI('GET',"/api/visual/v2/evol_outbound_carriers_weight/" ,general_parameters ).then((resp)=>{
                                    if (resp.status === 200){
                                        let resultBar = convertlineBarChartData(resp.data, 'month_year',
                                         [{name:'Emissions',type:'column', column_value: 'total_emission',
                                         columns_description: [{'label':'Total Emission (tCO2e)',
                                         'value':'total_emission'},
                                          {'label':'Average weight in tonnes','value':'avg_weight_tonnes'}]
                                        
                                        },
                                            {name:'Average weight in tonnes',type:'line', column_value: 'avg_weight_tonnes',
                                            columns_description: [{'label':'Total Emission (tCO2e)',
                                         'value':'total_emission'},
                                          {'label':'Average weight in tonnes','value':'avg_weight_tonnes'}]
                                        
                                        }]);
                                         setEmissionWeight(resultBar);
                                            
                                    }})}




    const getData=(current_carrier)=>{
             if (current_carrier || carrierSelected[0]){
                //get profile data
                //getCarrierStat();
               // getEmissionByDate();
                getEmissionIntensity();
                getEmissionShipments();
                getEmissionDistance();
                getEmissionWeight();
                getCarrierStat();

               //getCarrierRoutes(carrierSelected || current_carrier ,topRoutes);

        }else{
            msgDialog.msgError("Please select a carrier and then press the button 'Apply filter'")
            return false;

        }
   
    }


    useEffect(()=>{
        getCarrierCatalog();      
        if (searchParams.get('carrier_name')){
           
           getData(searchParams.get('carrier_name'));
        }
    },[carrierNameSelected])


    return (
        <Row>
                <PageTitle title='Carrier Profile (Outbound)' />  
            <Col md={2} xl={2} lg={2}>
                <CarrierSearchBox func_carrier={setCarrierSelected}
                                   default_value = {searchParams.get('carrier_name') || ''}
                                  func_start_date={setStartDate}
                                  func_end_date={setEndDate}
                                  show_carrier_list={false}
                                  fixed_scope='outbound'
                                  show_product_class={false}
                                  func_country={(country)=>{setSelectedCountry(country);
                               
                                    getCarrierCatalog();
                                  
                                  } }
                                  func_product_class={setProductClassSelected}

                func_get_data={getData}
                />

            </Col>
            <Col xs={10} md={10} xl={10}>


                <Row>
                    <Col xs={4} md={4} xl={4}>
                        <Card>
                            <Card.Body>
                            <Form onSubmit={handleSubmit}>
                        <Row>
                            <Form.Label className={'text-muted'}>Carrier Name</Form.Label>
                            <BootstrapSelect style={{'width':'100%'}}
                                             showSearch={true}                                          
                                             onChange={(e)=>{
                                                  if (e.selectedValue){
                                                      if (e.selectedValue.length >0){
                                                        //console.log('SELECCI: ', e.selectedValue , ' ', e)
                                                          setCarrierSelected(e.selectedKey);
                                                          setCarrierNameSelected(e.selectedValue);
                                                          setCarrierSelected(e.selectedKey);
                                                          getData(e.selectedKey);                                                    
                                                   }          
                                                 }
                                             }}
                                             placeholder={'-- select --'}  options={carriers} />
                                             </Row>
                                            </Form>
                            </Card.Body>
                        </Card>
                        
 
                    </Col>
                </Row>


                {!carrierSelected[0] ? <Alert variant='danger'>
                     No carrier selected ,  please select the desired filters on the left and click on "Apply filter"</Alert>:<></>}

                     {emissionIntensity['series'].length > 0 ? <>
                     <Row>
                 
                 <Col md={12} xl={12} lg={12}>
                     {/* Maps sections */
                     console.log(emissionIntensity['series'].length)
                     }
                     <Row>
                         <Col xs={6} md={6} xl={6} lg={6}>
                             <Card>
                                 <Card.Body>
                                     <GhgBarLineChartApex
                                     title={"Evolution of emission intensity (gCO2e/tkm) of carrier " + carrierNameSelected}
                                     x_axis={emissionIntensity?.x_axis } y_axis={'Emission in tCO2e'}
                                     show_labels={true}
                                     show_legend={true}
                                     content_explanation='This chart shows the development of the total Outbound emission of one carrier in tCO2e within the selected
                                     time frame in relation to the development of the emission intensity factor in gCO2e/tkm. This factor
                                     represents the amount of emission expected for each tonne-kilometre travelled. It is calculated by dividing
                                     total emission by tonnes x kilometres (tkm).'
                                 
                                     y2_axis='Emission intensity (gCO2e)'
                                     height={400} 
                                     series_data={emissionIntensity?.series}
                                     ></GhgBarLineChartApex>
                                 </Card.Body>
                             </Card>
                         </Col>
                         <Col xs={6} md={6} xl={6} lg={6}>
                         <Card>
                                 <Card.Body>
                                     <GhgBarLineChartApex
                                     title={"Evolution of shipments of carrier " + carrierNameSelected}
                                     x_axis={emissionShipments?.x_axis } y_axis={'Emission in tCO2e'}
                                     show_labels={true}
                                     show_legend={true}
                                     content_explanation='This chart shows the development of the total Outbound emission of one carrier in tCO2e within the selected
                                     time frame in relation to the development of the number of Outbound shipments during the same months.Since the total of transport-related emissions highly depends on the total distance travelled, which
                                     correlates strongly with the number of transports, the course of the curves is similar in most cases'
                                     y2_axis='Number of shipments'
                                     height={400} 
                                     series_data={emissionShipments?.series}
                                     ></GhgBarLineChartApex>
                                 </Card.Body>
                             </Card>
                         </Col>
                     </Row>

                     {/* End map section */}
                
                 </Col>
                 </Row>
                 <Row>
              
              <Col md={12} xl={12} lg={12}>
                  {/* Maps sections */}
                  <Row>
                      <Col xs={6} md={6} xl={6} lg={6}>
                          <Card>
                              <Card.Body>
                                  <GhgBarLineChartApex
                                  title={"Evolution of average service distance of carrier " + carrierNameSelected}
                                  x_axis={emissionDistance?.x_axis } y_axis={'Emission in tCO2e'}
                                  show_labels={true}
                                  
                                  content_explanation='This chart shows the development of the total Outbound emission of one carrier in tCO2e within the selected
                                  time frame in relation to the development of the average service distance driven by the same. The total of
                                  transport-related emissions highly depends on the total distance travelled. The total distance is made up of
                                  the number of shipments and the service distance. A different progression of the curves of emission and
                                  average service distance should therefore only be possible if the curves of total emission and number of
                                  shipments match (in theory).'
                                  height={400} 
                                  show_legend={true}
                                  y2_axis='Average driven service distance (km)'

                                  series_data={emissionDistance?.series}
                                  ></GhgBarLineChartApex>
                              </Card.Body>
                          </Card>
                      </Col>
                      <Col xs={6} md={6} xl={6} lg={6}>
                      <Card>
                              <Card.Body>
                                  <GhgBarLineChartApex
                                  title={"Evolution of average tour weight of carrier " + carrierNameSelected}
                                  x_axis={emissionWeight?.x_axis } y_axis={'Emission in tCO2e'}
                                  show_labels={true}
                                  show_legend={true}
                                  content_explanation='This chart shows the development of the total Outbound emission of one carrier in tCO2e within the selected
                                  time frame in relation to the development of the average weight transported by the same.'
                                  y2_axis='Average weight in tonnes'
                                  height={400} 
                                  series_data={emissionWeight?.series}
                                  ></GhgBarLineChartApex>
                              </Card.Body>
                          </Card>
                      </Col>
                  </Row>

                  {/* End map section */}
             
              </Col>
              </Row>

                 <Row>
                 <Col md={12} xl={12} lg={12}>
                 <Card>
                         <Card.Body>
                   
                             <Row>
                                 <Col xs={4}><KPICard
                                     className='card-xl-stretch mb-xl-4'
                                     color='white'
                                     background_color='white'
                                     titleColor={'dark'}
                                     link_color='link-light'
                                     descriptionColor={'secondary'}
                                     value={formatNumberPosDecimalRegion(carrierStat.total_emission  ,2)}
                                     title='Total emission in tCO2e '
                                     content_explanation='Sum of the Greenhouse gas emission expressed in tCO2e of the selected carrier.'
                                 /></Col>
                                 <Col xs={4}><KPICard
                                     className='card-xl-stretch mb-xl-4'
                                     color='white'
                                     background_color='white'
                                     titleColor={'dark'}
                                     link_color='link-light'
                                     descriptionColor={'secondary'}
                                     value={formatNumberPosDecimalRegion(carrierStat.number_shipment_orders,2)}
                                     title='Number of shipments operated'
                                     content_explanation='Number of Outbound shipments the selected carrier processed within the selected time frame. '
                                 /></Col>
                                        <Col xs={4}><KPICard
                                     className='card-xl-stretch mb-xl-4'
                                     color='white'
                                     background_color='white'
                                     titleColor={'dark'}
                                     link_color='link-light'
                                     descriptionColor={'secondary'}
                                     value={ formatNumberPosDecimalRegion(getValueMeasurement('distance', carrierStat.avg_distance_traveled),2)}
                                     title={'Average tour distance (' + getUnitMeasurement('distance') + ')' }
                                     content_explanation={'Average tour distance of all Outbound transports of the selected carrier within the selected time  frame' }
                                 /></Col>

                             </Row>
                             <Row style={{paddingTop:'10px'}}>
                              
                                 <Col xs={4}><KPICard
                                     className='card-xl-stretch mb-xl-4'
                                     color='white'
                                     background_color='white'
                                     titleColor={'dark'}
                                     link_color='link-light'
                                     descriptionColor={'secondary'}
                                     value={formatNumberPosDecimalRegion(carrierStat.avg_shipment_weight_tonns,2)}
                                     title='Average tour weight in tonnes'
                                     content_explanation='Average shipment weight of all Outbound transports of the selected carrier within the selected time
                                     frame.'
                                 /></Col>
                                         <Col xs={4}><KPICard
                                     className='card-xl-stretch mb-xl-4'
                                     color='white'
                                     background_color='white'
                                     titleColor={'dark'}
                                     link_color='link-light'
                                     descriptionColor={'secondary'}
                                     value={ formatNumberPosDecimalRegion(getValueMeasurement('distance', carrierStat.avg_transportation_activity_thousand),2)}
                                     title={'Average transport activity (tkm) in thousands)' }
                                     content_explanation={'The transport activity is a commonly used indicator to measure how much total weight was transported over the total distance, expressed in thousands of tonne-kilometres. For Outbound transports the value is calculated by multiplying kilometres driven with the shipped volume in tonnes (Tonne-kilometre = driven km * shipped volume in tonnes) PER TRANSPORT LEG. The value shows the average transport activity of the Outbound shipment legs. Further details and a calculation example can be found in the methodology tab.' }
                                 /></Col>
                                  <Col xs={4}><KPICard
                                     className='card-xl-stretch mb-xl-4'
                                     color='white'
                                     background_color='white'
                                     titleColor={'dark'}
                                     link_color='link-light'
                                     descriptionColor={'secondary'}
                                     value={formatNumberPosDecimalRegion(carrierStat.avg_emission_intensity,2)}
                                     title='Average emission intensity(gCO2e / tkm)'
                                     content_explanation='This KPI shows the emission intensity resulting from the calculation of Outbound transport
                                     emission of the selected carrier in the selected time frame, where the total emission, expressed in
                                     gCO2e, are divided by the tonnes x kilometres. This value represents the amount of emission
                                     expected for each tonne-kilometre travelled.'
                                 /></Col>
                             </Row>
                             <Row style={{paddingTop:'10px'}}>
              
                                
                             </Row>
                    
                         </Card.Body>
                     </Card>
           
                 </Col>
             </Row>
                     </>:<>
        <Alert variant='danger'>There is not data available for the selected criteria, please modify your search</Alert>
        </> }     
            
            

            </Col>

        </Row>

    )
}
