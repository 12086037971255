import './App.css';
import React from 'react';
import Menu from './components/menu/menu';
import { Outlet } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useSelector } from 'react-redux';
import { getCookie } from 'react-use-cookie';
import RegionalConfiguration from "./components/regionalConfiguration/RegionalConfiguration";


//TODO: arning: findDOMNode is deprecated in StrictMode. findDOMNode was
function App() {
 
   const coreState = useSelector((state)=>state.core);
    const userRegional = getCookie('GHG-MEASURE-DEFINED');


 return (

    <React.Fragment>
        <RegionalConfiguration show_modal={!userRegional} func_close={()=>{}} />

        <LoadingOverlay className='overlay_message'
      active={coreState.process > 0}
      spinner
      text='Please wait'
    >
    
       <Menu/>
       <div className='body_content'> 
   
    
       <Outlet/>
       </div>
   
       </LoadingOverlay>

    </React.Fragment>    
  );
}

export default App;
