import React, {useEffect, useState} from 'react'
import Card from 'react-bootstrap/Card'
import {Alert, Button} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useNavigate} from "react-router-dom";
import { useSelector } from 'react-redux';
import useCookie,  {getCookie} from 'react-use-cookie';

import * as Icon from 'react-bootstrap-icons';
import {CallAPI} from "../../services/apiService";
import {
    convertAPIDateFormat,
    convertPieChartData,
    convertAreaChartData,
    getLabelMeasurement,
    getValueMeasurement,
    convertPieNIVO, formatNumberPosDecimalRegion, getTopRecordsPie, getUnitMeasurement,
} from "../../services/utilsService";
import GhgAreaChartApex from "../../components/charts/GhgAreaChartApex";
import KPICard from "../../components/statistic/KPICard";
import msgDialog from '../../services/dialogService';
import NivoPie from '../../components/charts/NivoPie';
import PageTitle from '../../components/pageTitle/pageTitle';




export default function InboundDashboard(){

    //local state definition
    const [startDate, setStartDate ] = useState(getCookie('GHG-INITIAL-DATE') );
    const [endDate, setEndDate ] = useState(getCookie('GHG-END-DATE') );
    const [generalPieData, setGeneralPieData] = useState({series:[], label:[]})
    const [generalPieDataNivo, setGeneralPieDataNivo] = useState([])
    const [evolEmission, setEvolEmission] = useState([]);
    const [statRoad, setStatRoad] = useState({});
    const [statRail, setStatRail] = useState({});
    const navigation = useNavigate();    
    const [countries, setCountries]=useState([]);
    const [client,setClient] = useState('hofer');
    const [topRecords, setTopRecords] = useState(10);
    const [emissionDestination, setEmissionDestination] = useState([]);
     const [calculationMethod,setCalculationMethod] = useState('basic');
    const [scope, setScope] = useState('inbound');
    const loginState = useSelector((state)=>state.login);
    const [cookieInitialDate, setCookieInitialDate] = useCookie('GHG-INITIAL-DATE',null);
    const [cookieEndDate, setCookieEndDate] = useCookie('GHG-END-DATE',null);
    const [cookieDefaultCountry, setCookieDefaultCountry] = useCookie('GHG-DEFAULT-COUNTRY',null);
    const [selectedCountry , setSelectedCountry] = useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );
  
    



    //methods to get the data
    const getEmissionByTransport=()=>{
        CallAPI('GET',"/api/visual/v2/emission_transport_type/",
        {client_id:client,
            start:startDate, end:endDate , level:calculationMethod, scope:scope, country:selectedCountry
            } ).then((resp)=>{            
            if (resp.status === 200){
                setGeneralPieData(convertPieChartData(resp.data,'emissions','transport'));
                setGeneralPieDataNivo(convertPieNIVO(resp.data,
                    'transport','transport','emissions'))
            }
        })
    }

    const getEvolutionByTransportType=()=>{
        CallAPI('GET',"/api/visual/v2/evol_emission_transport_type/",
        {
            client_id:client,
                start:startDate, end:endDate , level:calculationMethod, scope:scope, country:selectedCountry
               
        } ).then((resp)=>{
           
            if (resp.status === 200){
                setEvolEmission(convertAreaChartData(resp.data,'type','shipping_date','total_emission'))
            }
        })
    }

    const getStatRoad=()=>{
        CallAPI('GET',
            '/api/visual/v2/general_kpi_road',
            {
                client_id:client,
                    start:startDate, end:endDate , level:calculationMethod, scope:scope, country:selectedCountry
                        
            } 
            ).then((resp)=>{
                if (resp.status === 200){
                  
                     setStatRoad(resp.data);
                }else{
   
                    setStatRoad({})
                }
        })
    }

    const getStatRail=()=>{
        CallAPI('GET',
            '/api/visual/v2/general_kpi_rail',
            {
                client_id:client,
                    start:startDate, end:endDate , level:calculationMethod, scope:scope, country:selectedCountry
     
            } 
            ).then((resp)=>{
            if (resp.status === 200){
                setStatRail(resp.data);
            }
        })
    }

    const redirectRoad=()=>{
        navigation({pathname: "../road_detailed",
        search:'?start_date=' + startDate + '&end_date=' + endDate},
        { replace: true });
    }

    const redirectRail=()=>{
        navigation({pathname: "../rail_detailed",
            search:'?start_date=' + startDate + '&end_date=' + endDate});
    }

    const getEmissionByDestination=()=>{
          CallAPI('GET',"/api/visual/v2/emission_by_destination" ,
        {
            client_id:client,top:topRecords, start:startDate, end:endDate ,
            level:calculationMethod, scope:scope, country:selectedCountry,
            category:'ALL'
 
        } 
        ).then((resp)=>{
            if (resp.status === 200){
               if (resp.data.length > 0){
                    let result = getTopRecordsPie(resp.data, topRecords,'destination_rdc','total_emission','Others');
                    setEmissionDestination(convertPieNIVO(result,'label','label','value' ))
   
                }else{
                    setEmissionDestination([]);
                }

               
            }
        })
    }

    //get the initial values for the date

    const getInitialDate=()=>{
        CallAPI('GET',
            '/api/visual/v2/initial_dates/',
            {
                client_id:client,
                level:calculationMethod, scope:scope, country:selectedCountry
     
            } 
            ).then((resp)=>{
            if (resp.status === 200){
                let data = resp.data;
                setCookieInitialDate(getCookie('GHG-INITIAL-DATE') ||  data.start_date);
                setCookieEndDate(getCookie('GHG-END-DATE') || data.end_date);
                setStartDate(getCookie('GHG-INITIAL-DATE') || data.start_date);
                setEndDate(getCookie('GHG-END-DATE') || data.end_date);
            }
        })
    }

   const showTransportDetail=(data)=>{
    if (data.id==='Road'){
        navigation({pathname: "/visualization/road_detailed",
         replace: true});
    }
    if (data.id==='Rail'){
        navigation({pathname: "/visualization/rail_detailed",
         replace: true});
    }
     }

    const callGetData=()=>{
        if ((!startDate && !getCookie('GHG-INITIAL-DATE')  ) || (!endDate && !getCookie('GHG-END-DATE') )){
            msgDialog.msgError("Please provide a valid date for the start and end period");
            return false;
        }


        getEmissionByTransport();
        getEvolutionByTransportType();
        getStatRoad();
        getStatRail();
        getEmissionByDestination();
   
    }


    const check_selected_country =(code)=>{
        if (code === getCookie('GHG-DEFAULT-COUNTRY')){
            return true;
        }else{
            return false;
        }
    }


    useEffect(()=>{
        let countries_list = loginState?.userListCountries;
        setCountries(countries_list);
        if (!client){
            setClient(loginState.userClient);
        }
        getInitialDate();
        callGetData();
    },[])

    return (
        <div>                
            <Row>
            <PageTitle title='Inbound emission' /> 
           
                <Col xs={2} md={2} lx={2}>
       {/* Date parameters card */}
       <Card className={'position-fixed'}  style={{ width: '16%' }}>
                <Card.Body>
                    <Card.Subtitle>
                        Filtering options 
                    </Card.Subtitle>
                        <Form style={{marginTop:'30px'}}>
                            <Form.Group className="mb-3" controlId="calculation_method">
                                <Form.Label className={'text-muted'}>Operation Country</Form.Label>
                                <Form.Select aria-label="Operation Country" onChange={(e)=>{setSelectedCountry(e.target.value)
                                           setCookieDefaultCountry(e.target.value);
                                   
                                 
                                }} >
                                    { countries.map((item)=>(
                                        <option selected={check_selected_country(item.country_code)}  key={item.country_code} value={item.country_code}>{item.country_name}</option>
                                    )) }

                                </Form.Select>
                            </Form.Group>
                            
                            {/* Hidden calculation method
                                   <Form.Group className="mb-3" controlId="calculation_method">
                                        <Form.Label className={'text-muted'}>Calculation Method</Form.Label>
                                        <Form.Select aria-label="Calculation Method" onChange={(e)=> setCalculationMethod(e.target.value) } >
                                            <option selected  value='basic'>Basic</option>
                                        </Form.Select>
                                    </Form.Group>
                            
                            */}
                             

                        

                            <Form.Group className="mb-3" controlId="scope">
                                <Form.Label className={'text-muted'}>Scope</Form.Label>
                                <Form.Select aria-label="Scope" onChange={(e)=> setScope(e.target.value)} >
                                    <option value='inbound'>Inbound</option>
                                </Form.Select>
                            </Form.Group>
                                              
                                    <Form.Group className="mb-4" controlId="startDate">
                                        <Form.Label className={'text-muted'}>Start date</Form.Label>
                                        <Form.Control value={startDate} controlid="start_date" type="date" placeholder="Enter the start date"
                                        onChange={(e)=>{
                                            setStartDate(convertAPIDateFormat( e.target.value ));
                                            setCookieInitialDate(e.target.value );

                                        }}
                                        />
                                    </Form.Group>
                         
                                    <Form.Group className="mb-3" controlId="endDate">
                                        <Form.Label className={'text-muted'}>End date</Form.Label>
                                        <Form.Control value={endDate} controlid="end_date" type="date" placeholder="Enter the end date"
                                        onChange={(e)=> {setEndDate(convertAPIDateFormat(e.target.value));
                                            setCookieEndDate(e.target.value);}
                                        }
                                        />
                                    </Form.Group>
                      
                                    <Button style={{marginTop:'20px', width:'100%'}} variant="danger"
                                    onClick={callGetData}
                                    >Apply filter</Button>
                                    <Form.Group>
                                    <Form.Text className="text-muted">
                                        Please select a calculation method and a time frame
                            </Form.Text>
                            </Form.Group>
                        </Form>
                </Card.Body>
            </Card>
            {/* Ending Card parameters */}

                </Col>
{/*Body section */}                
<Col xs={10} md={10} lg={10}>


    {statRoad?.total_emission > 0 ? <>
      {/* Start generation visualization */}
      <Row>
                <Col xl={6} md={6}>
                    <NivoPie title={'Emission distribution in tCO2e per transport type'}
                             description={'This chart shows the total gas emission expressed in tonnes of CO2 equivalence. The ' +
                                 'amount is provided for each transport type evaluated and using the selected level of emission calculation' +
                                 ' '}
                             height={400}
                             pad={9}
                             palette='pastel1'
                             show_legend={true}
                             label_value='Emission tCO2e'
                             show_arc_labels={true}
                                                 function_click={showTransportDetail}
                             data={generalPieDataNivo}/>
                </Col>
                <Col xl={6} md={6}>
                    <NivoPie show_legend={false}
                             palette={'pastel1'}
                             height={400}
                             label_value='Emission tCO2e'
                             title={'TOP ' + topRecords + ' destination divisions with highest emission in tCO2e'}
                             description={'This chart show the TOP ' + topRecords + ' division with highest emission in tC02e.'}
                             data={emissionDestination}/>
                </Col>
            </Row>
            <Row style={{marginTop:'5px'}}>
                <Col xs={12} xl={12} md={12}>
                    <Card style={{height:'400px'}}>
                        <Card.Body>
                            <GhgAreaChartApex series={evolEmission}
                                              palette_name={'pastel1'}
                                              base_color={'#00c853'}
                                              type='area'
                                              height={'300px'}
                                              show_legend={true}
                                              description={'This chart shows total emission in tCO2e of the selected time frame and the share of emission per transport mode.'}
                                              title={'Evolution of emission by transport mode'}
                                              use_monochrome={false}
                                              default_colors={['#fbb4ae','#a6cee3']}
                                              x_title={'Date'} y_title={'Emission in tCO2e'}
                            />

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row style={{marginTop:'5px'}}>
                <Col xs={12} xl={12} md={12}>
                    <Card>
                        <Card.Body>
                        <Card.Title>Summary KPIs for road</Card.Title>
                            <Row style={{paddingTop:'10px'}}>
                                <Col md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statRoad.total_emission,2)}
                                    title='Total emission in tCO2e'
                                    content_explanation='Sum of the Greenhouse gas emission expressed in tCO2e for road transportation'
                                /></Col>
                                          <Col md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statRoad.avg_transportation_activity_thousand,2)}
                                    title='Average transport activity (tkm) in thousands'
                                    content_explanation='The transport activity is a commonly used indicator expressed in thousands tonne-kilometre. The value is calculated by multiplying driven kilometre with the shipped volume in tonnes (Tonne-kilometre = driven km * shipped volume in tonnes). The value shows the average transport activity per shipment.'
                                /></Col>
                                  <Col  md={2}  xs={3}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(statRoad.number_orders,2)}
                                        title='Number of shipments processed'
                                        content_explanation='Number of shipment processed in the selected time frame'
                                    />
                                </Col>
                                <Col  md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statRoad.avg_shipment_weight,2)}
                                    title='Average shipment weight in tonnes'
                                    content_explanation='This KPI shows the average weight in tonnes per shipment evaluated in the current set of filters.  The average shipment weight is calculated by shipment order (not truck) and not consolidated per supplier and different RDCs because the actual trucks travelled are unknown, which leads to a small shipment weight. Another reason can be that one supplier delivers products to not only ALDI. '
                                />
                                </Col>
                                <Col  md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion( getValueMeasurement('distance', statRoad.avg_distance),2)}
                                    title={'Average distance (' + getUnitMeasurement('distance') + ')'}
                                    content_explanation={'This KPI shows the average distance in ' + getLabelMeasurement('distance') + ' that the shipments traveled by road from the supplier location (Production facility) to the ALDI Regional Distribution Center (RDC) in the selected time frame '}
                                />
                                </Col>
                                <Col  md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion( statRoad.intensity_factor,2)}
                                    title={'Average emission intensity factor (gCO2e / tkm)'}
                                    content_explanation={'This KPI shows the emission intensity factor resulting in the calculation for road, considering the total emission expressed in gCO2e divided by the tonnes x kilometres. This value represents the amount of emission expected for each tonne-kilometre traveled.'}
                                />
                                </Col>
                            </Row>
                            <Row>
                            <Col xs={3}>
                            <Button hidden={generalPieData.series.length === 0} variant="outline-danger" onClick={redirectRoad}>View road detailed dashboard</Button>{' '}
                             </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
            {/*KPI INDICATORS FOR RAIL*/}

            <Row style={{marginTop:'10px'}}>
                <Col xs={12} xl={12} md={12}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Summary KPIs for rail</Card.Title>
                            <Row style={{paddingTop:'10px'}}>
                                <Col md={2} xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='#90caf9'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statRail.total_emission,2)}
                                    title='Total emission in tCO2e'
                                    content_explanation={'Represent the total emission calculated with the basic approach and expressed in tonnes of CO2 equivalence'}
                                    description='using basic calculation'
                                /></Col>
                                
                                <Col md={2} xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='#90caf9'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statRail.avg_transportation_activity_thousand,2)}
                                    title='Average transport activity (tkm) in thousands'
                                    content_explanation='Average transport activity indicador presented in thousand of tkm (km * shipped volume in tonnes)'
                                />
                                </Col>
                                <Col md={2} xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='#90caf9'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statRail.number_orders,2)}
                                    title='Number of shipments processed'
                                    content_explanation='This KPI shows the number of shipments considered in the current emission calculation according the filter selected'
                                />
                                </Col>
                                <Col md={2} xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='#90caf9'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statRail.avg_shipment_weight,2)}
                                    title='Average shipment weight in tonnes'
                                    content_explanation='This KPI shows the average weight in tonnes per shipment evaluated in the current set of filters'
                                />
                                </Col>
                                <Col md={2} xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='#90caf9'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion( getValueMeasurement('distance',statRail.avg_distance_rdc),2)}
                                    title={'Average distance (' + getUnitMeasurement('distance') + ')'}
                                    content_explanation={'This KPI shows the average distance in ' + getLabelMeasurement('distance')  + ' per shipments sent by rail from the Port of Discharge (POD) to the Regional Distribution Center (RDC) '}
                                />
                                </Col>
                                <Col md={2} xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='#90caf9'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statRail.intensity_factor,2)}
                                    title={'Emission intensity factor (gCO2e / tkm)'}
                                    content_explanation={'This KPI shows the emission intensity factor resulting in the calculation for rail, considering the total emission expressed in gCO2e divided by the tonnes x kilometres.This value represents the amount of emission expected for each tonne-kilometre traveled.'}
                                />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3}>
                                    <Button hidden={generalPieData.series.length === 0} variant="outline-danger" onClick={redirectRail}>
                                        <Icon.PieChart style={{paddingRight:'10px' }} />
                                        View rail detailed dashboard</Button>{' '}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

    </>:<>
    <Alert variant='danger'>There is not data available for the current filters</Alert>
    </> }


                </Col>
            </Row>

     



        </div>
    )
}
