//Dialog stardard library
import {toast } from 'react-toastify';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


//display time and  default location
const displayTime = 3000;
const position = "top-right"

const msgDialog = {
    msgWarning:(message)=>{
        toast.warning(message, {
            position: position ,
            autoClose: displayTime,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    },
    msgInfo:(message)=>{
        toast.info(message, {
            position: position,
            autoClose: displayTime,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    },
    msgError:(message)=>{
        toast.error(message, {
            position: position,
            autoClose: displayTime,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    },
    msgSuccess:(message)=>{
        toast.success(message, {
            position: position,
            autoClose: displayTime,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    },
    msgConfirm:(title,message,function_ok, function_cancel)=>{
        confirmAlert({
            title:title,
            message:message,
            buttons:[
                {
                    label:'Yes',
                    onClick: function_ok
                },
                {
                    label:'No',
                    onClick: function_cancel

                }
            ]
        });

    }
}

export default msgDialog