import React, {useState} from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from 'react-redux';
import useCookie,  {getCookie} from 'react-use-cookie';
import SearchBox from "../../../components/searchBox/searchBox";
import DetailSupplierAnalysis from "./DetailSupplierAnalysis";
import PageTitle from '../../../components/pageTitle/pageTitle';


export default function SupplierAnalysis(){
    const [client,setClient]=useState('hofer');
    const loginState = useSelector((state)=>state.login);
    const [startDate, setStartDate ] = useState('2021-01-01');
    const [endDate, setEndDate ] = useState('2021-06-30');
    const [startProcess,setStartProcess] = useState(true)
    const [selectedCountry , setSelectedCountry]=useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );
    const [prodClassFiltered, setProdClassFiltered] = useState('ALL');

    return (
    
        <Row>
        <PageTitle title='Supplier Ranking (Inbound)' />  
            <Col   xs={2} md={2} lg={2}   >
                <SearchBox  client={client} initialStartDate={startDate}
                           initialEndDate={endDate}
                           initialScope='inbound'
                           funcProductClass={(value)=>setProdClassFiltered(value)}
                           funcEndDate={(value)=>setEndDate(value)}
                           funcCountry={(value)=>setSelectedCountry(value)}
                           funcStartDate={(value)=>setStartDate(value)}
                           funcProcess={(value)=>{setStartProcess(value)}}
                />
            </Col>
            <Col xs={10} md={10} lg={10}>
                <DetailSupplierAnalysis start_process={startProcess} client={client} start_date={startDate}
                                        end_date={endDate}
                                        finish_process={(value)=>setStartProcess(value)}
                                        top_routes={10}
                                        product_class={prodClassFiltered} top_suppliers={10}
                />

            </Col>

        </Row>

    )

}
