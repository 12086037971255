import React,{useState,useEffect} from 'react'
import {useSelector} from "react-redux";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import BootstrapSelect from "react-bootstrap-select-dropdown";
import {convertAPIDateFormat, convertSearchList} from "../../services/utilsService";
import {Button} from "react-bootstrap";
import {CallAPI} from "../../services/apiService";
import useCookie,  {getCookie, setCookie} from 'react-use-cookie';


export default function CarrierSearchBox({show_carrier_list=true, func_start_date, func_end_date,
                                             func_carrier,
                                             func_country,
                                             default_value='',
                                             fixed_scope='',
                                             show_product_class=true,
                                             show_scope=true,
                                             func_product_class,
                                             func_get_data,
                                             initialStartDate,
                                             initialEndDate
                                            }){
                                                
    const [startDate, setStartDate]= useState();
    const [endDate, setEndDate ] = useState();
    const loginState = useSelector((state)=>state.login);
    const [carriers,setCarriers] = useState([{}]);
    const [scope, setScope] = useState('inbound');
    const [carrierSelected, setCarrierSelected]= useState([default_value]);
    const [countries, setCountries]=useState([{}]);
    const [cookieDefaultCountry, setCookieDefaultCountry] = useCookie('GHG-DEFAULT-COUNTRY',null);
    const [productClass, setProductClass] = useState([]);
    const [productClassSelected, setProductClassSelected]=useState('ALL');
    const [cookieInitialDate, setCookieInitialDate] = useCookie('GHG-INITIAL-DATE',null);
    const [cookieEndDate, setCookieEndDate] = useCookie('GHG-END-DATE',null);



    //TODO: Resolver problema del state redux client id al hacer login, esta tomando un estado viejo
    const [client, setCliente] = useState('hofer');

    //function to get data
    const getProductClassCatalog=()=>{
        CallAPI('GET',"/api/visual/v2/product_class_catalog/" + client ,{} ).then((resp)=>{
            if (resp.status === 200){
                setProductClass(resp.data);
            }
        })
    }

    const getCarrierCatalog=(country_code)=>{
        CallAPI('GET',"/api/visual/v2/catalog_carriers/" ,{client_id: client , country:country_code || getCookie('GHG-DEFAULT-COUNTRY') } ).then((resp)=>{
            if (resp.status === 200){
                setCarriers(convertSearchList(resp.data,'carrier_name','carrier_name'));
            }
        })
    }


    const check_disabled=(scope)=>{
        if (scope===fixed_scope){
            return true
        }else{
            return false;
        }

    }    




    useEffect(()=>{
       //get the default data
       setCountries(loginState.userListCountries);
       setStartDate(getCookie('GHG-INITIAL-DATE') );
       setEndDate(getCookie('GHG-END-DATE') ); 
       func_country();
        getProductClassCatalog();
        if (show_carrier_list==true){
            getCarrierCatalog();
        }

        //getCountries();
 
        //set the default date getting from the Cookie
  ; 
    },[])

    const handleSubmit = event => {
        event.preventDefault(); //
    }

    const get_show_carrier_list = ()=>{
        if (show_carrier_list==true){
            return (
                <div>
                         
                <Form.Label className={'text-muted'}>Carrier Name</Form.Label>
                <BootstrapSelect style={{'width':'100%'}}
                                 showSearch={true}
                              
                                 onChange={(e)=>{
                         if (e.selectedValue){
                                     if (e.selectedValue.length >0){
                                            setCarrierSelected(e.selectedValue);
                                         func_carrier(e.selectedValue);
                                         //func_get_data(e.selectedValue);
                                         }                                                   
                                         
                                     }
                                 }}
                                 placeholder={'-- select --'}  options={carriers} />
                </div>
            )
        }else{
            return (<></>)
        }
    }

    const check_selected_country =(code)=>{
        if (code === getCookie('GHG-DEFAULT-COUNTRY')){
            return true;
        }else{
            return false;
        }
    }

    return (
            <Card className={'position-fixed'}   style={{ width: '16%', marginBottom:'10px'}}>
                <Card.Body>
                    <Card.Title>
                        Filter options
                    </Card.Title>
                    <Form onSubmit={handleSubmit} style={{marginTop:'30px'}}>
                        <Row>
                            { get_show_carrier_list()}
            

                            <Form.Group className="mb-3" controlId="calculation_method">
                                <Form.Label className={'text-muted'}>Operation country</Form.Label>
                                <Form.Select aria-label="Operation Country"
                                defaultValue={getCookie('GHG-DEFAULT-COUNTRY')} 
                                onChange={(e)=>{
                                    
                                  
                                    setCookieDefaultCountry(e.target.value);
                                    func_country(e.target.value);

                                getCarrierCatalog(e.target.value);
                                
                                }} >
                                    { countries.map((item)=>(
                                        <option  selected={check_selected_country(item.country_code)}  key={item.country_code} value={item.country_code}>{item.country_name}</option>
                                    )) }

                                </Form.Select>
                            </Form.Group>
                            {/* Hidden calculation method 
                            
                                 <Form.Group className="mb-3" controlId="calculation_method">
                                <Form.Label className={'text-muted'}>Calculation method</Form.Label>
                                <Form.Select aria-label="Calculation Method" >
                                    <option value='Basic'>Basic</option>
                                </Form.Select>
                            </Form.Group>
                            */}
                       
{ show_scope ?<>
    <Form.Group className="mb-3" controlId="scope">
                                <Form.Label className={'text-muted'}>Scope</Form.Label>
                                <Form.Select aria-label="Scope" onChange={(e)=> setScope(e.target.value)} >
                                 {check_disabled('inbound')? <option key="inbound" value='inbound'>Inbound</option> :<></> }
                                {check_disabled('outbound')? <option key="outbound"  value='outbound'>Outbound</option> :<></> }
              
                                
                                </Form.Select>
                            </Form.Group>
                    
</> : <></>}
                         

                


                            <Form.Group className="mb-4" controlId="startDate">
                                <Form.Label className={'text-muted'}>Start date</Form.Label>
                                <Form.Control required  value={ startDate || initialStartDate} controlid="start_date" type="date" placeholder="Enter the start date"
                                              onChange={(e)=>{
                                                  setStartDate(convertAPIDateFormat( e.target.value ));
                                                  setCookieInitialDate(convertAPIDateFormat( e.target.value ));
                                                  func_start_date(convertAPIDateFormat( e.target.value ));
                                              }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please select a valid date
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="endDate">
                                <Form.Label className={'text-muted'}>End date</Form.Label>
                                <Form.Control aria-required={true} required value={endDate || initialEndDate} controlid="end_date" type="date" placeholder="Enter the end date"
                                              onChange={(e)=> {setEndDate(convertAPIDateFormat( e.target.value ));
                                              setCookieEndDate(convertAPIDateFormat( e.target.value ));
                                                  func_end_date(convertAPIDateFormat( e.target.value ));

                                              }
                                                  //call external function
                                              } />
                            </Form.Group>
                            {show_product_class ?
                                         <Form.Group className="mb-3" controlId="product_class_2">
                                         <Form.Label className={'text-muted'}>Product class</Form.Label>
                                         <Form.Select aria-label="Product class selection" onChange={(e)=>{
                                             setProductClassSelected(e.target.value);
                                             func_product_class(e.target.value);
                                         }}>
                                             <option value='ALL'>ALL</option>
                                             {
                                                 productClass.map((item)=>(
                                                     <option value={item.product_class}>{item.product_class}</option>
                                                 ))
                                             }
                                         </Form.Select>
                                     </Form.Group>
                            : <></> }
               
                            <Button style={{marginTop:'35px'}} variant="danger"
                                    onClick={()=>{func_get_data()}}
                            >Apply filter</Button>

                        </Row>
                                 </Form>
                </Card.Body>
            </Card>


        )


}
