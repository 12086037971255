import React, {useState, useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useCookie,  {getCookie} from 'react-use-cookie';
import {
    convertAPIDateFormat,
    convertPieNIVO,
    convertScatter,  
    getTopRecordsPie
} from "../../services/utilsService";
import {Button} from "react-bootstrap";
import {CallAPI} from "../../services/apiService";
import NivoPie from "../../components/charts/NivoPie";
import { useSelector } from 'react-redux';
import NivoScatter from "../../components/charts/NivoScatter";
import JRCTable from "../../components/JRCTable/JRCTable";




export default function RailDetail(){
    const [searchParams, setSearchParams] = useSearchParams();
    const [startDate, setStartDate ] = useState(searchParams.get('start_date'));
    const [endDate, setEndDate ] = useState(searchParams.get('end_date'));
    const [client,setClient]=useState('hofer');
    const loginState = useSelector((state)=>state.login);
    const [selectedCountry , setSelectedCountry]= useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );
    const [calculationMethod,setCalculationMethod] = useState('basic');
    const [scope, setScope] = useState('inbound');  
    const [cookieInitialDate, setCookieInitialDate] = useCookie('GHG-INITIAL-DATE',null);
    const [cookieEndDate, setCookieEndDate] = useCookie('GHG-END-DATE',null);
    const [emissionDivision, setEmissionDivision] = useState([])
    const [countries, setCountries]=useState([]);
    const [dataScatter,setDataScatter]=useState([])
    const [dataDetail,setDataDetail] = useState([]);

    const colTableRail=[
        {'header':'Division name','field':'destination_rdc','capitalize':true},
    {'header':'Number or shipment orders','field':'number_orders', 'type':'integer','sorted':true},
{'header':'Total emission in tCO2e','field':'total_emission', 'type':'decimal','sorted':true}];


const check_selected_country =(code)=>{
    if (code === getCookie('GHG-DEFAULT-COUNTRY')){
        return true;
    }else{
        return false;
    }
}

    //method sections
    const getEmissionByDivision=()=>{
        CallAPI('GET',"/api/visual/v2/emission_destiny_rail/",{client_id:client,
        start:getCookie('GHG-INITIAL-DATE') || startDate, end:getCookie('GHG-END-DATE') || endDate , level:calculationMethod, scope:scope, country:selectedCountry
        } ).then((resp)=>{
            if (resp.status === 200){
                let result = getTopRecordsPie(resp.data, 10,'destination_rdc','total_emission','Others');
                 setEmissionDivision(convertPieNIVO(result,'label',
                    'label','value' ))
                setDataScatter(convertScatter(resp.data,'destination_rdc','total_emission','number_orders'))
                setDataDetail(resp.data);
        
            }
        });
    }



    const callGetData=()=>{   
        setCountries(loginState.userListCountries);  
        setStartDate(getCookie('GHG-INITIAL-DATE'));
        setEndDate(getCookie('GHG-END-DATE'));  
        getEmissionByDivision();    
    }

    useEffect(()=>{
        callGetData();
    },[endDate])

    return (<div>
        <Row>
            <Col xs={2} md={2} lg={2}>
                <Card className={'position-fixed'} style={{ width: '16%', marginBottom:'10px'}}  >
                    <Card.Body>
                        <Card.Title>
                            Filter options
                        </Card.Title>
                        <Form style={{marginTop:'30px'}}>
                            <Form.Group className="mb-3" controlId="calculation_method">
                                <Form.Label className={'text-muted'}>Operation Country</Form.Label>
                                <Form.Select aria-label="Operation Country" onChange={(e)=>setSelectedCountry(e.target.value)}  >
                                    { countries.map((item)=>(
                                        <option selected={check_selected_country(item.country_code)} key={item.country_code} value={item.country_code}>{item.country_name}</option>
                                    )) }

                                </Form.Select>                            
                            </Form.Group>
                       
                       {/* Hidden calculation method selector
                       
                                 <Form.Group className="mb-3" controlId="calculation_method">
                                <Form.Label className={'text-muted'}>Calculation Method</Form.Label>
                                <Form.Select aria-label="Calculation Method" >
                                    <option value='Basic'>Basic</option>
                                </Form.Select>
                            </Form.Group>
                       
                       */}
                  


                            <Form.Group className="mb-3" controlId="scope">
                                <Form.Label className={'text-muted'}>Scope</Form.Label>
                                <Form.Select aria-label="Scope" onChange={(e)=> setScope(e.target.value)} >
                                    <option value='inbound'>Inbound</option>                                   
                                </Form.Select>
                            </Form.Group>
                                    <Form.Group className="mb-4" controlId="startDate">
                                        <Form.Label className={'text-muted'}>Start date</Form.Label>
                                        <Form.Control value={startDate || ''} controlid="start_date" type="date" placeholder="Enter the start date"
                                                      onChange={(e)=>{
                                                          setStartDate(convertAPIDateFormat( e.target.value ));
                                                          setCookieInitialDate(convertAPIDateFormat( e.target.value ));
                                                      }}
                                        />
                                    </Form.Group>
                                      <Form.Group className="mb-3" controlId="endDate">
                                        <Form.Label className={'text-muted'}>End date</Form.Label>
                                        <Form.Control value={endDate || ''} controlid="end_date" type="date" placeholder="Enter the end date"
                                                      onChange={(e)=> {setEndDate(convertAPIDateFormat(e.target.value));
                                                      setCookieEndDate(convertAPIDateFormat( e.target.value ));
                                                    
                                                    }} />
                                    </Form.Group>
                               <Button style={{marginTop:'35px', width:'100%'}} variant="danger"
                                            onClick={callGetData}
                                    >Apply filter</Button>
                                  <Form.Text className="text-muted">
                                Please provides a value for the initial and ending date
                            </Form.Text>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={10} md={10} lg={10}>
                <Card style={{marginBottom:'5px'}}>
                    <Card.Body>
                        <h4>
                            Rail transportation emission analysis
                        </h4>
                    </Card.Body>
                </Card>
                <Row style={{marginTop:'5px'}}>
                    <Col xs={6} sm={12} md={6} lg={6}>
                        <NivoPie show_legend={false} palette={'pastel1'}
                                 height={480}
                                 label_value="Emission in tCO2e"
                                 description={ 'This chart shows the absolute and relative share of emission per division . Please note, the TOP 10 Division are represented exclusively, whereas all other carriers are grouped in „Others“'}
                                 title={'TOP 10 Inbound rail emission (in tCO2e) per receiving division'} data={emissionDivision}
    
                                 
                                 />

                    </Col>
                    <Col xs={6} md={6} lg={6}>
                        <Card>
                            <Card.Body>

                            <NivoScatter data={dataScatter} 
                            palette={"pastel1"}
                            height={450}
                            node_size={20}
                            axis_y_legend="Number of shipments"
                            axis_x_legend="Total emission tCO2e"
                            title={['Emission in tCO2e and number of shipments per division']}
                            description={'This chart shows the relation between the number of shipments and total inbound emission produced per destination division.'}
                            />
                            <JRCTable
                            columns={colTableRail}
                            export_table={true}
                            data={dataDetail}
                            >


                            </JRCTable>
             
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
        </Col>
        </Row>     

    </div>)

}
