import React,{useState, useRef} from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { FilterMatchMode} from 'primereact/api';
import 'primereact/resources/primereact.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/datatable/datatable.min.css";
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import './JRCTable.css';
import { formatNumberPosDecimal, formatNumberPosDecimalRegion } from '../../services/utilsService';
import { Alert, Col, Row } from 'react-bootstrap';




export default function JRCTable({columns=[],table_title="", data=[],
paginate=true,
export_table,
show_grid=false,
show_search,
export_pdf = true,
show_select = false,
show_title,
table_max_height='400px',
select_function,
item_by_page=10}){


    const [currentPage, setCurrentPage] = useState(1);
    const [first1, setFirst1] = useState(0);
    const [pageInputTooltip, setPageInputTooltip] = useState('Press \'Enter\' key to go to this page.');
    const [filters, setFilters] = useState({'global': { value: null, matchMode: FilterMatchMode.CONTAINS }})
    const exportColumns = columns.map(col => ({ title: col.header, dataKey: col.field }));
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const formatedValue=(value,type)=>{
      
         if (type === 'integer'){
            return formatNumberPosDecimalRegion(value,0);
         }
        if (type === 'decimal'){
             return formatNumberPosDecimalRegion(value,2);
        }
        return value ; //default value


    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    
 

    //Export functionality


    const exportPdf = () => {
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, data);
                doc.save('products.pdf');
            })
        })
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, table_title);
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }


   const showExport = () =>{
    if (export_table===true && data.length > 0){
        return (   <div 
        style={{'marginTop':'10px',  'width':'100%','textAlign':'right'}}>
        <Button style={{'height':'40px', 'paddingLeft':'10px', 'opacity':'0.75'}}  type="button" icon="pi pi-file-excel" onClick={exportExcel} 
        className="p-button-primary mr-2" data-pr-tooltip="XLS">Excel</Button>
        { export_pdf ?   <Button style={{'height':'40px', 'opacity':'0.75','background':'#f44336'
    }} type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-danger mr-2" data-pr-tooltip="PDF">PDF</Button> : <></>}
      
              </div>)
    }else{
        return (<></>)
    }
   }

   const showSearch = ()=>{
    if (show_search===true && data.length > 0 ){
        return ( 
            <span className="p-input-icon-left" style={{'marginTop':'10px'}}>
            <i className="pi pi-search" />              
                <InputText style={{'height':'40px'}} type="search" value={globalFilterValue} onChange={onGlobalFilterChange} 
                placeholder="Keyword Search" />
                </span>

        )
    }
   }

   const showTitle=()=>{
    if (show_title===true){
        return (
            <h5 style={{'textAlign':'left', 'fontWeight':'lighter'}}>{table_title}</h5>
                   )
    }
   
   }

    const renderHeader = () => {
        return (
            <div>         
            { show_search ? showTitle():<></>}
            { /* data.length === 0 ?
                <Alert style={{'fontWeight':'normal'}} variant='danger'>Not data found to populate the table  for the provided filters</Alert>
                :<></> 
            */
            }

            <Row>
                { show_search ?<>
                    <Col xs={'2'} md={'2'} lg={'2'}>{showSearch()}</Col>
                    <Col xs={'6'} md={'6'} lg={'6'} ></Col>
                </> 
                
                : <Col xs={8} md={8} lg={8}>{showTitle()}</Col>  }
            
            
            <Col xs={'4'} md={'4'} lg={'4'} style={{'textAlign':'right'}}>{showExport()}</Col>  
            </Row>          
            
       
         
                
            
         
        </div>
        )
    }

    const header = renderHeader();

    //const footer = `In total there are ${products ? products.length : 0} products.`;
    const applyProp = (prop,obj)=>{
        if (prop in obj){
            return obj[prop];
        }else{
            return false
        }
    }

    const getAligment=(col)=>{
        if ('align' in col){
            return col['align']
        }else{
            return 'center' //default value
        }
    

    }

    const BodyTemplate = (rowData,col) => {       
        if ('type' in col){
            return  formatedValue(rowData[col.field],col.type);
        }else{
            //evaluate if must be capitalized
            if (('capitalize' in col) && (Object.keys(rowData).length)>0 ){
              return rowData[col.field].charAt(0).toUpperCase()  +rowData[col.field].slice(1).toLowerCase();        
            }else{
                return rowData[col.field];
            }
                
        }

        //return formatedValue(rowData.price);
    }
    const dynamicColumns = columns.map((col,i) => {
        return <Column headerStyle={{'color':'black',
        'fontSize':'13px',
        'width':'130px',        
        'textAlign':'right'
    }}     
  
    resizeable={false} 
      align='left' key={col.field} field={col.field} 
    
        header={col.header} sortable={applyProp('sorted',col)}
        style={{
    'textAlign':'left',
    'width':'120px',
    'fontSize':'13px','color':'#595959'}}
        filter={applyProp('filter',col)} 
        body={(e)=>BodyTemplate(e, col)}
     
        />;
    });

    


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => select_function(rowData)} />
             </React.Fragment>
        );
    };

    const showSelect = ()=>{
        if (show_select === true){
            return (    
          
                <Column
                style={{
                    'textAlign':'left',
                    'width':'120px',
                    'fontSize':'13px','color':'#595959'}}
                    header=''
        body={actionBodyTemplate}  exportable={false}></Column>)
        }


    }

    //paginator  
    return (
            
            <div> 
           
                <DataTable value={data} 
                showGridlines={show_grid}
                size="small" 
                tableStyle={{ Width: '100%' }}           
                rowClassName={'content_data_table'} 
                rowsPerPageOptions={[5,10,20,50]}
                paginator = {paginate}           
                rows={item_by_page}
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                filters={filters}
                responsiveLayout="scroll"           
                 header={header}
                 scrollable scrollHeight={table_max_height}>
                    {dynamicColumns}
                    {showSelect()}
    
                </DataTable>
            </div>
        
        )
}