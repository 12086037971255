import React from "react";
import { Card } from "react-bootstrap";

export default function  PageTitle({title=''}){
    return (
        <Card style={{'marginBottom':'5px', 'width':'100%', 'marginLeft':'04px', 'marginRight':'10px'}}>
        <Card.Body>
            <h4>
                {title}
            </h4>
        </Card.Body>
    </Card>
    )

}