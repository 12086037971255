import React, {useState,useEffect} from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import {
    convertAPIDateFormat,
    convertLeaftMap,
    convertSearchList,
    getLabelMeasurement,
    getUnitMeasurement,
    getValueMeasurement,
    formatNumberPosDecimalRegion
} from "../../../services/utilsService";
import {Alert, Button} from "react-bootstrap";
import BootstrapSelect from 'react-bootstrap-select-dropdown';

import {CallAPI} from "../../../services/apiService";
import KPICard from "../../../components/statistic/KPICard";
import NivoCalendar from "../../../components/charts/NivoCalendar";
import GhgMap from "../../../components/geomap/GhgMap";
import {useSearchParams} from "react-router-dom";
import useCookie,  {getCookie} from 'react-use-cookie';
import PageTitle from '../../../components/pageTitle/pageTitle';
import JRCTable from '../../../components/JRCTable/JRCTable';
import { useSelector } from 'react-redux';
import msgDialog from '../../../services/dialogService';


export default function SupplierProfile(){

    const [startDate, setStartDate]=useState('2021-01-01');
    const [endDate, setEndDate]=useState('2021-08-01');
    const [client,setClient] = useState('hofer');
    const [suppliers,setSuppliers] = useState([{}]);
    const [searchParams, setSearchParams] = useSearchParams()
    const [supplierSelected, setSupplierSelected]= useState(searchParams.get('supplier'));
    const [suppliersLocation, setSupplierLocation] = useState([]);
    const [rdcDestiny, setRDCDestiny] = useState([]);
    const [rdcSelected, setRdcSelected]=useState()
    const [supplierStat, setSupplierStat] = useState({})
    const [productClassSelected, setProductClassSelected]=useState('ALL');
    const [emissionByDate, setEmissionByDate] = useState([])
    const [supplierMap, setSupplierMap]=useState({data:[],supplier:''});
    const [countries, setCountries]=useState([]);
    const [productClass, setProductClass] = useState([]);
    const [topRoutes, setTopRoutes] = useState(10);
    const loginState = useSelector((state)=>state.login);
    const [selectedCountry , setSelectedCountry]=useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );
    const [cookieDefaultCountry, setCookieDefaultCountry] = useCookie('GHG-DEFAULT-COUNTRY',null);
   

    const [calculationMethod,setCalculationMethod] = useState('basic');
    const [scope, setScope] = useState('inbound');   
    const [cookieInitialDate, setCookieInitialDate] = useCookie('GHG-INITIAL-DATE',null);
    const [cookieEndDate, setCookieEndDate] = useCookie('GHG-END-DATE',null);



    let general_parameters={
        client_id:client, start:getCookie('GHG-INITIAL-DATE') || startDate,
         end:getCookie('GHG-END-DATE') || endDate ,
        level:calculationMethod, scope:scope, country:getCookie('GHG-DEFAULT-COUNTRY') || selectedCountry,
        category:productClassSelected,top:10,
        supplier:supplierSelected ? supplierSelected: null,
        measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')

    }

    const getProductClassCatalog=()=>{
        CallAPI('GET',"/api/visual/v2/product_class_catalog/" + client ,{} ).then((resp)=>{
            if (resp.status === 200){
                setProductClass(resp.data);
            }
        })
    }

    const getSupplierCatalog=()=>{      
        CallAPI('GET',"/api/visual/v2/catalogue_supplier_road/"  
        ,{
            client_id: client,
            country: getCookie('GHG-DEFAULT-COUNTRY') || selectedCountry,
            scope: scope,
            level:calculationMethod
        }
        ).then((resp)=>{       
            if (resp.status === 200){
                setSuppliers(convertSearchList(resp.data,'supplier_id','supplier_name'));
            }
        })
    }

    const getSupplierLocation=()=>{
        CallAPI('GET',"/api/visual/v2/supplier_locations/" ,general_parameters ).then((resp)=>{
            
            if (resp.status === 200){
                setSupplierLocation(resp.data);
            }
        })
    }


    const getRDCDestination=(supplier,top)=>{
        setTopRoutes(top);
        CallAPI('GET',"/api/visual/v2/rdc_by_supplier_individual",
    general_parameters
     ).then((resp)=>{
            if (resp.status === 200){
                setRDCDestiny(resp.data);
                let series=[{'name':'production_facility','latitude_field': 'production_facility_latitude',
                    'longitude_field': 'production_facility_longitude','icon':'production',
                    'state':'production_facility_state', 'city': 'production_facility_city',
                    'label_type':'Production Facility',
                    'origin_latitude':'production_facility_latitude',
                    'origin_longitude': 'production_facility_longitude',
                    'line_color':'white',
                    'line_color_dark':'#333333',
                    'emission_field':'total_emission'
                },
                    {'name':'supplier','latitude_field': 'supplier_latitude',
                        'longitude_field': 'supplier_longitude', 'icon':'hub' ,
                        'state':'supplier_state', 'city': 'supplier_city',
                        'label_type':'Supplier','origin_latitude':'production_facility_latitude',
                        'origin_longitude': 'production_facility_longitude',
                        'line_color':'#ef5350',
                        'line_color_dark':'#ef5350',
                        'emission_field':'total_emission'
                    },
                    {'name':'rdc','latitude_field': 'rdc_latitude',
                        'longitude_field': 'rdc_longitude', 'icon':'rdc',
                        'state':'destination_state', 'city': 'destination_rdc',
                        'label_type':'RDC',
                        'origin_latitude':'supplier_latitude',
                        'origin_longitude': 'supplier_longitude',
                        'line_color':'#004d00',
                        'line_color_dark':'#004d00',
                        'emission_field':'total_emission'
                    } ];

                if (resp.data.length > 0){
                    let data = convertLeaftMap(resp.data,series);                  
                    setSupplierMap({data: data , supplier:supplierSelected})
                }else{
                    setSupplierMap({data: null , supplier:supplierSelected})
                }

           

            }})}

    const getSupplierStat=()=>{
        CallAPI('GET',"/api/visual/v2/stats_supplier", general_parameters
        ).then((resp)=>{
          if (resp.status === 200){
                setSupplierStat(resp.data);
            }})}


    const getEmissionByDate=()=>{
        CallAPI('GET',"/api/visual/v2/emission_by_date_supplier" ,
     general_parameters
        ).then((resp)=>{
            if (resp.status === 200){
                setEmissionByDate(resp.data);
            }
        })}




    //get supplier locations

    const getData=()=>{
        if (supplierSelected && startDate && endDate){
            getSupplierLocation();
            getRDCDestination(supplierSelected, 10);
            getSupplierStat();
            getEmissionByDate();
       
        }
   }

    useEffect(()=>{
        getProductClassCatalog();
        getSupplierCatalog();
        //getCountries();
        setCountries(loginState.userListCountries);
        getData();
        setStartDate(getCookie('GHG-INITIAL-DATE') );
        setEndDate(getCookie('GHG-END-DATE') );  
     },[])

    //table parameters for supplier location
    const colTableLocations = [{'header':'State','field':'supplier_state'},
        {'header':'City','field':'supplier_city'},
        {'header':'Zip code','field':'supplier_zipcode'},
        {'header':'Street','field':'supplier_street'},       
        {'header':'Emission in tCO2e - Production Facility to Hub', 'field':'emission_first_leg', 'type':'decimal', 'sorted':true},
        {'header':'Emission in tCO2e - Hub to RDC', 'field':'emission_second_leg','type':'decimal','sorted':true},
        {'header':'Intensity factor (gCO2e)','field':'intensity_factor' ,'type':'decimal','sorted':true}
    ];

    const colTableRDC = [{'header':'Prod. Facility City','field':'production_facility_city'},
        {'header':'Supplier City','field':'supplier_city'},
        {'header':'RDC City','field':'destination_rdc'},
        {'header':'Total emission (tCO2e)','field':'total_emission' ,'type':'decimal','sorted':true},
        {'header':'Intensity factor (gCO2e)','field':'intensity_factor' ,'type':'decimal','sorted':true}
    ];

    const handleSubmit = event => {
        event.preventDefault(); //
    }

    const check_selected_country =(code)=>{
        if (code === getCookie('GHG-DEFAULT-COUNTRY')){
            return true;
        }else{
            return false;
        }
    }



    return (
        
        <Row>
            <PageTitle title='Supplier Profile (Inbound)' />   
            <Col md={2} xl={2} lg={2}>
                <Card className={'position-fixed'}   style={{ width: '16%', marginBottom:'10px'}}>
                    <Card.Body>
                        <Card.Title>
                            Filter options
                        </Card.Title>
                        <Form onSubmit={handleSubmit} style={{marginTop:'30px'}}>
                            <Row>
                                <Form.Label className={'text-muted'}>Supplier Name</Form.Label>
                                <BootstrapSelect style={{'width':'100%'}}
                                    showSearch={true}
                                    onChange={(e)=>{
                                        if (e.selectedValue.length > 0 ){
                                            setSupplierSelected(e.selectedValue[0] );
                                        }
                                    }}
                                    placeholder={'-- select --'}  options={suppliers} />

                                <Form.Group className="mb-3" controlId="calculation_method">
                                    <Form.Label className={'text-muted'}>Operation Country</Form.Label>
                                    <Form.Select aria-label="Operation Country" 
                                    onChange={(e)=> {setSelectedCountry(e.target.value);
                                        setCookieDefaultCountry(e.target.value);
                                        getSupplierCatalog();
            
                                        
                                        }} 
                                    >
                                        { countries.map((item)=>(
                                            <option selected={check_selected_country(item.country_code)}  key={item.country_code} value={item.country_code}>{item.country_name}</option>
                                        )) }

                                    </Form.Select>
                                </Form.Group>

                              {/* Hidden calculation method selector
                              
                                         <Form.Group className="mb-3" controlId="calculation_method">
                                    <Form.Label className={'text-muted'}>Calculation Method</Form.Label>
                                    <Form.Select aria-label="Calculation Method" >
                                        <option value='Basic'>Basic</option>
                                    </Form.Select>
                                </Form.Group>
                              */}
                     

                             {/* hide scope
                                           <Form.Group className="mb-3" controlId="scope">
                            <Form.Label className={'text-muted'}>Scope</Form.Label>
                            <Form.Select aria-label="Scope" onChange={(e)=>setScope(e.target.value)} >
                                <option value='inbound'>Inbound</option>
                                <option disabled value='outbound'>Outbound</option>
                            </Form.Select>
                        </Form.Group>
                             
                             */}
                  


                                <Form.Group className="mb-4" controlId="startDate">
                                    <Form.Label className={'text-muted'}>Start date</Form.Label>
                                    <Form.Control required  value={startDate || ''} controlid="start_date" type="date" placeholder="Enter the start date"
                                                  onChange={(e)=>{
                                                      setStartDate(convertAPIDateFormat( e.target.value ));
                                                      setCookieInitialDate(convertAPIDateFormat( e.target.value ));
                                                                                              }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please select a valid date
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="endDate">
                                    <Form.Label className={'text-muted'}>End date</Form.Label>
                                    <Form.Control aria-required={true} required value={endDate || ''} controlid="end_date" type="date" placeholder="Enter the end date"
                                                  onChange={(e)=> {setEndDate(convertAPIDateFormat(
                                                      e.target.value
                                                  ));
                                                  setCookieEndDate(convertAPIDateFormat( e.target.value ));

                                                  }
                                                      //call external function
                                                  } />
                                </Form.Group>
                                {/* hide filter
                                                                <Form.Group className="mb-3" controlId="product_class_2">
                                    <Form.Label className={'text-muted'}>Product class</Form.Label>
                                    <Form.Select aria-label="Product class selection" onChange={(e)=>{
                                        setProductClassSelected(e.target.value);

                                    }}>
                                        <option value='ALL'>ALL</option>
                                        {
                                            productClass.map((item)=>(
                                                <option value={item.product_class}>{item.product_class}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                                
                                */}

                                <Button style={{marginTop:'35px'}} variant="danger"
                                        onClick={()=>{getData()

                                        }}
                                >Apply filter</Button>

                            </Row>
                                        </Form>
                    </Card.Body>
                </Card>

            </Col>
            <Col xs={10} md={10} xl={10}>
 
                <Row>
                    <Col md={6} xl={6} lg={6}>
                        <Card >
                            <Card.Body>
                                { !supplierSelected ?      <Alert variant="danger" style={{ width: "100%" , }}>
                            No supplier selected , please apply a desired filter on the left and click on "Apply filter"
                             </Alert> : <></> }
                       
                                <Alert variant='info'>
                                    <h4>{supplierSelected || searchParams.get('supplier')  }</h4>

                                    <h6>Product class: {productClassSelected}</h6>
                                </Alert>
                                <JRCTable
                                    columns={colTableLocations}
                                    export_table={true}
                                    data={suppliersLocation}
                                    item_by_page={10}
                                    paginate={true}
                                    show_title={true}
                                    show_search={false}
                                    table_max_height='100%'     
                                    table_title={'TOP ' + topRoutes +' : Supplier locations with highest emission in tCO' + 2 + 'e for the supplier ' + (supplierSelected || searchParams.get('supplier'))}
                                    />   

                                <div style={{paddingTop:'20px'}}>
                                <JRCTable
                                    columns={colTableRDC}
                                    export_table={true}
                                    data={rdcDestiny}
                                    item_by_page={10}
                                    paginate={true}
                                    show_search={true}
                                    show_title={true}
                                    table_max_height='100%'     
                                    table_title={'TOP ' + topRoutes + ': Routes with highest emission in tCO2e for the supplier ' + (supplierSelected || searchParams.get('supplier'))}
                                    /> 
                              </div>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} xl={6} lg={6}>
                        <Card>
                            <Card.Body>
                                <Row style={{paddingTop:'10px'}}>
                                    <Col xs={6}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={ formatNumberPosDecimalRegion( getValueMeasurement('distance', supplierStat.avg_distance_to_hub),2)}
                                        title={'Average distance  from production facility to hub in ' + getLabelMeasurement('distance')}
                                        content_explanation={'This KPI represents the average driven distance on the first supply chain segment from the selected supplier to hub (' + getUnitMeasurement('distance') + ')' }
                                    /></Col>
                                    <Col xs={6}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(getValueMeasurement('distance',supplierStat.avg_distance_to_rdc),2)}
                                        title={'Average distance from hub to RDC in ' + getLabelMeasurement('distance')}
                                        content_explanation='This KPI represents the average driven distance on the second supply chain segment from the Hub location to ALDIs Regional Distribution Center. '
                                    /></Col>
                                    <Col xs={6}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(supplierStat.avg_shipment_weight,2)}
                                        title='Average shipment weight in tonnes'
                                        content_explanation='Represents the average weight in tonnes for the shipments processed. '
                                    /></Col>
                                        <Col xs={6}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(supplierStat.avg_transportation_activity,2)}
                                        title='Average transport activity (tkm) in thousands'
                                        content_explanation='Represents the average transport activity expressed in tkm (tonne-kilometre) '
                                    /></Col>
                                     <Col xs={6}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(supplierStat.total_emission,2)}
                                        title='Total emission in tCO2e '
                                        content_explanation='Represents the total emission product to transport the products provided by this supplier , expressed in tCO2e '
                                    /></Col>
                                     <Col xs={6}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(supplierStat.intensity_factor,2)}
                                        title='Average emission intensity factor (gC02/tkm) '
                                        content_explanation='Represents the average emission intensity factor for the filtered shipping orders'
                                    /></Col>

                                </Row>
                                <Row style={{'marginBottom':'10px'}}>
                                    <Col md={12} xl={12} lg={12}>
                                        { emissionByDate.length > 0 ?
                                            <NivoCalendar data={emissionByDate}
                                            y_possition={-100}
                                            
                                                          title={'Emission concentration in tCO2e by day for product class ' + productClassSelected + ' for the supplier ' + supplierSelected }
                                                          description={'This chart shows the heat color representation of the emission produced for each day for a specific product class  '}
                                            />
                                            :<Card>
                                                <Card.Body>
                                                    <h3 style={{width:'100%', textAlign:'center'}}>No data to show</h3>
                                                </Card.Body>
                                            </Card>
                                            }


                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} xl={12} lg={12}>
                                        { supplierMap ?   <GhgMap
                                            show_detail={false}
                                            show_slide={true}
                                            title={'TOP ' + topRoutes + ': Shipment routes with highest emission for the supplier '+ supplierSelected}
                                            slide_function={getRDCDestination}
                                            data={supplierMap}  rdc_selected={rdcSelected}
                                                                      height={'820px'}
                                                                      click_function={(v)=>{setRdcSelected(v) }}
                                        /> : null }

                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Col>

        </Row>
    )
}
