import React , {useState, useEffect} from 'react'
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector } from 'react-redux';
import {convertAPIDateFormat} from "../../services/utilsService";
import {Button} from "react-bootstrap";
import {CallAPI} from "../../services/apiService";

import useCookie,{getCookie} from 'react-use-cookie';


export default function  SearchBox({client,initialStartDate,
    initialScope='',
    initialEndDate,
    funcStartDate,
    show_product_class=true,
    show_scope=true,
     funcEndDate,
      funcProductClass, funcProcess, funcCountry}){

    const [startDate, setStartDate]=useState();
    const [endDate, setEndDate]=useState();
    const [productClassSelected, setProductClassSelected]=useState();
    const [countries, setCountries]=useState([]);
    const loginState = useSelector((state)=>state.login);
    const [scope, setScope] = useState(initialScope);
    const [productClass, setProductClass] = useState([]);
    const [cookieInitialDate, setCookieInitialDate] = useCookie('GHG-INITIAL-DATE',null);
    const [cookieEndDate, setCookieEndDate] = useCookie('GHG-END-DATE',null);
    const [cookieDefaultCountry, setCookieDefaultCountry] = useCookie('GHG-DEFAULT-COUNTRY',null);
    const [selectedCountry , setSelectedCountry]=useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );
  
   




    const getProductClassCatalog=()=>{
        CallAPI('GET',"/api/visual/v2/product_class_catalog/" + client ,{} ).then((resp)=>{
            if (resp.status === 200){
                setProductClass(resp.data);
            }
        })
    }

    const check_selected_country =(code)=>{
        if (code === getCookie('GHG-DEFAULT-COUNTRY')){
            return true;
        }else{
            return false;
        }
    }

    const check_selected_scope =(code)=>{
        if (code === scope ){
            return true;
        }else{
            return false;
        }
    }


    useEffect(()=>{
        getProductClassCatalog();
        setCountries(loginState.userListCountries);
        setStartDate(getCookie('GHG-INITIAL-DATE') );
        setEndDate(getCookie('GHG-END-DATE') );   
       },[])


    return (
        <Card className={'position-fixed'}   style={{ width: '16%', marginBottom:'10px'}}>
            <Card.Body>
                <Card.Title>
                    Filter options
                </Card.Title>
                <Form style={{marginTop:'30px'}}>
                    <Row>
                        <Form.Group className="mb-3" controlId="calculation_method">
                            <Form.Label className={'text-muted'}>Operation Country</Form.Label>
                            <Form.Select aria-label="Operation Country" onChange={(e)=>{
                                setSelectedCountry(e.target.value);
                                setCookieDefaultCountry(e.target.value);
                                
                                               funcCountry(e.target.value);
                            
                            }
                            } >
                                { countries.map((item)=>(
                                    <option selected={check_selected_country(item.country_code)}  key={item.country_code} value={item.country_code}>{item.country_name}</option>
                                )) }

                            </Form.Select>
                        </Form.Group>
                        {/* Hidden calculation method selector
                                 <Form.Group className="mb-3" controlId="calculation_method">
                            <Form.Label className={'text-muted'}>Calculation Method</Form.Label>
                            <Form.Select aria-label="Calculation Method" >
                                <option value='Basic'>Basic</option>
                            </Form.Select>
                        </Form.Group>
                        
                        */}
               

                          { show_scope ?
                                   <Form.Group className="mb-3" controlId="scope">
                                   <Form.Label className={'text-muted'}>Scope</Form.Label>
                                   <Form.Select aria-label="Scope" >
                                   {check_selected_scope('inbound')? <option key="inbound" value='inbound'>Inbound</option> :<></> }
                                   {check_selected_scope('outbound')? <option key="outbound"  value='outbound'>Outbound</option> :<></> }
                     
                                   </Form.Select>
                               </Form.Group>
                          : <></>} 
               

                        <Form.Group className="mb-4" controlId="startDate">
                            <Form.Label className={'text-muted'}>Start date</Form.Label>
                            <Form.Control required  value={startDate || initialStartDate} controlid="start_date" type="date" placeholder="Enter the start date"
                                          onChange={(e)=>{
                                              setStartDate(convertAPIDateFormat( e.target.value ))
                                              setCookieInitialDate(convertAPIDateFormat(
                                                e.target.value
                                            ));
                                              funcStartDate(convertAPIDateFormat(
                                                  e.target.value
                                              ))
                                          }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please select a valid date
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="endDate">
                            <Form.Label className={'text-muted'}>End date</Form.Label>
                            <Form.Control aria-required={true} required value={endDate || initialEndDate} controlid="end_date" type="date" placeholder="Enter the end date"
                                          onChange={(e)=> {setEndDate(convertAPIDateFormat(
                                              e.target.value
                                          ));
                                          setCookieEndDate(convertAPIDateFormat(
                                            e.target.value
                                        ));
                                              funcEndDate(convertAPIDateFormat(
                                                  e.target.value
                                              ))
                                          }
                             //call external function
                            } />
                        </Form.Group>
                        {show_product_class?
                                  <Form.Group className="mb-3" controlId="product_class_2">
                                  <Form.Label className={'text-muted'}>Product class</Form.Label>
                                  <Form.Select aria-label="Product class selection" onChange={(e)=>{
                                      setProductClassSelected(e.target.value);
                                      funcProductClass(e.target.value);
                                      //call external function
                                  }}>
                                      <option value='ALL'>ALL</option>
                                      {
                                          productClass.map((item)=>(
                                              <option key={item.product_class} value={item.product_class}>{item.product_class}</option>
                                          ))
                                      }
                                  </Form.Select>
                              </Form.Group>
                        : <></>}
              
                        <Button style={{marginTop:'35px'}} variant="danger"
                                onClick={()=>{funcProcess(true)

                                }}
                        >Apply filter</Button>

                    </Row>
                    </Form>
            </Card.Body>
        </Card>
    )

}
