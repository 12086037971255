export const menuItems = [
    {
        title: 'Home',
        url: '/',
      },
      {
        title: 'General Dashboard',
        url: '/',
      },
      {
        title: 'Detailed Analysis',
        url: '#',
      },
  ];
  