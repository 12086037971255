import React, {useEffect, useState} from 'react'
import Card from 'react-bootstrap/Card'
import {Alert, Button} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useNavigate} from "react-router-dom";
import { useSelector } from 'react-redux';
import useCookie,  {getCookie} from 'react-use-cookie';

import * as Icon from 'react-bootstrap-icons';
import {CallAPI} from "../../services/apiService";
import {
    convertAPIDateFormat,
    convertAreaChartData,
    getValueMeasurement,
    convertPieNIVO, formatNumberPosDecimalRegion, convertRadialNivo
} from "../../services/utilsService";
import GhgAreaChartApex from "../../components/charts/GhgAreaChartApex";
import KPICard from "../../components/statistic/KPICard";
import msgDialog from '../../services/dialogService';
import NivoPie from '../../components/charts/NivoPie';
import PageTitle from '../../components/pageTitle/pageTitle';
import JRCTable from '../../components/JRCTable/JRCTable';
import NivoRadial from '../../components/charts/NivoRadial';
import GeneralSearchBox from '../../components/generalSearchBox/generalSearchBox';




export default function IntegralDashboard(){



    //local state definition
    const [startDate, setStartDate ] = useState(getCookie('GHG-INITIAL-DATE') || '2021-01-01');
    const [endDate, setEndDate ] = useState(getCookie('GHG-END-DATE') || '2023-06-30');
    const [generalPieData, setGeneralPieData] = useState({series:[], label:[]})
    const [generalPieDataNivo, setGeneralPieDataNivo] = useState([])
    const [evolEmission, setEvolEmission] = useState([]);
    const [statScope, setStatScope] = useState({});
      const navigation = useNavigate();    
    const [countries, setCountries]=useState([]);
    const [client,setClient] = useState('hofer');
    const [topRecords, setTopRecords] = useState(10);
    const [emissionDestination, setEmissionDestination] = useState([]);
    const [emissionProductClassDetail, setEmissionProductClassDetail]=useState([])
     const [calculationMethod,setCalculationMethod] = useState('basic');
    const [scope, setScope] = useState('inbound_outbound');
    const loginState = useSelector((state)=>state.login);

    const [cookieInitialDate, setCookieInitialDate] = useCookie('GHG-INITIAL-DATE',null);
    const [cookieDefaultCountry, setCookieDefaultCountry] = useCookie('GHG-DEFAULT-COUNTRY',null);
    const [cookieEndDate, setCookieEndDate] = useCookie('GHG-END-DATE',null);

   
    const [selectedCountry , setSelectedCountry]=useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code']) ;


    //variables for table display
    const [scopeTable,setScopeTable] = useState([]);
    const [productClassTable,setProductClassTable] = useState([]);

    
    //Tables structures definition

    const colEscope = [{'header':'Scope','field':'scope','capitalize':true},
        {'header':'Total emission (tCO2e)', 'sorted':true, 'field':'emission', 'type':'decimal'}];

    const colProductClass = [{'header':'Product class','field':'product_class','capitalize':true},
        {'header':'Total inbound emission (tCO2e)', 'sorted':true, 'field':'emission_inbound', 'type':'decimal'},
        {'header':'Total outbound emission (tCO2e)', 'sorted':true, 'field':'emission_outbound', 'type':'decimal'}
    
    ];

    //methods to get the data
    const getEmissionByTransport=()=>{
        CallAPI('GET',"/api/visual/v2/emission_inbound_outbound/",
        {client_id:client,
            start:startDate, end:endDate , scope:scope, level:calculationMethod, country:selectedCountry
            } ).then((resp)=>{
            if (resp.status === 200){
                //setGeneralPieData(convertPieChartData(resp.data,'emission','scope'));
                setScopeTable(resp.data);
                setGeneralPieDataNivo(convertPieNIVO(resp.data,'scope','scope','emission'))
            }
        })
    }


    const getEmissionProductClass=()=>{
        CallAPI('GET',"/api/visual/v2/emission_inbound_outbound_product_class/" , 
        {client_id:client,
            start:startDate, end:endDate , scope:scope, level:calculationMethod, country:selectedCountry
            }
        ).then((resp)=>{
            if (resp.status === 200){
                setProductClassTable(resp.data.data);

                let result = convertRadialNivo(resp.data.data,'product_class',resp.data.product_classes ,[{'label':'Inbound emission', 'field':'emission_inbound' },
                    {'label':'Outbound emission', 'field':'emission_outbound' }
                ],
                [{'label':'Inbound emission', 'field':'emission_inbound' },
                    {'label':'Outbound emission', 'field':'emission_outbound' }
                ]
                    )       
                setEmissionProductClassDetail(result.detail);
            }
        })

    }



    const getEvolutionByScope=()=>{
        CallAPI('GET',"/api/visual/v2/emission_evolution_inbound_outbound/",
        {
            client_id:client,
                start:startDate, end:endDate , level:calculationMethod, scope:scope, country:selectedCountry
               
        } ).then((resp)=>{
            if (resp.status === 200){
                setEvolEmission(convertAreaChartData(resp.data,'type','shipment_date','total_emission'))
            }
        })
    }

    const getScopeStat=()=>{
        CallAPI('GET',
            '/api/visual/v2/stats_inbound_outbound/',
            {
                client_id:client,
                    start:startDate, end:endDate , level:calculationMethod, scope:scope, country:selectedCountry
                        
            } 
            ).then((resp)=>{
                if (resp.status === 200){
                    setStatScope(resp.data);
                }
        })
    }



    const redirectRoad=()=>{
        navigation({pathname: "visualization/road_detailed",
        search:'?start_date=' + startDate + '&end_date=' + endDate});
    }



    //get the initial values for the date

    const getInitialDate=()=>{
        CallAPI('GET',
            '/api/visual/v2/initial_dates/',
            {
                client_id:client,
                level:calculationMethod, scope:scope, country:getCookie('GHG-DEFAULT-COUNTRY') || selectedCountry
     
            } 
            ).then((resp)=>{
            
            if (resp.status === 200){
                let data = resp.data;
             
                setCookieInitialDate(getCookie('GHG-INITIAL-DATE') ||  data.start_date);
                setCookieEndDate(getCookie('GHG-END-DATE') || data.end_date);
                setStartDate(getCookie('GHG-INITIAL-DATE') || data.start_date);
                setEndDate(getCookie('GHG-END-DATE') || data.end_date);
            }
        })
    }

   const showTransportDetail=(data)=>{
    if (data.id==='Road'){
        navigation({pathname: "/visualization/road_detailed",
         replace: true});
    }
    if (data.id==='Rail'){
        navigation({pathname: "/visualization/rail_detailed",
         replace: true});
    }
     }

    const callGetData=()=>{
        if (!startDate || !endDate){
            msgDialog.msgError("Please provide a valid date for the start and end period");
            return false;
        }

        let countries_list = loginState.userListCountries;
        setCountries(countries_list);
        getEmissionByTransport();
        //getEvolutionByTransportType();
        //getStatRoad();
        //getStatRail();
        //getEmissionByDestination();
        getScopeStat();    
        getEvolutionByScope();
        getEmissionProductClass();
    }

    const check_selected_country =(code)=>{
        if (code === getCookie('GHG-DEFAULT-COUNTRY')){
            return true;
        }else{
            return false;
        }
    }


    useEffect(()=>{   
        if (!client){
            setClient(loginState.userClient);
        }
       // getInitialDate();
        callGetData();
    },[])

    return (
        <div>                
            <Row>
            <PageTitle title='Emission general overview' /> 
            {/* }
            <Col xs={2} md={2} lx={2}>
                
            <GeneralSearchBox default_scope='inbound_outbound' func_get_data={callGetData}
            func_country={setSelectedCountry}
            func_start_date={setStartDate}
            func_end_date={setEndDate}
            />
            </Col>
    */}
           
                <Col xs={2} md={2} lx={2}>
       {/* Date parameters card */}
   
       <Card className={'position-fixed'}  style={{ width: '16%' }}>
                <Card.Body>
                    <Card.Subtitle>
                        Filtering options
                    </Card.Subtitle>
                        <Form style={{marginTop:'30px'}}>
                            <Form.Group className="mb-3" controlId="calculation_method">
                                <Form.Label className={'text-muted'}>Operation Country</Form.Label>
                                <Form.Select aria-label="Operation Country" onChange={(e)=>{setSelectedCountry(e.target.value)
                                getInitialDate();
                                      setCookieDefaultCountry(e.target.value);
                                }} >
                                    { countries.map((item)=>(
                                        <option selected={check_selected_country(item.country_code)} key={item.country_code} value={item.country_code}>{item.country_name}</option>
                                    )) }

                                </Form.Select>
                            </Form.Group>

                            {/* Hidden calculation method selector
                            
                              <Form.Group className="mb-3" controlId="calculation_method">
                                        <Form.Label className={'text-muted'}>Calculation Method</Form.Label>
                                        <Form.Select aria-label="Calculation Method" onChange={(e)=> setCalculationMethod(e.target.value) } >
                                            <option selected  value='basic'>Basic</option>
                                        </Form.Select>
                                    </Form.Group>

                            
                            
                            
                            */}


                            
                                  
                        
                             
                            <Form.Group className="mb-3" controlId="scope">
                                <Form.Label className={'text-muted'}>Scope</Form.Label>
                                <Form.Select aria-label="Scope" onChange={(e)=> setScope(e.target.value)} >
                                <option selected value='inbound_outbound'>Inbound + Outbound</option>
                               
                                </Form.Select>
                            </Form.Group>
                                              
                                    <Form.Group className="mb-4" controlId="startDate">
                                        <Form.Label className={'text-muted'}>Start date</Form.Label>
                                        <Form.Control value={startDate} controlid="start_date" type="date" placeholder="Enter the start date"
                                        onChange={(e)=>{
                                            setStartDate(convertAPIDateFormat( e.target.value ));
                                            setCookieInitialDate(e.target.value );

                                        }}
                                        />
                                    </Form.Group>
                         
                                    <Form.Group className="mb-3" controlId="endDate">
                                        <Form.Label className={'text-muted'}>End date</Form.Label>
                                        <Form.Control value={endDate} controlid="end_date" type="date" placeholder="Enter the end date"
                                        onChange={(e)=> {setEndDate(convertAPIDateFormat(e.target.value));
                                            setCookieEndDate(e.target.value);}
                                        }
                                        />
                                    </Form.Group>
                      
                                    <Button style={{marginTop:'20px', width:'100%'}} variant="danger"
                                    onClick={callGetData}
                                    >Apply filter</Button>
                                    <Form.Group>
                                    <Form.Text className="text-muted">
                                        Please select a calculation method and a time frame
                            </Form.Text>
                            </Form.Group>
                        </Form>
                </Card.Body>
            </Card>
            {/* Ending Card parameters */}

                </Col>
{/*Body section */}                
<Col xs={10} md={10} lg={10}>
    {statScope.total_emission === 0 ?
    <Alert variant='danger'>There is not data available for the current applied filters</Alert>
    
    :<></> }
  {/* Start generation visualization */}
            <Row>
                <Col xl={6} md={6}>
                    <NivoPie title={'Emission distribution in tCO2e'}
                             description={'This chart shows the total Greenhouse gas emission expressed in tonnes of CO2 equivalent (CO2e). The amount is shown for each type of transport offered in the respective scope. Outbound shipments are only carried out by road, whereas Inbound shipments can be carried out by road or rail. '}
                             height={400}
                             pad={9}
                             palette='pastel1'
                             show_legend={false}
                             label_value='Emission tCO2e'
                             show_arc_labels={true}
                                                 function_click={showTransportDetail}
                             data={generalPieDataNivo}/>
                             
                                    <JRCTable
                columns={colEscope}
                 export_table={true}
                 data={scopeTable}
                 item_by_page={5}
                 paginate={false}
                 show_search={false}
                 table_max_height='400px'     
                 table_title='Emission in tCO2 by scope'
                />  
                </Col>
                <Col xl={6} md={6}>
                <NivoRadial title={'Emission of Inbound and Outbound transport per product class'}
                        height={'400px'}
                        palette='pastel1'                       
                        description={'This chart shows the emission in tCO2e per product class for Inbound and Outbound transports.'}
                        data={emissionProductClassDetail}

            />
                <JRCTable
                columns={colProductClass}
                 export_table={true}
                 data={productClassTable}
                 item_by_page={5}
                 paginate={false}
                 show_search={false}
                 table_max_height='300px'     
                 table_title='Emission in tCO2 per scope and product class'
                />
            
                   

                   
        
                </Col>
            </Row>
            <Row style={{marginTop:'5px'}}>
                <Col xs={12} xl={12} md={12}>
                    <Card style={{height:'400px'}}>
                        <Card.Body>
                            <GhgAreaChartApex series={evolEmission}
                                              palette_name={'pastel1'}
                                              base_color={'#00c853'}
                                              type='area'
                                              height={'300px'}
                                              description={'This chart shows the development of total emission in tCO2e of the different scopes and transport modes within the selected time frame.'}
                                              title={'Emission by scope and transport type over time'}
                                              use_monochrome={false}
                                              default_colors={['#ff6666','#1f78b4','#00cc99']}
                                              x_title={'Date'} y_title={'Emission in tCO2e'}
                            />

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row style={{marginTop:'5px'}}>
                <Col xs={12} xl={12} md={12}>
                    <Card>
                        <Card.Body>
                        <Card.Title>KPIs for all transports (Inbound +  Outbound)</Card.Title>
                            <Row style={{paddingTop:'10px'}}>
                                <Col md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statScope.total_emission,2)}
                                    title='Total emission in tCO2e'
                                    content_explanation='Sum of the Greenhouse gas emission expressed in tCO2e for Inbound and Outbound transports.'
                                /></Col>
                                          <Col md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statScope.avg_transport_activity_thousand,2)}
                                    title='Average transport activity (tkm) in thousands'
                                    content_explanation='The transport activity is a commonly used indicator to measure how much total weight was transported over the total distance, expressed in thousands of tonne-kilometres.
                                    The value is calculated by multiplying kilometres travelled with the shipped volume in tonnes (Tonne-kilometre = driven km * shipped volume in tonnes). The value shows the average transport activity for each shipment.'
                                /></Col>
                                  <Col  md={2}  xs={3}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(statScope.total_processed_shipments,2)}
                                        title='Number of shipments processed'
                                        content_explanation='Number of all shipments processed within the selected time frame.'
                                    />
                                </Col>
                                                   
                                <Col  md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion( getValueMeasurement('distance', statScope.avg_emission_intensity),2)}
                                    title={'Average emission intensity factor (gCO2e / tkm)'}
                                    content_explanation={'This KPI shows the emission intensity factor resulting from the calculation of all transport-related emission, where the total emission, expressed in gCO2e, are divided by the tonnes x kilometres. This value represents the amount of emission expected for each tonne-kilometre travelled.'}
                                />
                                </Col>
                            </Row>
                            <Row>
                            <Col xs={3}>
                            <Button hidden={generalPieData.series.length === 0} variant="outline-danger" onClick={redirectRoad}>View road detailed dashboard</Button>{' '}
                             </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>

           {/* Inbound KPI start */}
           <Row style={{marginTop:'5px'}}>
                <Col xs={12} xl={12} md={12}>
                    <Card>
                        <Card.Body>
                        <Card.Title>KPIs for inbound scope</Card.Title>
                            <Row style={{paddingTop:'10px'}}>
                                <Col md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statScope.inbound?.total_emission,2)}
                                    title='Total emission in tCO2e'
                                    content_explanation='Sum of the Greenhouse gas emission expressed in tCO2e for Inbound transports.'
                                /></Col>
                                          <Col md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statScope.inbound?.avg_transport_activity_thousand,2)}
                                    title='Average transport activity (tkm) in thousands'
                                    content_explanation='The transport activity is a commonly used indicator to measure how much total weight was transported over the total distance, expressed in thousands of tonne-kilometres.
                                    The value is calculated by multiplying kilometres travelled with the shipped volume in tonnes (Tonne-kilometre = driven km * shipped volume in tonnes). The value shows the average transport activity for each Inbound shipment.'
                                /></Col>
                                  <Col  md={2}  xs={3}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(statScope.inbound?.total_processed_shipments,2)}
                                        title='Number of shipments processed'
                                        content_explanation='Number of Inbound shipments processed within the selected time frame.'
                                    />
                                </Col>
                                                   
                                <Col  md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion( getValueMeasurement('distance', statScope.inbound?.avg_emission_intensity),2)}
                                    title={'Average emission intensity factor (gCO2e / tkm)'}
                                    content_explanation={'This KPI shows the emission intensity factor resulting from the calculation of Inbound transport emission, where the total emission, expressed in gCO2e, are divided by the tonnes x kilometres. This value represents the amount of emission expected for each tonne-kilometre travelled.'}
                                />
                                </Col>
                            </Row>
                            <Row>
                            <Col xs={3}>
                            <Button hidden={generalPieData.series.length === 0} variant="outline-danger" onClick={redirectRoad}>View road detailed dashboard</Button>{' '}
                             </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>


           {/* Inbound KPI end */}


           {/* Outbound KPI start */}

           <Row style={{marginTop:'5px'}}>
                <Col xs={12} xl={12} md={12}>
                    <Card>
                        <Card.Body>
                        <Card.Title>KPIs for outbound scope</Card.Title>
                            <Row style={{paddingTop:'10px'}}>
                                <Col md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statScope.outbound?.total_emission,2)}
                                    title='Total emission in tCO2e'
                                    content_explanation='Sum of the Greenhouse gas emission expressed in tCO2e for Outbound transports.'
                                /></Col>
                                          <Col md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statScope.outbound?.avg_transport_activity_thousand,2)}
                                    title='Average transport activity (tkm) in thousands'
                                    content_explanation='The transport activity is a commonly used indicator to measure how much total weight was transported over the total distance, expressed in thousands of tonne-kilometres. For Outbound transports the value is calculated by multiplying kilometres driven with the shipped volume in tonnes (Tonne-kilometre = driven km * shipped volume in tonnes) PER TRANSPORT LEG. The value shows the average transport activity of the Outbound shipment legs. Further details and a calculation example can be found in the methodology tab.'
                                /></Col>
                                  <Col  md={2}  xs={3}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(statScope.outbound?.total_processed_shipments,2)}
                                        title='Number of shipments processed'
                                        content_explanation='Number of Outbound shipments processed within the selected time frame.'
                                    />
                                </Col>
                                                   
                                <Col  md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion( getValueMeasurement('distance', statScope.outbound?.avg_emission_intensity),2)}
                                    title={'Average emission intensity factor (gCO2e / tkm)'}
                                    content_explanation={'This KPI shows the emission intensity factor resulting from the calculation of Outbound transport emission, where the total emission, expressed in gCO2e, are divided by the tonnes x kilometres. This value represents the amount of emission expected for each tonne-kilometre travelled.'}
                                />
                                </Col>
                            </Row>
                            <Row>
                            <Col xs={3}>
                            <Button hidden={generalPieData.series.length === 0} variant="outline-danger" onClick={redirectRoad}>View road detailed dashboard</Button>{' '}
                             </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>

           {/* Outbound KPI end */}


                </Col>
            </Row>

     



        </div>
    )
}
