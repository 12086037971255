import React,{useState,useEffect} from 'react'
import { Alert, Card, Col, Row } from 'react-bootstrap'
import  AsyncSelect  from 'react-select/async';
import {CallAPI} from "../../../services/apiService";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import GhgBarDataLabelApex from "../../../components/charts/GhgBarDataLabelApex";
import {convertBarDataLabel} from "../../../services/utilsService";
import { convertAPIDateFormat,
    getUnitMeasurement,
    getValueMeasurement, convertLeaftMap, formatNumberPosDecimalRegion } from '../../../services/utilsService';
import msgDialog from '../../../services/dialogService';
import useCookie,  {getCookie, setCookie} from 'react-use-cookie';
import GhgMap from "../../../components/geomap/GhgMap";
import PageTitle from '../../../components/pageTitle/pageTitle';
import JRCTable from '../../../components/JRCTable/JRCTable';
import { useSelector } from 'react-redux';




export default function DetailProduct(){
const [startDate, setStartDate]=useState('2021-01-01');
const [endDate, setEndDate]=useState('2021-06-01');
const [client,setClient] = useState('hofer')
const [countries, setCountries]=useState([]);
const [inputValue, setValue] = useState('');
const [rdcSelected, setRdcSelected]=useState()
const [selectedProduct, setSelectedProduct] = useState(null);
const [productProfile, setProductProfile] = useState({});
const [emissionSupplier, setEmissionSupplier]=useState({data:[],categories:[]})
const [emissionDestination, setEmissionDestination]=useState({data:[],categories:[]})
const [emissionRoutes,setEmissionRoutes]=useState([]);
const [productMap, setProductMap]=useState({data:[],product:''});
const [topRoutes, setTopRoutes] = useState(10)
const [cookieInitialDate, setCookieInitialDate] = useCookie('GHG-INITIAL-DATE',null);
const [cookieEndDate, setCookieEndDate] = useCookie('GHG-END-DATE',null);
const [cookieDefaultCountry, setCookieDefaultCountry] = useCookie('GHG-DEFAULT-COUNTRY',null);
const [alertType, setAlertType] = useState('danger');
const [messageAlert,setMessageAlert]=useState('No product selected, please write a product name and select it  from the left side and click on "Apply filters."')



//TODO: Vincular con caja de busqueda

const [calculationMethod,setCalculationMethod] = useState('basic');
const [scope, setScope] = useState('inbound');
const loginState = useSelector((state)=>state.login);
const [selectedCountry , setSelectedCountry]= useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );

   

//table parameters for supplier location
const colTableRoutes = [{'header':'State of production','field':'production_facility_state'},
{'header':'City of production','field':'production_facility_city','capitalize':true},
{'header':'State of supplier','field':'supplier_state'},
{'header':'City of supplier','field':'supplier_city','capitalize':true},
{'header':'Emission in tCO2e prod. factility to hub','field':'segment_one_emission', 'type':'decimal','sorted':true},
{'header':'Emission in tCO2e hub to RDC' ,'field':'segment_two_emission' , 'type':'decimal', 'sorted':true},
{'header':'Destination RDC','field': 'destination_rdc' }
];


let general_parameters={
    client_id:client, start:startDate, end:endDate ,
    level:calculationMethod, scope:scope, country:selectedCountry,
    product_code:selectedProduct?.product_code,top:topRoutes,
    measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')

}

const getSupplierByProduct=()=>{
    if (startDate && endDate && selectedProduct){
        CallAPI('GET',"/api/visual/v2/emission_supplier_by_product/" ,general_parameters ).then((resp)=>{
           if (resp.status === 200){
            setEmissionSupplier(convertBarDataLabel(resp.data,'total_emission','supplier_name'))
          }
        })

    }else{
       msgDialog.msgWarning("Please select a product and provide a date range to search")
    }
}

const getDestinationByProduct=()=>{
    if (startDate && endDate && selectedProduct){
        CallAPI('GET',"/api/visual/v2/emission_destination_by_product/",general_parameters ).then((resp)=>{
           if (resp.status === 200){
            setEmissionDestination(convertBarDataLabel(resp.data,'total_emission','destination_rdc'))
          }
        })

    }else{
         msgDialog.msgWarning("Please select a product and provide a date range to search")
    }
}

const getProductProfile = ()=>{
    if (startDate && endDate && selectedProduct){
        CallAPI('GET',"/api/visual/v2/product_emission_profile/" ,general_parameters ).then((resp)=>{

            if (resp.status === 200){
                 if ( 'product_code' in resp.data){
                    setProductProfile(resp.data);
                      //getting emission by supplier
                      getSupplierByProduct();
                      getDestinationByProduct();
                      getProductRoutes(resp.data['product_code'] ,10);
                 }else{
                    setProductProfile(null)
                   setAlertType('danger')
                   setMessageAlert('Data not available, for the product ' + selectedProduct.product_description +  ' , In the period ' + startDate + ' - ' + endDate + ', please change the criteria filter.') ;
                    msgDialog.msgError("Data not found for the searching criteria")
                 }              
                          
            }
        })
    }else{
       msgDialog.msgWarning("Please select a product and provide a date range to search")
    }
}


//routes for product 

const getProductRoutes=(product,top)=>{    
    
    let local_parameters={
        client_id:client, start:startDate, end:endDate ,
        level:calculationMethod, scope:scope, country:selectedCountry,
        product_code:selectedProduct?.product_code,top:top,
        measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')
    
    }
    console.log(local_parameters)
    setTopRoutes(top);
    CallAPI('GET',"/api/visual/v2/route_map_by_product/" ,local_parameters ).then((resp)=>{
        console.log(resp.data);
        if (resp.status === 200){
            console.log(resp.data)
            setEmissionRoutes(resp.data.supply_chain);
            let series=[{'name':'production_facility','latitude_field': 'production_facility_latitude',
            'longitude_field': 'production_facility_longitude','icon':'production',
            'state':'production_facility_state', 'city': 'production_facility_city',
            'label_type':'Production Facility',
            'origin_latitude':'production_facility_latitude',
            'origin_longitude': 'production_facility_longitude',
            'line_color':'white',
            'line_color_dark':'#333333',
            'emission_field':null
        },
            {'name':'supplier','latitude_field': 'supplier_latitude',
                'longitude_field': 'supplier_longitude', 'icon':'hub' ,
                'state':'supplier_state', 'city': 'supplier_city',
                'label_type':'Supplier','origin_latitude':'production_facility_latitude',
                'origin_longitude': 'production_facility_longitude',
                'line_color':'#ef5350',
                'line_color_dark':'#ef5350',
                'emission_field':'segment_one_emission'
            },
            {'name':'rdc','latitude_field': 'rdc_latitude',
                'longitude_field': 'rdc_longitude', 'icon':'rdc',
                'state':'destination_state', 'city': 'destination_rdc',
                'label_type':'RDC',
                'origin_latitude':'supplier_latitude',
                'origin_longitude': 'supplier_longitude',
                'line_color':'#004d00',
                'line_color_dark':'#004d00',
                'emission_field':'segment_two_emission'
            } ];
            let data = convertLeaftMap(resp.data.supply_chain,series);
            setProductMap({data: data , supplier: selectedProduct.product_description})
        
        }})}


// handle input change event
const handleInputChange = value => {
    setValue(value);
  };

  // handle selection
  const handleChange = value => {
    setSelectedProduct(value);

  }

  const check_selected_country =(code)=>{
    if (code === getCookie('GHG-DEFAULT-COUNTRY')){
        return true;
    }else{
        return false;
    }
}
  const fetchProducts = () => {
    if (inputValue){
        return CallAPI("GET","/api/visual/v2/product_catalog/" ,{
            client_id: client, product_name: inputValue , 
            country_id: (getCookie('GHG-DEFAULT-COUNTRY') || selectedCountry )
        }).then((result)=>{
            const res =  result.data;
            return res;
        })
    } }


    const handleSubmit = event => {
        event.preventDefault(); //
    }

  useEffect(()=>{
     //getCountries();
     setCountries(loginState.userListCountries);
     //set default values for the date
     setStartDate(getCookie('GHG-INITIAL-DATE') );
     setEndDate(getCookie('GHG-END-DATE') );  
},[])

    return (
        <div>
           <PageTitle title='Product Profile (Inbound)' />
  
            <Row>
                <Col xs={2} md={2} lg={2}>
                <Card>
            <Card.Body>
                <Card.Title>
                    Filter options
                </Card.Title>
                <Form onSubmit={handleSubmit} style={{marginTop:'30px'}}>
                    <Row>
                    <Form.Group>
                        <Form.Label className={'text-muted'}>Product to Evaluate</Form.Label>
                        <AsyncSelect
                                cacheOptions
                                defaultOptions
                                placeholder="Write the product name"
                                value={selectedProduct}
                                getOptionLabel={e => e.product_description}
                                getOptionValue={e => e.id}
                                loadOptions={fetchProducts}
                                onInputChange={handleInputChange}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="calculation_method">
                            <Form.Label className={'text-muted'}>Operation Country</Form.Label>
                            <Form.Select aria-label="Operation Country" onChange={(e)=>{
                                setSelectedCountry(e.target.value); 
                                setCookieDefaultCountry(e.target.value);
                                
                                }} >
                                { countries.map((item)=>(
                                    <option   selected={check_selected_country(item.country_code)}   key={item.country_code} value={item.country_code}>{item.country_name}</option>
                                )) }

                            </Form.Select>
                        </Form.Group>

                        {/* Hidden calculaton method
                        
                                    <Form.Group className="mb-3" controlId="calculation_method">
                            <Form.Label className={'text-muted'}>Calculation Method</Form.Label>
                            <Form.Select aria-label="Calculation Method" >
                                <option value='Basic'>Basic</option>
                            </Form.Select>
                        </Form.Group>
                        */}
            
            
                  
                        <Form.Group className="mb-3" controlId="scope">
                            <Form.Label className={'text-muted'}>Scope</Form.Label>
                            <Form.Select aria-label="Scope" >
                                <option value='Basic'>Inbound</option>
                                <option disabled value='Basic'>Outbound</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-4" controlId="startDate">
                            <Form.Label className={'text-muted'}>Start date</Form.Label>
                            <Form.Control required  value={startDate } controlid="start_date" type="date" placeholder="Enter the start date"
                                          onChange={(e)=>{
                                              setStartDate(convertAPIDateFormat( e.target.value ));
                                              setCookieInitialDate(convertAPIDateFormat( e.target.value ));
                                                  }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please select a valid date
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="endDate">
                            <Form.Label className={'text-muted'}>End date</Form.Label>
                            <Form.Control aria-required={true} required value={endDate} controlid="end_date" type="date" placeholder="Enter the end date"
                                          onChange={(e)=> {setEndDate(convertAPIDateFormat(
                                              e.target.value
                                          ));
                                          setCookieEndDate(convertAPIDateFormat( e.target.value ));
                                             }
                             //call external function
                            } />
                        </Form.Group>
  
                        <Button style={{marginTop:'35px'}} variant="danger"
                                onClick={()=>{

                                    getProductProfile();

                                }}
                        >Apply filter</Button>
                    </Row>
                  </Form>
            </Card.Body>
        </Card> 
               
                </Col>
                {!productProfile?.product_class ?
                <Col xs={10} md={10} lg={10}>
                     <Alert style={{'fontSize':'18px'}} variant={alertType}>{messageAlert}</Alert>
                
                  
                </Col>
                      :


                  
                
                   

                <Col xs={10} md={10} lg={10}> 
                <Row>
                    <Col xs={4}>
                  
                       
                     <Card style={{height:'435px'}}>
                            <Card.Title style={{paddingLeft:'10px', paddingTop:'5px'}}>Product profile</Card.Title>
                            <Card.Body >
                                <Row>
                                    <Col style={{'textAlign':'right', 'fontWeight':'bold'}} md={6}>Product class:</Col>
                                    <Col md={6}>{productProfile?.product_class}</Col>                                   
                                </Row>
                                <Row>
                                    <Col style={{'textAlign':'right', 'fontWeight':'bold'}} md={6}>Product code:</Col>
                                    <Col md={6}>{productProfile?.product_code}</Col>                                   
                                </Row>
                                <Row>
                                    <Col style={{'textAlign':'right' , 'fontWeight':'bold'}} md={6}>Product description:</Col>
                                    <Col md={6}>{productProfile?.product_description}</Col>                                   
                                </Row>
                                <Row>
                                    <Col style={{'textAlign':'right' , 'fontWeight':'bold'}} md={6}>Total emission (tCO2e):</Col>
                                    <Col md={6}>{formatNumberPosDecimalRegion(productProfile?.total_emission,2)}</Col>                                   
                                </Row>
                                <Row>
                                    <Col style={{'textAlign':'right' , 'fontWeight':'bold'}} md={6}>Total shipped volume (t):</Col>
                                    <Col md={6}>{formatNumberPosDecimalRegion(productProfile?.total_weight_tons,0)}</Col>                                   
                                </Row>
                                <Row>
                                    <Col style={{'textAlign':'right' , 'fontWeight':'bold'}} md={6}>Average distance   ({getUnitMeasurement('distance') }):</Col>
                                    <Col md={6}>{formatNumberPosDecimalRegion(getValueMeasurement('distance',productProfile?.avg_distance_km),0)}</Col>                                   
                                </Row>
                                <Row>
                                    <Col style={{'textAlign':'right' , 'fontWeight':'bold'}} md={6}>Average emission intensity factor (gCO2e per tkm ):</Col>
                                    <Col md={6}>{formatNumberPosDecimalRegion(getValueMeasurement('distance',productProfile?.intensity_factor_gram),0)}</Col>                                   
                                </Row>

                            </Card.Body>
                        </Card>
                    
                       
                    </Col>
                    <Col xs={4}>
                      
                    
                    <Card>
                            <Card.Title  style={{paddingLeft:'10px', paddingTop:'5px'}}>Emission by supplier in tCO2e</Card.Title>
                            <Card.Body>
                            <GhgBarDataLabelApex
                                title={''}
                                subtitle={'This chart shows the destination with higher emission in tCO2e'}
                                data={emissionSupplier.data}  categories={emissionSupplier.categories}
                                use_monochrome={true} base_color={'#a6cee3'}
                                mode={'light'}
                                click_function={(v)=>{
                                    //getTopDestinationBySupplier(v);
                                }}
                            />

                            </Card.Body>
                        </Card>

                    </Col>
                    <Col xs={4}>
                       <Card style={{marginBottom:'10px'}}>
                            <Card.Title  style={{paddingLeft:'10px', paddingTop:'5px'}}>Emission by destination in tCO2e</Card.Title>
                            <Card.Body>
                            <GhgBarDataLabelApex
                                title={''}
                                data={emissionDestination.data}  categories={emissionDestination.categories}
                                use_monochrome={true} base_color={'#a6cee3'}
                                mode={'light'}
                                click_function={(v)=>{
                                    //getTopDestinationBySupplier(v);
                                }}
                            />

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {productMap?.data[0]?.detail.length  > 0? 
                    <Row>
                        <h2>{productMap?.data[0]?.detail.length > 0}</h2>
                    <Col sm={6} md={6} lg={6}>
                            <Card style={{'height':'100%'}}>
                                   <Card.Body>
                                            <JRCTable
                                        columns={colTableRoutes}
                                        export_table={true}
                                        data={emissionRoutes}
                                        item_by_page={10}
                                        paginate={true}
                                        show_title={true}
                                        show_search={true}
                                        table_max_height='100%'     
                                        table_title={'TOP ' + topRoutes + ' shipment routes with highest emission for ' + selectedProduct?.product_description }
                                        />   
                            </Card.Body>
                        </Card>                  
    
                    </Col>
                    <Col sm={6} md={6} lg={6}>
                       <GhgMap data={productMap}  rdc_selected={rdcSelected}
                                      height={'820px'}
                                        show_detail={false}
                                        show_slide={true}
                                        slide_function={getProductRoutes}
                                        slide_value={topRoutes}
                                        title={'Top ' + topRoutes + ' routes with highest emission product ' + selectedProduct?.product_description}
                                       click_function={(v)=>{setRdcSelected(v) }}
                            />
                    
                    </Col>
                </Row>
                
                :
                <Alert variant='danger'>
                    There is not geographical data available for the applied filters
                </Alert>
                }
            

                </Col>
}
            </Row>

        </div>
    )
}