import React, {useState} from 'react'
import Chart from 'react-apexcharts';
import {getCookie} from 'react-use-cookie';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GhgToolTip from '../tooltip/GhgTooltip';
import { formatNumberPosDecimalRegion } from '../../services/utilsService';

export default function GhgBarDataLabelApex({data, categories,
   title, subtitle, height=380,click_function=({}),
   description="",
    selected_color="#033060",
   mode='light',base_color,use_monochrome, palette_name='palette1'
  }){

    const region = getCookie('GHG-MEASURE-SEPARATOR') || 'de-DE';
    const [selectedIndex, setSelectedIndex]=useState(null)
    const series =[{
        data: data
      }];
    const options = {


    theme: {
      mode: mode, 
      palette: palette_name, 
      monochrome: {
          enabled: use_monochrome,
          color: base_color,
          shadeTo: mode,
          shadeIntensity: 0.70
      },
  },

        chart: {
            toolbar: {
                show:false
            },
        type: 'bar',
        height: height,
        events:{

          click: function(event, chartContext, config) {
            // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
           click_function(categories[config.dataPointIndex], data[config.dataPointIndex])
              setSelectedIndex(config.dataPointIndex);
       
          }
        }

      },
        colors: [
            function ({ value, seriesIndex, dataPointIndex, w }) {

                if (dataPointIndex == selectedIndex) {
                    return selected_color;
                } else {
                    return base_color;
                }
            }
        ],
        legend:{
            show: false,
        },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom'
          },
        }
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: [
              function ({ value, seriesIndex, dataPointIndex, w }) {

                  if (dataPointIndex == selectedIndex) {
                      return "#fff";
                  } else {
                      return "#1a1a1a";
                  }
              }

          ]
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + " ( " + formatNumberPosDecimalRegion(val,0) + " )"
        },
        offsetX: 0,
        dropShadow: {
          enabled: false
        }
      },
      stroke: {
        width: 2,
        colors: ['#fff']
      },
      xaxis: {
        categories: categories,
          labels:{
              formatter: function (val) {
                  return  formatNumberPosDecimalRegion(val,0)
              }
          }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      title: {
          text: '',
          align: 'left',
        floating: true
      },

      tooltip: {
        theme: 'dark',
        x: {
          show: false
        },

        y: {
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " +
                formatNumberPosDecimalRegion(val,0)  + ' tCO2e'
            },

          title: {
            formatter: function () {
              return ''
            }
          }
        }
      }
      };
    return (
      <>


               <Row>
                <Col md={11} xl={11}>
                <div style={{'textAlign':'left','fontWeight':'bold'}}>
                    <h5>{title}</h5>
                    </div>
                </Col>
                <Col xl={1} md={1} style={{'textAlign':'center'}}>
                  {description.length > 0 ? <GhgToolTip title={title}
                  content_explanation={description}
                  /> : <></>}
                  
               </Col>
            </Row>
   
        <Chart  options={options} series={series} type='bar' height={'350px'} />
        </>
    )
}
