import React, {useState,useEffect} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import NivoPie from '../../../components/charts/NivoPie';
import {CallAPI} from "../../../services/apiService";
import Card from 'react-bootstrap/Card';

import {getCookie} from 'react-use-cookie';


import {convertlineBarChartData, convertPieNIVO, convertAreaChartData, convertRadialNivo
} from "../../../services/utilsService";
import GhgAreaChartApex from "../../../components/charts/GhgAreaChartApex";
import NivoRadial from "../../../components/charts/NivoRadial";
import NivoCalendar from "../../../components/charts/NivoCalendar";
import JRCTable from '../../../components/JRCTable/JRCTable';
import { useSelector } from 'react-redux';


//TODO: Vincular search box parametros con el pais, scope 
export default function DetailProductClass({start_date, end_date, product_class='ALL',
                                           client, start_process,reset_process,
                                           country_code
                                           }){

    const [emissionProductClassNivo, setEmissionProductClassNivo] = useState([])
    const [emissionProductClassLineBar, setEmissionProductClassLineBar] = useState({series:[], x_axis:[]})
    const [dataTableTruck, setDataTableTruck] = useState([{}]);
    const [emissionTruckType, setEmissionTruckType] = useState([]);
    const [evolTruckType, setEvolTruckType ]=useState([{data:[]}]);
    const [emissionLegClassDetail, setEmissionLegClassDetail]=useState([])
    const [emissionLegClass, setEmissionLegClass]=useState([])
    const [dataTableProductClass, setDataTableProductClass] = useState([{}]);
    const [emissionByDate, setEmissionByDate]= useState([{}]);
    const loginState = useSelector((state)=>state.login);
    const [selectedCountry , setSelectedCountry]=useState(country_code);
    const [calculationMethod,setCalculationMethod] = useState('basic');
    const [scope, setScope] = useState('inbound');
  

    const colTableTruck = [{'header':'Truck Type',
    'field':'truck_type','capitalize':true},
        {'header':'Number of Shipments', 'sorted':true, 
        'field':'amount_orders', 'type':'integer'},
        {'header':'Total Emission (tCO2e)',
        'sorted':true, 'field':'total_emission','align':'center', 'type':'integer'} ];


    const colTableProductClass = [{'header':'Product Class','field':'product_class'},
        {'header':'Number of Shipments', 'sorted':true,'field':'number_orders', 'type':'integer', 'align':'center'},
        {'header':'Total Emission (tCO2e)', 'sorted':true, 'field':'total_emission', 'type':'decimal' } ];

    //general parameters
    let general_parameters={
        client_id:client, start:getCookie('GHG-INITIAL-DATE') || start_date   ,
         end:getCookie('GHG-END-DATE') || end_date ,
        level:calculationMethod, scope:scope, country:country_code,
        category:product_class

    }

    const getEmissionByProductClass=()=>{
        CallAPI('GET',"/api/visual/v2/emission_by_product_class" ,
        general_parameters
        ).then((resp)=>{
            if (resp.status === 200){
                setDataTableProductClass(resp.data);
                let resultBar = convertlineBarChartData(resp.data, 'product_class', [{name:'Emissions',type:'column', column_value: 'total_emission'},
                    {name:'Total Orders',type:'line', column_value: 'number_orders'}]);
                setEmissionProductClassLineBar(resultBar);
                setEmissionProductClassNivo(convertPieNIVO(resp.data,'product_class','product_class','total_emission' ))
            }
        })
    }

    //Getting the data
    const getEmissionByTruckType=()=>{
        CallAPI('GET',"/api/visual/v2/emission_truck_type",general_parameters ).then((resp)=>{
            if (resp.status === 200){
                setDataTableTruck(resp.data);
                setEmissionTruckType(convertPieNIVO(resp.data,'truck_type','truck_type','total_emission' ))
            }
        })
    }
    const getEvolutionByTruckType=()=>{
        CallAPI('GET',"/api/visual/v2/evol_emission_truck_type",general_parameters ).then((resp)=>{
            if (resp.status === 200){
                   setEvolTruckType(convertAreaChartData(resp.data,'truck_type','shipping_date','total_emission'))
            }
        })
    }

    const getEmissionByDate=()=>{
        CallAPI('GET',"/api/visual/v2/emission_by_date",general_parameters ).then((resp)=>{
            if (resp.status === 200){
                setEmissionByDate(resp.data);
            }
        })

    }

    const getEmissionByLeg=()=>{
        CallAPI('GET',"/api/visual/v2/emission_road_leg_product_class" , general_parameters ).then((resp)=>{
            if (resp.status === 200){
                let result = convertRadialNivo(resp.data,'product_class',['Core Range',
                    'Food Specials','Non Food Specials','Seasonal'
                ],[{'label':'Production facility to Hub', 'field':'emission_first_leg' },
                    {'label':'Hub to RDC', 'field':'emission_second_leg' }
                ],
                    [{'label':'Production facility to Hub','field':'emission_first_leg'},
                        {'label':'Hub to RDC','field':'emission_second_leg'}]
                    )
                setEmissionLegClass(convertPieNIVO(result.summary,'label','label','value' ))
                setEmissionLegClassDetail(result.detail);
            }
        })

    }

    const callData=()=>{
  
        if (start_process===true){
            getEmissionByProductClass();
            getEmissionByTruckType();
            getEvolutionByTruckType();
            getEmissionByLeg();
            getEmissionByDate();
            reset_process(false);

        }
    }

    useEffect(() => {
      callData();
    }, [start_process]);


return (
<div>
    <Row>
    <Col xs={6} md={6} lg={6}>
    <NivoPie show_legend={true}
    palette='pastel1'
              show_arc_labels={false}
              label_value='Emission tCO2e'
             description={'This chart shows the proportion (based of the total emission in tCO2e) for each product class in road transport.'}
             title={'Distribution of emission per product class for transport in % (tCO2e)'} 
             data={emissionProductClassNivo}/>
            <Card style={{height:'100%'}}>
            <Card.Body>
                <JRCTable
                 columns={colTableProductClass}
                 export_table={true}
                 data={dataTableProductClass}
                 item_by_page={5}
                 paginate={false}
                 show_search={false}
                 table_max_height='300px'     
                 table_title='Emission in TCO2 by product class'
                />   
        
            </Card.Body>
        </Card>

    </Col>
        <Col  xs={6} md={6} lg={6}>
            <NivoPie
            show_arc_labels={false}
            palette='pastel1'
            label_value='Emission tCO2e'            
                description={'The number in brackets represents the emissions generated for the order volume transported per truck type'}
                title={'Emission distribution in tCO2e per truck type'} data={emissionTruckType}/>
            <Card style={{height:'100%'}}>
                <Card.Body>
                <JRCTable
                columns={colTableTruck}
                 export_table={true}
                 data={dataTableTruck}
                 item_by_page={5}
                 paginate={false}
                 show_search={false}
                 table_max_height='300px'     
                 table_title='Emission in tCO2 by truck type'
                />  

                </Card.Body>
            </Card>
        </Col>
    </Row>
    <Row style={{marginTop:'10px'}}>
        <Col  xs={12} md={12} lg={12}>
            <Card>
                <Card.Body>
                    <GhgAreaChartApex series={evolTruckType}
                                      palette_name={'palette2'}
                                      base_color={'#00c853'}
                                      type='area'
                                      height={350}
                                      default_colors={['#a6cee3','#1f78b4']}
                                      title={'Evolution of emission by truck type in tCO2e'}
                                      use_monochrome={false}
                                      description={'This chart shows the evolution in the emission expressed in tCO2e for each kind of truck type.'}
                                      x_title={'Date'} y_title={'Emission in tCO2e'}
                    />

                </Card.Body>
            </Card>
        </Col>
    </Row>
    <Row style={{marginTop:'5px'}}>
        <Col  xs={12} md={12} lg={12}>

                    <NivoCalendar data={emissionByDate}
                    title={'Emission concentration in tCO2e per day'}
            description={'This chart shows the heat color representation of emission produced each day for the selected product class'}
                    />

        </Col>
    </Row>

    <Row style={{marginTop:'5px'}}>
        <Col   xs={6} md={6} lg={6}>
                 <NivoPie
                 palette='pastel1'
                 show_arc_labels={false}
                 label_value='Emission tCO2e'                 
                 title={'Emission distribution in tCO2e by supply chain segment'}
                          data={emissionLegClass}
                 description={'This chart shows the absolute (in tCO2e) and relative (in %) share of emission per supply chain segment - Production facility to Hub  and Hub to Regional Distribution Center (RDC).'}
                 />
        </Col>
        <Col xs={6} md={6}>
            <NivoRadial title={'Emission by segment in tCO2e grouped by product class'}
                        height={'380px'}
                        palette='pastel1'
                       
                        description={'This chart shows absolute emission in tCO2e per supply chain segment and product class.'}
                        data={emissionLegClassDetail}

            />

        </Col>
    </Row>
</div>

)


}
