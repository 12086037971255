import React from "react";
import PageTitle from "../../components/pageTitle/pageTitle";
import { Accordion, Alert, Tab, Tabs } from "react-bootstrap";
import Styles from './Methodology.css'



export default function Methodology(){

    return (
        <>
        
        <PageTitle title="Calculation Methodology"></PageTitle>
        <div className="tabContainer">
            <Tabs
             defaultActiveKey="inbound"
             id="methodology-tab"
             
             className="mb-2 coloredTab"
             
            >
                   <Tab key='inbound' className="tabContent"  tabClassName="tabContent" eventKey="inbound" title="Inbound transports">
                        <p className="content_title">In this section you will find a summary explanation of the methodology follow to perform 
                            the emission calculation in the inbound transport, grouped by type of used transport.
                        </p>

                        <Accordion className="accordion_block" defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header  className="accordion_header">Rail transport</Accordion.Header>
                                <Accordion.Body>
                                    <div className="accordion_content">
                                    <h4>Methodology</h4>
                                    <br></br>
                                    Rail transport includes all the transport of products using the rail infrastructure,leaving from:
                                        <ul>
                                            <li>The port of discharge.</li>
                                            <li>The production facility of the product.</li>
                                            <li>The hub where the product is stored before being transferred to the RDC.</li>
                                        </ul>

                                        <h5>For the current implementation the following assumptions were considered::</h5>

                                        <ul>
                                            <li>The emission intensity factor was taken from the GLEC framework V2021 (p.99), which has a value of 17g Co2e per t-km</li>
                                            <li>When the distance was not available from the shipment records, this was calculated using the origin and destination points (applying an extra 5% to the total as an adjustment factor).</li>
                                            <li>The shipments transported by rails are split into two segments: rail (95% of the route) and truck, meaning the very last part of the trip (5% of the route).</li>
                                            
                                        </ul>

                                        <p>To perform the emission calculation the following formula was used:</p>
                                        <span className="formula" >E = (D * W * IF)/1000.000  </span>
                                        <br></br>
                                        <p style={{marginTop:'20px'}}>Where:
                            <ul>
                                <li>E = Emission in tCo2e.</li>
                                <li>D = Distance expressed in Kilometres.</li>
                                <li>W = Weight expressed in kilograms, if another unit is used in the raw data this is converted.</li>
                                <li>IF = Intensity factor given in gCO2e/TKM.</li>
                                <li>/ 1000.000 to express the final result in tonne CO2 instead of grams.</li>
                            </ul>

                                        </p>

                                        <br></br>
                                        <h4>Main KPI considerations</h4>
                                        <ul>
                                            <li>The total emission presented is expressed in tCOe2.</li>
                                            <li>The average values are calculated considering the emission for each shipment order, one shipment order could contain more than one container.</li>
                                            <li>The values and charts displayed are linked with the filtering period.</li>
                                        </ul>


                                        

                                    </div>

                                </Accordion.Body>

                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Road transport </Accordion.Header>
                                <Accordion.Body>
                                <div className="accordion_content">

                                <h4>Methodology</h4>
                                    <br></br>
                                    Road transport includes all the transport of goods done by truck,leaving from :
                                             <ul>
                                            <li>The port of discharge (International shipments)</li>
                                            <li>The production facility of the product (Domestic shipments)</li>
                                            <li>The hub where the product is stored before being transferred to the RDC.</li>
                                            
                                        </ul>

                                        <h5>For the current implementation the following assumptions were considered:</h5>

                                        <ul>
                                            <li>All the trucks have been grouped into two categories,according to their temperature, Dry (for ambient goods) and Reefer (for temperature controlled goods), each category having an emission intensity factor.</li>
                                            <li>The emission intensity factor for dry trucks was taken from GLEC framework v2021 (p.101). </li>
                                            <li>The emission intensity factor for reefer (12% uplift compared to dry) was taken from the GLEC framework v2021 (p.105).</li>
                                            <li>The distance and the weight data were cleaned according to the defined limits (e.g 26 tonnes maximum payload).</li>
                                            <li>A shipment is assumed to be departing from a hub for distribution when the location of the production facility is distinct from the location of the supplier. </li>
                                            <li>For the shipments linked to a hub, the distance of the first leg (Production facility to Hub) was calculated and complemented with an extra 5% adjustment factor.</li>
<li>For shipments including a hub, the emissions calculation accounts for the first leg (PF to Hub) and the second leg (Hub to RDC).</li>
<li>The emission of a shipment order is split across all the products included in the order, based on their weight.</li>
                                            
                                                       
                                        </ul>

                                        <p>To perform the emission calculation the following formula was used:</p>
                                        <span className="formula" >E =  (D * W * IF)/1000.000 </span>
                                        <br></br>
                                        <p style={{marginTop:'20px'}}>Where:
                            <ul>
                                <li>E = Emission in tCo2e.</li>
                                <li>D = Distance expressed in Kilometres.</li>
                                <li>W = Weight expressed in kilograms, if another unit is used in the raw data this is converted.</li>
                                <li>IF = Intensity factor given in gCO2e/TKM. (according the truck type).</li>
                                <li>/ 1000.000 to express the final result in tonne CO2 instead of grams of CO2.</li>
                            </ul>

                                        </p>

                                        <br></br>
                                        <h4>Main KPI considerations</h4>
                                        <ul>

                                        <li>The total emission presented is expressed in tCO2e, this value is obtained from the sum of the emissions of each shipment order.</li>
<li>The average transport activity is obtained by multiplying the Distance in kilometres (D) by the Weight (in tonnes) for each shipment order, and then getting the average of these values.</li>
<li>This result is expressed in thousands for better readability.</li>
<li>The average emission intensity (EIF) is obtained from the (EIF) of each shipment order.</li>
<li>The number of shipments processed represents the number of unique shipment orders used for the emission calculation.</li>
<li>The average shipment weight in tonnes is obtained by summing up the individual shipment weights and dividing that by the number of shipment orders.</li>
<li>The average distance (km), is obtained by dividing the total distance (distance from Production facility to Hub to RDC) of the shipments by the number of shipment orders.</li>

                              </ul>


                   


</div>
                                </Accordion.Body>

                            </Accordion.Item>
                        </Accordion>
                    </Tab>
                    <Tab eventKey="outbound" title="Outbound transport" className="tabContent"  tabClassName="tabContent">
                    <p className="content_title">In this section you will find a summary explanation of the methodology follow to perform 
                            the emission calculation in the outbound transport , which is completelly conducted by road and following the Milkrun system approach.
                        </p>

                        <Accordion className="accordion_block" defaultActiveKey="0">
                      

                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Road transport </Accordion.Header>
                                <Accordion.Body>
                                <div className="accordion_content">
                                <h4>Methodology</h4>
                                    <br></br>
                                    Road transport includes all the transport of goods done by truck that area leaving from:
                                             <ul>
                                            <li>A Reginal Distribution Center (RDC)</li>
                                            <li>A supplier storage center</li>
                                            <li>From a branch to another</li>
                                            
                                        </ul>

                                        <h5>For the current implementation the following assumptions were considered:</h5>

                                        <ul>

                                        <li>A shipment order is divided into different stages (each stage is a stop in the route).</li>
<li>Average fuel consumption of 35 Liters of Diesel to travel 100 Kilometres was defined.</li>
<li>All the trucks used are assumed to be using Diesel fuel.</li>
<li>The emission intensity factor for Diesel was assumed to be 3,17 kg Co2e per liter according to GLEC v2021 (p.87).</li>
<li>The emissions were calculated based on the assumption that for each truck (on top of the products’ weight) we had to account for the weight of 33 “empty” pallets, each pallet weighing 20 gg.</li>
<li>Once the truck has been finished with one milk run, the truck will go back to the starting location with empty pallets only.</li>
<li>To account for this, a default weight of 800 kg (=33x20 kg + package weight) was defined for an empty truck.</li>
<li>The emissions are calculated for each stage of a shipment order and later summarised.</li>
<li>The emission of a shipment order is split across all the products included in the order, based on their weight.</li>
 </ul>

                                        <p>To perform the emission calculation the following set of formulas were used:</p>
                                        <p>I. Litres of fuel required to cover the complete route:</p>
                                        <span className="formula" >L =  (0.35 * D) </span>
                                        <br></br>
                                        <p style={{marginTop:'20px'}}>Where:
                            <ul>
                                <li>L = Litres of Diesel required</li>
                                <li>0.35  Liters of Diesel required to travel 1 Kilometre</li>
                                <li>D = Distance of the complete route</li>
                            </ul>
                                                                    </p>
                                    <p>II. Calculate transport activity for each stage
                                        <ul>
                                            <li>For each stage it is required to multiply the distance in kilometers (D) by the Weight in tonnes (W).</li>
                                            <li>Calculate the sum of the individual transport activity grouping by shipment order is then calculated.</li>
                                            <li>For each stage calculate the proportion of the transport activity (TA) between the shipment order transport activity must be calculated.</li>
                                            <li>Finally, the number of Diesel liters for each stage is multiplied by the emission of Co2 for each liter of Diesel.</li>

                                        </ul>
                                                                    </p>
                                                                    <p>III. Calculate the emission intensity for each stage</p>
                                                                    <span className="formula" >EI =  (E / TKM)/1000.000 </span>
                                                                    <p style={{marginTop:'20px'}}>Where:
                            <ul>
                                <li>EI = Emission Intensity</li>
                                <li>E = Emission in tCO2e for each stage</li>
                                <li>TKM = Weight (Tonne) * Distance (Km) for each stage </li>
                                <li>1000.000 = To express the final result in  tCO2e / Tkm</li>
                            </ul>
                            </p>

                                                                        
                                                                    


                                        <br></br>
                                        <h4 style={{marginBottom:'40px'}}>Main KPI considerations</h4>
                                        <ul>
                                            <li>The total emission presented is expressed in tCO2e, this value is obtained from the sum of the emission of each shipment order.</li>
                                            <li>Each shipment order is compounded by one or more stages.</li>
                                            <li>The average transport activity KPI is calculated using the average of the TKM for each shipment order, and this is obtained at the same time calculating the average TKM for all the stages that belong to a shipment order. Please see the following example:</li>
                                            <div style={{marginTop:'20px'}}>
                                                <table border={1} className="table" style={{maxWidth:'50%'}}>
                                                    <tr style={{background:'#000', color:'#fff'}}><th>Shipment order</th>
                                                    <th>Stage Number</th>
                                                    <th>Distance (Km)</th>
                                                    <th>Weight (Tonnes)</th>
                                                    <th>Transport activity (TKM)</th>
                                                    </tr>

<tr><td>5000052044x</td><td>1</td><td>236,706</td><td>9,918</td><td>2.347,65</td></tr>
<tr><td>5000052044x</td><td>2</td><td>78,666</td><td>8,390</td><td>660,00</td></tr>
<tr><td>5000052044x</td><td>3</td><td>29,849</td><td>5,301</td><td>158,23</td></tr>
<tr><td>5000052044x</td><td>4</td><td>59,441</td><td>0.800</td><td>47,55</td></tr>
<tr style={{fontSize:'18px', fontWeight:'bold', color:'blue'}}><td>Total</td><td>4</td><td>404,66</td><td>24,41</td><td></td> </tr>
                                                  
                                                     </table>
                                            </div>
                                            <div>
                                             <p>
To increase the transparency and accuracy of the calculation for each shipment order is calculated the average of the transport activity (TKM), instead of making a sum of the distance and the weight of each stage and calculating the transport activity with this summary results (e.g 404,66 X 24,409 = 9.877,39). You can see the difference in the results of both approaches. </p>

                                            </div>
                                            <div>
                                                <table border={1} className="table" style={{maxWidth:'50%'}}>
                                                    <tr style={{background:'#000', color:'#fff'}}><th>Method</th><th>Transport activity (TKM)</th></tr>
                                                    <tr><td>
                                                        Using average of individual stage values</td><td>803,36</td></tr>
                                                    <tr><td>Using the aggregated values for each shipment order</td><td>9.877,39</td></tr>
                                                    
                                                </table>
                                                <p>* The value of 803,36 TKM is more representative of the real transport activity for each stage that belongs to a shipment order.</p>
                                            </div>
                                            <li>The average emission intensity is calculated using the emission for each stage that belongs to a shipment order.</li>
                                         
                                         </ul>


                   


</div>
                                </Accordion.Body>

                            </Accordion.Item>
                        </Accordion>

    
                    </Tab>


            </Tabs>
        </div>
        </>

    )
}