
import React,{useState,useEffect} from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Form } from 'react-bootstrap';
import {Button} from "react-bootstrap";
import { CallAPI } from '../../../services/apiService';
import {convertAPIDateFormat} from '../../../services/utilsService';
import msgDialog from '../../../services/dialogService';
import NivoPie from '../../../components/charts/NivoPie';
import {convertPieNIVO} from "../../../services/utilsService";
import NivoCalendar from '../../../components/charts/NivoCalendar';
import PageTitle from '../../../components/pageTitle/pageTitle';
import JRCTable from '../../../components/JRCTable/JRCTable';
import useCookie,{getCookie} from 'react-use-cookie';
import {Alert} from "react-bootstrap";
import { useSelector } from 'react-redux';


export default function RDCAnalysis(){

    const [countries, setCountries]=useState([]);
    const [emissionProductClassNivo, setEmissionProductClassNivo] = useState([])   
    const [rdc, setRDC]=useState([]);
    const [selectedRDC, setSelectedRDC] = useState(null);
    const [level,setLevel] = useState('basic');
    const [startDate, setStartDate]=useState();
    const [emissionByDate, setEmissionByDate]= useState([{}]);
    const [scope,setScope]= useState('inbound');
    const [rdcProfile, setRDCProfile]=useState({})
    const loginState = useSelector((state)=>state.login);
    const [dataTableProductClass, setDataTableProductClass] = useState([{}]);
    const [dataTableCarriers, setDataTableCarriers] = useState([{}]);
    const [cookieDefaultCountry, setCookieDefaultCountry] = useCookie('GHG-DEFAULT-COUNTRY',null);
   

    const [endDate, setEndDate]=useState();
    const [client, setClient] = useState('hofer') // TODO: Linked with Cookie
    const [selectedCountry, setSelectedCountry]= useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );

   //Table emission by product class


   const colTableProductClass = [{'header':'Product Class','field':'product_class'},
   {'header':'Total Emission (tCO2e)','field':'total_emission', 'sorted':true, 'type':'decimal' } ];

   const colTableCarriers = [{'header':'Carrier Name','field':'carrier_name'},
   {'header':'Total Emission (tCO2e)','field':'total_emission', 'sorted':true, 'type':'decimal' } ];

    const getRDC=()=>{
        CallAPI('GET',"/api/visual/v2/rdc_catalog/" ,{
            client_id: client,
            country: getCookie('GHG-DEFAULT-COUNTRY') || selectedCountry,
            scope: scope,
            level:level
        } ).then((resp)=>{
            console.log(resp);
            if (resp.status === 200){    
                setRDC(resp.data);
             }
        })
    }


   //get RDC profile information
   const getRDCProfile=()=>{
    CallAPI('GET',"/api/visual/v2/rdc_profile/" ,{
        client_id: client,
        country: getCookie('GHG-DEFAULT-COUNTRY') || selectedCountry,
        scope: scope,
        level:level,
        rdc: selectedRDC
    } ).then((resp)=>{
        if (resp.status === 200){
           setRDCProfile(resp.data);
        }
    })
   }

   //get info about emission by product class for the Specific RDC
   const getRDCProductClass=()=>{
    CallAPI('GET',"/api/visual/v2/emission_by_product_class_rdc/" ,{
        client_id: client,
        country: getCookie('GHG-DEFAULT-COUNTRY')|| selectedCountry,
        scope: scope,
        level:level,
        start:startDate,
        end:endDate,
        rdc: selectedRDC
    } ).then((resp)=>{
        if (resp.status === 200){
            setDataTableProductClass(resp.data);
            setEmissionProductClassNivo(convertPieNIVO(resp.data,'product_class','product_class','total_emission' ))
        }
    })
   } 

   const getEmissionByDate=()=>{
    CallAPI('GET',"/api/visual/v2/emission_by_date_rdc/",{
        client_id: client,
        country: getCookie('GHG-DEFAULT-COUNTRY') || selectedCountry,
        scope: scope,
        level:level,
        start:startDate,
        end:endDate,
        rdc: selectedRDC
    } ).then((resp)=>{
        if (resp.status === 200){
            setEmissionByDate(resp.data);
        }
    })

}


   const getRDCCarriers=()=>{
    CallAPI('GET',"/api/visual/v2/emission_by_carrier_rdc" ,{
        client_id: client,
        country: getCookie('GHG-DEFAULT-COUNTRY') || selectedCountry,
        scope: scope,
        level:level,
        start:startDate,
        end:endDate,
        rdc: selectedRDC
    } ).then((resp)=>{
        if (resp.status === 200){
            setDataTableCarriers(resp.data);            
        }
    })
   }  

  const getData = ()=>{
    if (!startDate || !endDate || !selectedRDC ){
           msgDialog.msgError("You must select a RDC and provide a start and end date.");
        return false;
    }else{
        
        getRDCProfile();
        getRDCProductClass();
        getRDCCarriers();
        getEmissionByDate();
       }

  }


  const check_selected_country =(code)=>{
    if (code === getCookie('GHG-DEFAULT-COUNTRY')){
        return true;
    }else{
        return false;
    }
}


    useEffect(()=>{
          setCountries(loginState.userListCountries);
          getRDC();
          setStartDate(getCookie('GHG-INITIAL-DATE') );
          setEndDate(getCookie('GHG-END-DATE') );  
    },[])


    return (
        <div>            
                <Row>
                <PageTitle title='Regional Distribution Center (RDC) Analysis' />   
        
                <Col xs={2} md={2} lg={2}>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Filter options
                            </Card.Title>
                            <Form style={{marginTop:'30px'}}>
                            <Row>
                            <Form.Group className="mb-3" controlId="calculation_method">
                            <Form.Label className={'text-muted'}>Operation Country</Form.Label>
                            <Form.Select aria-label="Operation Country" onChange={(e)=> {setSelectedCountry(e.target.value);
                            setCookieDefaultCountry(e.target.value);
                            getRDC();

                            
                            }} >
                                { countries.map((item)=>(
                                    <option selected={check_selected_country(item.country_code)}   key={item.country_code} value={item.country_code}>{item.country_name}</option>
                                )) }

                            </Form.Select>
                        </Form.Group>
                            <Form.Group>
                        <Form.Label className={'text-muted'}>Regional Distribution Center</Form.Label>
                        <Form.Select aria-label="Regional Distribution Center"
                        autoComplete='true'
                        placeholder='Select'
                        onChange={(e)=>{
                              setSelectedRDC(e.target.value)}} >
                              <option selected value="SELECT" disabled>SELECT</option>
                                {
                                 rdc.map((item)=>(
                                    <option   key={item.rdc} value={item.rdc}>{item.rdc}</option>
                                )) }

                                

                            </Form.Select>
                        </Form.Group>

                    {/* Hidden calculation method selector
                    
                        <Form.Group className="mb-3" controlId="calculation_method">
                            <Form.Label className={'text-muted'}>Calculation Method</Form.Label>
                            <Form.Select aria-label="Calculation Method" onChange={(e)=> setLevel(e.target.value)} >
                                <option value='basic'>Basic</option>
                            </Form.Select>
                        </Form.Group>
                    */}
                    


                        <Form.Group className="mb-3" controlId="scope">
                            <Form.Label className={'text-muted'}>Scope</Form.Label>
                            <Form.Select aria-label="Scope" onChange={(e)=>setScope(e.target.value)} >
                                <option value='inbound'>Inbound</option>
                                <option disabled value='outbound'>Outbound</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-4" controlId="startDate">
                            <Form.Label className={'text-muted'}>Start date</Form.Label>
                            <Form.Control required  value={startDate } controlid="start_date" type="date" placeholder="Enter the start date"
                                          onChange={(e)=>{
                                              setStartDate(convertAPIDateFormat( e.target.value ))
                                                  }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please select a valid date
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="endDate">
                            <Form.Label className={'text-muted'}>End date</Form.Label>
                            <Form.Control aria-required={true} required value={endDate} controlid="end_date" type="date" placeholder="Enter the end date"
                                          onChange={(e)=> {setEndDate(convertAPIDateFormat(
                                              e.target.value
                                          ))
                                             }
                             //call external function
                            } />
                        </Form.Group>
  
                        <Button style={{marginTop:'35px'}} variant="danger"
                                onClick={()=>{
                                   getData();
                                }}
                        >Apply filter</Button>
                    </Row>
                            </Form>
                        </Card.Body>
                    </Card>

                </Col>
 

                { rdcProfile?.rdc_city ?

                      <Col xs="10" md="10" lg="10">
                      <Row>
                        <Col xs="6" md="6" lg="6">
                          <Card style={{'minHeight':'455px','height':'100%'}}>
                            <Card.Header>
                             <Card.Subtitle>
                                <h5>RDC general data for {selectedRDC}</h5>
                                </Card.Subtitle>
                            </Card.Header>
                            <Card.Body>
                                <div>
                                <Row>
                                    <Col xs="4" md="4" lg="4" style={{'textAlign':'right', 'fontWeight':'bold'}}>State Name:</Col>
                                    <Col xs="8" md="8" lg="8" style={{'textAlign':'left'}}>{rdcProfile?.state}</Col>
                                </Row>
                                <Row>
                                    <Col xs="4" md="4" lg="4" style={{'textAlign':'right','fontWeight':'bold'}}>City Name:</Col>
                                    <Col xs="8" md="8" lg="8" style={{'textAlign':'left'}}>{rdcProfile?.rdc_city}</Col>
                                </Row>
                                <Row>
                                    <Col xs="4" md="4" lg="4" style={{'textAlign':'right','fontWeight':'bold'}}>Zip Code:</Col>
                                    <Col xs="8" md="8" lg="8" style={{'textAlign':'left'}}>{rdcProfile?.zipcode}</Col>
                                </Row>
                                <Row>
                                    <Col xs="4" md="4" lg="4" style={{'textAlign':'right','fontWeight':'bold'}}>Latitude:</Col>
                                    <Col xs="8" md="8" lg="8" style={{'textAlign':'left'}}>{rdcProfile?.rdc_latitude}</Col>
                                </Row>
                                <Row>
                                    <Col xs="4" md="4" lg="4" style={{'textAlign':'right','fontWeight':'bold'}}>Longitude:</Col>
                                    <Col xs="8" md="8" lg="8" style={{'textAlign':'left'}}>{rdcProfile?.rdc_longitude}</Col>
                                </Row>
                                <div style={{'marginTop':'30px'}}>                      
    
                          <JRCTable
                                          columns={colTableCarriers}
                                          export_table={true}
                                          data={dataTableCarriers}
                                          item_by_page={10}
                                          paginate={true}
                                          show_title={true}
                                          show_search={true}
                                          table_max_height='300px'     
                                          table_title='TOP 10 carriers with the highest emission in tCO2e'
                                          />   
                           </div>
                                </div>                                              
                                
                                                   
                               
                         
                     
                            </Card.Body>
                          </Card>
    
                        </Col>
                        <Col xs="6" md="6" lg="6">
                        <Card style={{'height':'100%'}}>
                            <Card.Header>
                                <Card.Subtitle>
                                    <h5> Distribution of the RDC's road emission in tCO2e  per product class</h5>
                                   </Card.Subtitle>
    
                            </Card.Header>
                            <Card.Body>
                                       <div>
                                        <NivoPie show_legend={true}
                                        show_arc_labels={false}
                                        height={400}
                                        palette='pastel1'
                                        label_value='Emission tCO2e'
                                                       description={'The share of emission from Inbound road transport for the selected RDC is shown per product class.'}
                                                       title={'Share of emission (tCO2e) by product class'} data={emissionProductClassNivo}/>
                                          
                                          
                                                                              <JRCTable
                                                                                      columns={colTableProductClass}
                                                                                      export_table={true}
                                                                                      data={dataTableProductClass}
                                                                                      item_by_page={10}
                                                                                      paginate={false}
                                                                                      show_title={false}
                                                                                      show_search={false}
                                                                                      table_max_height='300px'     
                                                                                      />  
                                
    
                                </div>              
                                
                                   
         
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>                  
                      <Row style={{marginTop:'5px'}}>
            <Col  xs={12} md={12} lg={12}>               
            <NivoCalendar data={emissionByDate}
            title={'Emission in tCO2e per day for RDC ' + selectedRDC }
    description={'This chart shows the heat color representation of emission produced each day for the selected RDC.'}
            />                              
    
            </Col>
        </Row>
    
                    </Col>

                :
                <Col xs="10" md="10" lg="10">
                        <Alert variant="danger" style={{ width: "100%" , }}>
                            No Regional Distribution Center (RDC) selected , please select the desired filters on the left and click on "Apply filter"
                        </Alert> 
                </Col>
         
                }


          
            </Row>
        </div>
    )
}