import { createSlice } from "@reduxjs/toolkit";

/*add here the general status to  share between the application
 that are not related with some specific component
*/
const initialState = {
    processing :false,
    process:0
}

export const CoreSlice = createSlice({
    name:"core",
    initialState,
    reducers:{
        core_start_processing: (state)=>{
            state.processing = true;
            state.process = state.process +1
        },
        core_stop_processing: (state)=>{
            state.processing = false;
            state.process = state.process - 1
        }
    }

})

export const {core_start_processing, core_stop_processing} = CoreSlice.actions

export default CoreSlice.reducer
