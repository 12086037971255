import React,{useState} from "react"
import './login.scss'
import logo from '../../assets/images/jrc-live-white.png'
import mapImage from '../../assets/images/usa_map.webp'
import { Alert, Button, Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { login_authenticated } from "./loginSlice"
import { useDispatch , useSelector } from "react-redux"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CallAPI} from "../../services/apiService";
import dialogService from "../../services/dialogService";
import ForgotPassword from "./forgotPassword"
import msgDialog from "../../services/dialogService"
import useCookie,  {getCookie} from 'react-use-cookie';
import LoadingOverlay from 'react-loading-overlay-ts';
import icon_check from "../../assets/images/check_icon.png";
import icon_error from "../../assets/images/error_icon.png";


export default function Login({children}){
    
    //hook variables
    const loginState = useSelector((state)=> state.login);
    const [userId, setUserId] = useState('')
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [showChangeInitial, setShowChangeInitial] = useState(false);
    const [confirmNewPassword,setConfirmNewPassword]= useState('')
    const [showForgot, setShowForgot] = useState(false);
    const coreState = useSelector((state)=>state.core);
    const [CookiekeepLogged, setCookieKeepLogged] = useCookie('GHG-KEEP-LOGGED',null);
    const [cookieDefaultCountry, setCookieDefaultCountry] = useCookie('GHG-DEFAULT-COUNTRY',null);
                           
    //instance action dispatch
    const dispatch = useDispatch()
    let navigate = useNavigate();



    const email_passed=(value)=> 
    {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;   
        if (mailformat.test(value))
        {
            return true
        }else{
            return false
        }
}


     
    const password_passed=(value)=>{
        if (RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*+-])(?=.{8,})").test(value))
        {
            return true;
        }else{
            return false;
        }

    }


    const getMenu=(role_id)=>{
        CallAPI('GET',"/api/visual/v2/menu_by_role",{'role_id':role_id} ).then((resp)=>{
            if (resp.status === 200){
               let data = resp.data;            
               if (data){                   
                   localStorage.setItem('ghg_menu',JSON.stringify({'menu':data}))  
               }   
                  
           }
       })

    }

    const getCountries=(profile_data,user_data)=>{     
            CallAPI('GET',"/api/visual/v2/catalogue_countries/" ,{
            'codes': profile_data['countries'].join()
        } ).then((resp)=>{
            if (resp.status === 200){              
            
                dispatch(login_authenticated({
                    userId : user_data.user.email  ,
                    userName: user_data.user.email ,
                    userIdGraph: user_data.user.id,
                    userRoleId : profile_data['role_id'],
                    'userListCountries': JSON.stringify(resp.data),
                    userCountries : profile_data['countries'].join(),
                    userClient : 'hofer'
                }));
                getMenu(profile_data['role_id']);
            }
        })
    }

    
    const confirmarPasswordChange=()=>{
          if (password_passed(newPassword)===false){
            msgDialog.msgError("Password does not comply with the minimun security, please define a new one ");
            return false
          }
          if (newPassword !== confirmNewPassword){
            dialogService.msgError("The new password and the confirmation do not match");
            return false;
        }
          if (newPassword===confirmNewPassword ){
            CallAPI('POST','/auth/auth_ghg_core/change_initial_password',
            {'email':userId,'current_password':currentPassword,
            'session': loginState.cognitoSession,           
            'new_password': newPassword}).then((resp)=>{
                if (resp.status === 200){
                    if (resp.data['authenticated']===true){
                        msgDialog.msgSuccess("Password changed successfully, please proceed to log in ")
                        setShowChangeInitial(false);


                    }else{
                        if (resp.data['code']==='SESSION_EXPIRED'){
                          msgDialog.msgError('Error in the process,your session has expired, please refresh the page');
                        }else{
                            msgDialog.msgError("Error in the process, please check the current password provided");
                        }
                     
                    }

                }
            
            })

        }

    }



    const authenticate = ()=>{
          if (email_passed(userId)===false){
            msgDialog.msgError("Please provide a valid email address");
            return false;
          }
            if (userId && password){
                 CallAPI('POST','/auth/auth_ghg_core/auth_user',{'email':userId, 'password': password}).then((resp)=>{
        
                    if (resp.status === 200){                      
                        if (resp.data['authenticated']===false && resp.data['reason']==='WRONG_USER_PASSWORD'){
                            dialogService.msgError("Wrong user or password!!");
                        }
                        if (resp.data['authenticated']===false && resp.data['reason']==='CHANGE_PASSWORD_REQUIRED'){
                          dialogService.msgInfo("You must change the default passsword`");
                            setUserName(userName)
                        dispatch(login_authenticated({
                                userId : userId,
                                userName: null,
                              userIdGraph: '',
                              userAuthenticated: false,
                                cognitoSession: resp.data['session'],
                                userRoleId : null,
                                userClient : null
                            }));
                            setShowChangeInitial(true);
                        }
                         if (resp.data['authenticated'] === true){
                            //get the user profile, client , countries, role
                            //call the user profile
                            CallAPI('GET','/api/visual/auth_ghg/user_profile_by_email',{'user_email':userId}).then((resp)=>{
                                if (resp.status ===200){
                                    let user_profile = resp.data['profile'];
                                    if (user_profile['countries'][0]){
                                            setCookieDefaultCountry(user_profile['countries'][0]['country_code']);    
                                            setUserName(userName)                                               
                                            dispatch(login_authenticated({
                                                userId : userId,
                                                userName: user_profile['first_name'] + user_profile['last_name'],
                                                userIdGraph: '',
                                                userAuthenticated: true,
                                                cognitoSession: resp.data['access_token'],
                                                userRoleId : user_profile['role_id'],
                                                userClient : user_profile['client_id'],
                                                userListCountries: user_profile['countries'],
                                                userSelectedCountry: user_profile['countries'][0]['country_code']
                                            }));
                                                    
                                            navigate('/')
    
                                    }else{
                                        msgDialog.msgError("Your user has not been linked with some country, please contact your local administrator")
                                    }
                                }
                            })
                            
                                        
 
    
                        
                          }
                     }
    
            })
            //call authenticate function using the API service    
    
        }else{
                alert('An user and password must be provided');
    
        }       


  
}

const handleSubmit = event => {
    event.preventDefault(); //
}
if (loginState.userAuthenticated===true && loginState?.userListCountries?.lenght > 0 ){
    navigate('/')
}

const show_change_initial_password = ()=>{
    return (
        <Form onSubmit={handleSubmit}>
              <h2 style={{marginBottom:'30px'}}>Change initial password</h2>
        <Form.Group>
            <Form.Label>User</Form.Label>
            <Row>
                <Col xs={1} md={1} lg={1}></Col>
                <Col xs={11} md={11} lg={11}>
                <Form.Control  readOnly value={userId} type='text'></Form.Control>
                </Col>
            </Row>
          
        </Form.Group>
        <Form.Group>
            <Form.Label>Current password</Form.Label>
            <Row>
                <Col xs={1} md={1} lg={1}></Col>
                <Col xs={11} md={11} lg={11}>
                <Form.Control onChange={e=>setCurrentPassword(e.target.value)} type='password'></Form.Control>
        <Form.Text className='text-mude'>* Please write your current password </Form.Text>
   
                </Col>
            </Row>
             </Form.Group>
        <Form.Group>
        <Form.Label>New password</Form.Label>
        <Row>
                    <Col xs={1} md={1} lg={1}>
                    {password_passed(newPassword)? <img src={icon_check} alt="Icon check" style={{height:'30px'}} />:
                <img src={icon_error} alt="Icon error" style={{height:'30px'}} />
                }
                    </Col>
                    <Col xs={11} md={11} lg={11}>
                    
            <Form.Control onChange={e=>setNewPassword(e.target.value)} type='password'></Form.Control>
                    </Col>
                    </Row>
        
         </Form.Group>
         <Form.Group style={{marginTop:'6px'}}>
                <Alert  style={{marginLeft:'70px'}} variant='info'>The new password must comply with the security requirements: <li>Minimum 8 characters length</li>
                <li>Include an upper and lower case</li>
                 <li>Include a number and a special symbol (!@#$%^&*+-) </li>
             </Alert>
                </Form.Group>
        <Form.Group>
            <Form.Label>Confirm new password</Form.Label>
            <Row>
                <Col xs={1} md={1} lg={1}></Col>
                <Col xs={11} md={11} lg={11}>
                <Form.Control onChange={e=>setConfirmNewPassword(e.target.value)} type='password'></Form.Control>
        <Form.Text className='text-mude'>*** Re-enter the new password</Form.Text>

                </Col>
            </Row>
                   </Form.Group>
        {newPassword!==confirmNewPassword ?<Form.Group>
            <Alert  style={{marginLeft:'70px'}} variant='danger'>New password and the confirmation are differents</Alert>
            </Form.Group>
        
        : <></>}
            <Row>            
                <Col xs={6} md={6} lg={6}>
                <Button  style={{marginLeft:'70px'}} variant="primary" onClick={confirmarPasswordChange}>
                   Change password
               </Button>
                </Col>
              
            </Row>
       
    </Form>
    )
}

    return (
 <React.Fragment>
        <LoadingOverlay className='overlay_message'
      active={coreState.process > 0}
      spinner
      text='Please wait'
    >
  <div className="background-login">
            <div className="row">
                <div className="col-md-12">
                    <img className="logo-login" alt="" src={logo}/>
                </div>                
            </div>
            {/*alert message */ }
            <Alert variant="danger"> You must log in to access this page.</Alert>
            <div className="row content background-login">
                <div className="col-md-6 imageMap">
                    <img alt="US night map" src={mapImage}/>
                </div>
                <div className="col-md-6">
                { ( showForgot===true) ? <ForgotPassword showComponent={setShowForgot} />  :<></>     } 
                  { ( showChangeInitial===true ) ? show_change_initial_password()  : <></> } 
                     

                    { ( showForgot===false && showChangeInitial===false ) ?
                      <Form  onSubmit={handleSubmit}>
                      <h2>Sign in</h2>
                      <Form.Group  className="mb-3" controlId="formEmail" >
                        <Form.Control type="email" onChange={e => setUserId(e.target.value)}  placeholder="Your email address" />
                         </Form.Group>
                         <Form.Group className="mb-3" controlId="formPassword">
                          <Form.Control type="password"
                          onKeyUp={e=>{
                              if (e.code==='Enter'){
                              authenticate();    
                              }}}
                          onChange={e=>setPassword(e.target.value)}
                          placeholder="Password"/>
                         </Form.Group>
                         <Form.Group className="mb-3" controlId="formKeep">
                          <Form.Check defaultChecked={getCookie('GHG-KEEP-LOGGED')==='true' ? true : false}
                           type="checkbox" label="Keep me logged in"  onClick={e=>{
             
                            setCookieKeepLogged(e.target.checked)}} />
                      </Form.Group>
                      <Button className="signing" onClick={authenticate} variant="outline-info">Sign in</Button> 
                      <div className="forgot_password">              
                      <a  onClick={()=>{setShowForgot(true)}}  href="#">Did you forget your password? </a> 
                   
                      </div>             
                      </Form> 
                    :<></>
                  }
                </div>
                <div style={{textAlign:'center'}}>
                <p>Version {process.env.REACT_APP_APP_ENV}-{process.env.REACT_APP_APP_VERSION} </p>
                </div>
    
            </div>
        
        </div>

    </LoadingOverlay>


 </React.Fragment>
      
    )

}

