import React,{useState,useEffect} from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from 'react-redux';
import useCookie,  {getCookie} from 'react-use-cookie';
import SearchBox from "../../../components/searchBox/searchBox";
import DetailedCarrierCharts from "./DetailIndividualProductClass";
import PageTitle from '../../../components/pageTitle/pageTitle';


export default function  CarrierAnalysis(){
    const [client,setClient]=useState('hofer');
    const loginState = useSelector((state)=>state.login);

    const [startDate, setStartDate ] = useState('2021-01-01');
    const [endDate, setEndDate ] = useState('2021-06-01');
    const [startProcess,setStartProcess] = useState(true);
    const [selectedCountry , setSelectedCountry]= useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );
    const [prodClassFiltered, setProdClassFiltered] = useState('ALL');

    return (
<div>

    <Row>
    <PageTitle title='Carrier Ranking (Inbound)' />  
        <Col xs={2} md={2} lg={2}>
            <SearchBox client={client} initialStartDate={startDate}
                       initialEndDate={endDate}
                       initialScope='inbound'
                       show_scope={false}
                    
                       funcProductClass={(value)=>setProdClassFiltered(value)}
                       funcEndDate={(value)=>setEndDate(value)}
                       funcStartDate={(value)=>setStartDate(value)}
                       funcCountry={(value)=>setSelectedCountry(value)}
                       funcProcess={(value)=>{setStartProcess(value)}}

            />
 
        </Col>
        <Col xs={10} md={10} lg={10}>

            <Row>
             
                <DetailedCarrierCharts client={client} start_date={startDate}
                                       finish_process={(value)=>setStartProcess(value)}
                                       end_date={endDate} process={startProcess}  product_class={prodClassFiltered}
                />
            </Row>
        </Col>
    </Row>
</div>
    )
}
