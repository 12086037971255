import React,{useState,useEffect} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Alert, Card} from "react-bootstrap";
import {getCookie} from 'react-use-cookie';
import Modal from 'react-bootstrap/Modal';
import {useNavigate} from "react-router-dom";
import Button from 'react-bootstrap/Button';

import {
     convertPieNIVO,
    convertScatter,
    formatNumberPosDecimalRegion,
    getTopRecordsPie
} from "../../../services/utilsService";
import {CallAPI} from "../../../services/apiService";
import NivoPie from "../../../components/charts/NivoPie";
import NivoScatter from '../../../components/charts/NivoScatter';
import JRCTable from '../../../components/JRCTable/JRCTable';
import { useSelector } from 'react-redux';


export default function DetailedCarrierCharts({client,start_date,end_date, product_class,process,
                                                  finish_process,                                      
                                                  top_records=10}){


    const [emissionCarrierRoad, setEmissionCarrierRoad] = useState([]);
    const [dataScatter,setDataScatter]=useState([])
    const navigation = useNavigate();
    
    //TODO: Combine 
    const loginState = useSelector((state)=>state.login);
    const [selectedCountry , setSelectedCountry]=useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState.userListCountries[0]['country_code'] );
    const [calculationMethod,setCalculationMethod] = useState('basic');
    const [scope, setScope] = useState('inbound');
    const [carrierProfile,setCarrierProfile] = useState({});
    const [carrierTable, setCarrierTable] = useState([]);

    //Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    

    const colTableCarriers = [{'header':'Carrier Name',
    'field':'carrier_name','capitalize':true},
        {'header':'Total emission(tCO2e)', 'sorted':true, 
        'field':'total_emission', 'type':'integer'},
        {'header':'Number of orders',
        'sorted':true, 'field':'number_orders','type':'integer'} ];


   const showCarrierDetail=(data)=>{
    setCarrierProfile(data);
    handleShow();
     }

    const callCarrierDetailPage=()=>{
        navigation({pathname: "/visualization/road_carrier_profile",
        replace: true ,
        search:'?carrier_name=' + carrierProfile?.label});
      
    }


    const getEvolutionByCarrierRoad=()=>{
    
        CallAPI('GET',"/api/visual/v2/emission_carrier_road/",
        {
          client_id:client, start:getCookie('GHG-INITIAL-DATE') || start_date,
           end:getCookie('GHG-END-DATE') || end_date ,
          level:calculationMethod, scope:scope, country:getCookie('GHG-DEFAULT-COUNTRY') || selectedCountry,
          category:product_class,top:10,
          measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')    
      }
        
        ).then((resp)=>{
            if (resp.status === 200){
       
                //bar line
                let orderedData = resp.data;
                setCarrierTable(resp.data);
                orderedData.sort((a, b) => parseFloat(b.total_emission) - parseFloat(a.total_emission));
                let result = getTopRecordsPie(orderedData, top_records,'carrier_name','total_emission','Others');
                setEmissionCarrierRoad(convertPieNIVO(result,'label','label','value' ))
                setDataScatter(convertScatter(resp.data,'carrier_name','total_emission','number_orders'))
            } }) }

    const getAllData=()=>{
        if (process===true){
            getEvolutionByCarrierRoad();
            finish_process(false);
        }

    }
    useEffect(() => {
        getAllData();
    },[process])

    return (
        <div>
            {/* Modal carrier profile */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Carrier profile (Inbound)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <p><span style={{'fontSize':'17px','fontWeight':'bold'}}>Name:</span> {carrierProfile?.label}</p>
         <p><span style={{'fontSize':'17px','fontWeight':'bold'}}>
         Total emission (tCO2e):</span> {formatNumberPosDecimalRegion(carrierProfile?.value,0)}</p>
         <p><span style={{'fontSize':'17px','fontWeight':'bold'}}>
         Percentage of share:</span> {carrierProfile?.formattedValue}</p>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>{
            handleClose();
            callCarrierDetailPage();
          }}>
            Show more details
          </Button>
        </Modal.Footer>
      </Modal>

{carrierTable.length > 0 ? <>

  <Row>
    
    <Col xs={6} md={6}>
    
        
        <NivoPie show_legend={false}
                 palette={'pastel1'}
                 height={545}
                 function_click={showCarrierDetail}
                 label_value='Emission in tCO2e'
    
                 title={'TOP 10 : Distribution of emissions in tCO2e by carrier share'}
                 description={'This chart shows the absolute and relative share of emission per carrier. Please note, the TOP 10 carriers are represented exclusively, whereas all other carriers are grouped in „Others“. Supplier managed shipments are reflected as separate category in the pie chart.'}
                 data={emissionCarrierRoad}/>
                 <Alert style={{'marginTop':'0px'}} variant='success'>Click on one carrier in the pie segment to see more details.</Alert>
    
           
                 
       </Col>
    <Col xs={6} md={6}>
        <Card>
            <Card.Body>
            <JRCTable show_title={true}
                    columns={colTableCarriers}
                     export_table={true}
                     data={carrierTable}
                     item_by_page={10}
                     paginate={true}
                     show_search={true}
                     table_max_height='100%'     
                     table_title='TOP 10 carriers with highest emission'
                    />  
            </Card.Body>
    
        </Card>
    
    </Col>
    </Row>
    <Row>
        <Col xs={12} md={12}>
    
            <NivoScatter data={dataScatter} 
                                height={440}
                                margin_right_chart={350}
                                palette={'pastel1'}
                                node_size={34}
                                legend_direction={'column'}
                                axis_y_legend="Number of shipments"
                                axis_x_legend="Total emission in tCO2e"
                                title={['Relation of emission in tCO2e and number of shipments processed per carrier']}
                                description={'This chart shows the relation between the number of shipments and total inbound emission produced per destination division.'}
                                />
    
        </Col>
    </Row>

</> : <Row>
  <Col xs={12} md={12} lg={12}>
<Alert variant='danger'>There is not data available for the current filters, please select other values</Alert>
</Col>
</Row> }



  </div>

    )

}
