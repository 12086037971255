import React, {useState,useEffect}  from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {getCookie} from 'react-use-cookie';
import {CallAPI} from "../../../services/apiService";
import GhgBarDataLabelApex from "../../../components/charts/GhgBarDataLabelApex";
import {convertBarDataLabel, convertlineBarChartData,convertPieNIVO} from "../../../services/utilsService";
import SearchBox from "../../../components/searchBox/searchBox";
import PageTitle from '../../../components/pageTitle/pageTitle';
import JRCTable from '../../../components/JRCTable/JRCTable';
import { Alert } from 'react-bootstrap';
import GhgBarLineChartApex from '../../../components/charts/GhgBarLineChartApex';
import { useSelector } from 'react-redux';
import RangeSlider from 'react-bootstrap-range-slider';
import GhgToolTip from '../../../components/tooltip/GhgTooltip';
import NivoPie from '../../../components/charts/NivoPie';



export default function SummaryProductsOutbound(){
    const [client,setClient]=useState('hofer');
    const [startDate, setStartDate ] = useState('2021-01-01');
    const [endDate, setEndDate ] = useState('2021-06-01');
    const [prodClassFiltered, setProdClassFiltered] = useState('ALL');
    const [startProcess,setStartProcess] = useState(true);
    const [emissionProductClassNivo, setEmissionProductClassNivo] = useState([])
    const [weightProductClassNivo, setWeightProductClassNivo] = useState([])
    
    
    //TODO: Conectar con la caja de busqueda
    const loginState = useSelector((state)=>state.login);
    const [selectedCountry , setSelectedCountry]= useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );
    //useState(JSON.parse(loginState.userListCountries)[0]['country_code'] );
    const [calculationMethod,setCalculationMethod] = useState('basic');
    const [scope, setScope] = useState('outbound');
    const [topProducts, setTopProducts] = useState(10);
    
    //variable to show in charts

    const [emissionProducts , setEmissionProducts] = useState({data:[],categories:[]})
    const [emissionProductsTable , setEmissionProductsTable]=useState([]);
    const [emissionIntensityProducts , setEmissionIntensityProducts] = useState({series:[], x_axis:[]})
    const [emissionIntensityProductsTable , setEmissionIntensityProductsTable]=useState([]);
    
    //summary table configuration
    const colTableProducts = [{'header':'Product Class',
    'width':'70%',
    'field':'product_class'},
    {'header':'Product Code','field':'product_code'
},
    {'header':'Product Description','field':'product_description'},
    {'header':'Total Emission (tCO2e)','field':'total_emission', 'sorted':true, 'type':'integer'},
    {'header':'Total shipped weight (tonnes)','field':'total_weight_tons', 'sorted':true, 'type':'integer'},
    {'header':'Total Tonne  Kilometres (Thousands)', 'field':'tonns_km', 'sorted':true, 'type':'integer'},
    {'header':'Average Emission Intensity (gCO2e per tkm)', 'field':'emission_intensity','type':'decimal','sorted':true}
];

    const colTableIntensity = [{'header':'Product Class',
    'field':'product_class'},
    {'header':'Total emission (tCO2e)','field':'total_emission', 'type':'integer','sorted':true},
    {'header':'Total Tonne Kilometres (Thousands)','field':'total_tonns_km_thousand', 'type':'integer','sorted':true},
    {'header':'Average emission Intensity (gCO2e per tkm) ','field':'emission_intensity', 'sorted':true, 'type':'decimal'},
    ];



    const getEmissionByProducts=()=>{
        CallAPI("GET","/api/visual/v2/top_product_by_emission_outbound/",
        {
            client_id:client, start:getCookie('GHG-INITIAL-DATE') || startDate,
             end: getCookie('GHG-END-DATE') ||  endDate ,
            level:calculationMethod, scope:scope, country:selectedCountry,
            category:prodClassFiltered,top:topProducts,
            measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')
        
        }
        
        ).then((resp)=>{
            console.log('Emission product table: ', resp.data);
             if (resp.status === 200 && resp.data !== null){
                setEmissionProductsTable(resp.data)
                setEmissionProducts(convertBarDataLabel(resp.data,'total_emission','product_description'))
            }else{
                setEmissionProductsTable(null);
            }
        })
    }


    const getIntensityByProductClass=()=>{

        CallAPI("GET","/api/visual/v2/product_class_intensity_outbound/",
        {
            client_id:client, start:getCookie('GHG-INITIAL-DATE') || startDate, 
            end: getCookie('GHG-END-DATE') ||  endDate ,
            level:calculationMethod, scope:scope, country:selectedCountry,
            category:prodClassFiltered,
            measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')
        
        }
        
        ).then((resp)=>{
            if (resp.status === 200 && resp.data !== null){
                setEmissionIntensityProductsTable(resp.data)
              
                let resultBar =  convertlineBarChartData(resp.data, 'product_class',
                 [{name:'Total emission (tCO2e)',type:'column', column_value: 'total_emission',
                columns_description : [{'label':'Emission intensity (g CO2e per tkm)',
            'value':'emission_intensity'  },
            {'label':'Total emission (tCO2e)','value':'total_emission'},
        {'label': 'Tonne-kms (Millions)','value':'total_tonns_km_millions' }
        ]
                }
          ]);
                setEmissionIntensityProducts(resultBar);
                //create pie charts
                setEmissionProductClassNivo(convertPieNIVO(resp.data,'product_class','product_class','total_emission' ))
                setWeightProductClassNivo(convertPieNIVO(resp.data,'product_class','product_class','total_weight_tons' ))
             }
        })
    }

    const getData=()=>{    
       // if (startProcess===true){
            getEmissionByProducts()
            getIntensityByProductClass();
            setStartProcess(false);
        //}
    }
    useEffect(() => {
        getData();
    }, [selectedCountry,startDate,endDate,startProcess]);
  
    return (
             <div>
                 <PageTitle title='Product Ranking (Outbound)' />               
      
                <Row>
                <Col xs={2} md={2} lg={2}>
            <SearchBox client={client} initialStartDate={startDate}
                       initialEndDate={endDate}
                       initialScope={'outbound'}
                       funcProductClass={(value)=>setProdClassFiltered(value)}
                       funcEndDate={(value)=>setEndDate(value)}
                       funcStartDate={(value)=>setStartDate(value)}
                       funcCountry={(value)=>setSelectedCountry(value)}
                       funcProcess={(value)=>{setStartProcess(value)}}

            />
        </Col>
        <Col xs={10} md={10} lg={10}>
           
        {emissionProductsTable.length >0 ? <>
              {/* TOP Pie chart */}
              <Row>
                <Col xs={12} md={12} lg={12}>
 
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={6} lg={6}>
                    <Card>
                        <Card.Body>
                        <NivoPie show_legend={true}
    palette='pastel1'
              show_arc_labels={true}
              label_value='Emission tCO2e'
             description={'This chart shows the distribution of emissions for Outbound (road) transport by product class.'}
             title={'Distribution of emission by product class'} 
             data={emissionProductClassNivo}/>

                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={6} md={6} lg={6}>
                    <Card>
                        <Card.Body>
                        <NivoPie show_legend={true}
    palette='pastel1'
              show_arc_labels={true}
              label_value='Total weight in tonnes'
             description={'This chart shows the distribution of weight for Outbound (road) transport by product class.'}
             title={'Distribution of weight (tonnes) by product class'} 
             data={weightProductClassNivo}/>

                        </Card.Body>
                    </Card>
                </Col>

            </Row>

            {/* Add the charts */}
            <Row>
                <Col xs={6} md={6} lg={6}>
                    <Card style={{'height':'100%'}}>
                        <Card.Body>
                        <GhgBarDataLabelApex
                                title={'TOP ' + topProducts + ' : Products with the highest emission in tCO2e'}
                                subtitle={'This chart shows the distribution of emissions for Outbound (road) transport by product class.'}
                                data={emissionProducts.data}  categories={emissionProducts.categories}
                                use_monochrome={true} base_color={'#a6cee3'}
                                    mode={'light'}
                                click_function={(v)=>{
                                    //getTopDestinationBySupplier(v);
                                }}
                            />
                                    <div>
                  <span className={'text-danger'}>Move the slider to show more products (max 25 top products)</span>
                  <RangeSlider
                  min={1}
                  max={25}
                  step={1}
                  tooltip={'on'}
                  value={topProducts}
                  onChange={e => setTopProducts(e.target.value)}
                  onAfterChange={e => {getEmissionByProducts(); }}
                />
            </div>
                          </Card.Body>
                     
                    </Card>
            
                </Col>
                <Col xs={6} md={6} lg={6}>
                <Card style={{'height':'100%'}}>
                        <Card.Body>
                        <div style={{'textAlign':'right','marginBottom':'10px'}}>
                            <GhgToolTip title={'Special values explanation'}
                            content_explanation={'Tonne-kilometre: A tonne-kilometre (tkm) is a measurement unit corresponding to the transport of one ton over a distance of one kilometre.Emission intensity factor: The emission intensity factor shows - independent of distance and weight - the environmental impact of the subject (here: Product Class).'}
                            >
                         
                          

                            </GhgToolTip>
                            </div>

                <JRCTable
                                    columns={colTableIntensity}
                                    export_table={true}
                                    data={emissionIntensityProductsTable}
                                    item_by_page={10}
                                    paginate={true}
                                    title={'Detail of emission intensity by product class'}
                                    show_title={true}
                                    show_search={true}
                                    table_max_height='100%'     
                                    table_title={'Detail of emission intensity by product class' }
                                    /> 
                                 </Card.Body>
                                 </Card>
                </Col>
        
            </Row>
     
            {/* Emission intensity by product class */ }
            <Row>
                <Col xs={6} md={6} lg={6}>

                <Card style={{'height':'100%'}}>
                        <Card.Body>
                            {/* Show the table */}
                            <div style={{'textAlign':'right','marginBottom':'10px'}}>
                            <GhgToolTip title={'Special values explanation'}
                            content_explanation={'Tonne-kilometre: A tonne-kilometre (tkm) is a measurement unit corresponding to the transport of one ton over a distance of one kilometre.Emission intensity factor: The emission intensity factor shows - independent of distance and weight - the environmental impact of the subject (here: Product Class).'}
                            >
                         
                          

                            </GhgToolTip>
                            </div>
                            <JRCTable
                                    columns={colTableProducts}
                                    export_table={true}
                                    data={emissionProductsTable}
                                    item_by_page={10}
                                    paginate={true}
                                    title={'Detail of TOP ' + topProducts + ' products with the highest emission in tCO2e'}
                                    show_title={true}
                                    show_search={true}
                                    table_max_height='100%'     
                                    table_title={'TOP ' + topProducts + ' : Products with highest emission in tCO2e for the product class ' + prodClassFiltered }
                                    />   
        
              
                        </Card.Body>
                    </Card>
                </Col>
               
            </Row>

        </>  : <>
        <Alert variant="danger" style={{ width: "100%" ,'marginLeft':'5px', 'marginRight':'5px','marginTop':'10px' }}>
                            There is not data available for the current filters, please check the parameters and try again
                          </Alert>
        </>}

      
        </Col>

                </Row>
             </div>
       )
}