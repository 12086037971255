import React from "react";
import { BrowserRouter, Routes, Route,redirect } from "react-router-dom";
import App from './App';
import Page404 from './pages/page404/page404';
import Login from './pages/login/login';
import Landing from './pages/landing/landing';
import SubRouter from './modules/sample/subrouter';
import { Provider } from "react-redux";
import { store } from './store';
import LoadingOverlay from 'react-loading-overlay-ts';
import VisualizationRouter from "./pages/visualization/visualizationRouter";
import {ProtectedRouter} from "./services/protectedRouter";
import InboundDashboard from "./pages/visualization/inboundDashboard";
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore, persistReducer } from 'redux-persist'
import LoadingSpinner from "./components/loadingSpinner/loadingSpinner";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import UserManagement from './pages/users/management/userManagement';
import IntegralDashboard from "./pages/visualization/integralDashboard";





//include all the main core and the linking
// to router of the submodules

//const client = new ApolloClient({
 //   uri: "https://api.aldisphere.govisible.org/graphql/",
  //  cache: new InMemoryCache(),
   // headers :{
    //    authorization:'Bearer ' + localStorage.getItem("graph_token") },
   // connectToDevTools:true
  //});

export default function CoreRouter(){


 

    let persistor = persistStore(store)
    return (
    <Provider store={store}>
           <PersistGate loading={<LoadingSpinner/> } persistor={persistor}>

    <BrowserRouter>
    {/*    <ApolloProvider client={client}> */}

    <Routes>
      <Route element={<App/>}>

          <Route exact path='/' element={
              <ProtectedRouter>
              <IntegralDashboard/>
              </ProtectedRouter>
                  }/>               
      
       
     
          {/* Add  the routes for each module */}
          <Route path='visualization/*' element={
              <ProtectedRouter>
              <VisualizationRouter/>
                  </ProtectedRouter>
           }/>
          {/* Generic path for not found */}
          <Route path='*' element={<Page404/>}/>
      </Route>
     <Route path='/login' element={<Login/>}/>
   </Routes>
   {/*   </ApolloProvider> */}

   </BrowserRouter>
   </PersistGate>

   </Provider>

    )
}
