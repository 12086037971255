import React from 'react'
import { ResponsiveCalendar, ResponsiveTimeRange } from '@nivo/calendar'
import Card from 'react-bootstrap/Card';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GhgToolTip from "../tooltip/GhgTooltip";
import {getCookie} from 'react-use-cookie';
import {formatNumberPosDecimal} from "../../services/utilsService";

export default function NivoCalendar({data,
                                     title='',
                                     description='',
                                     y_possition=-60
                                     }){
    const region= getCookie('GHG-MEASURE-SEPARATOR') || 'de-DE';
    const numberFormat =(value)=>{
        if (value){
            return formatNumberPosDecimal(Number(value),0).toLocaleString(region, {
                minimumFractionDigits: 0,
            });
        }else{
            return value;
        }



    }
  
    const getStartDay =()=>{
     if (data.length>0){
        return data[0]['day']
     }else {
        return new Date();
     }
    }
    const getEndDay = () =>{
        if (data.length > 0){            
            return data[data.length-1]['day']
        }else{
            return new Date();
        }

    }
    return (
        <Card style={{height:'330px'}}>
            <Card.Body>
                <Row>
                    <Col md={11} xl={11}>
                        <div style={{'textAlign':'left', 'fontWeight':'bold'}}>
                            <h5>{title}</h5>
                            </div>
                    </Col>
                    <Col  xl={1} md={1} style={{'textAlign':'center'}}>
                        <GhgToolTip title={title}
                                    content_explanation={description}
                        />
                    </Col>
                </Row>
                <Row style={{height:'300px'}}>
                    <Col md={12} xl={12}>
                        <ResponsiveCalendar
                            data={data}
                            from={ getStartDay()}
                            to={getEndDay()}
                            dayRadius={4}
                            monthSpacing={10}
                            yearSpacing={40}
                            monthBorderColor="#ffffff"
                          
                            emptyColor="#eeeeee"
                            //colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
                            colors={[ '#a5d6b7', '#fff176', '#f3903f', '#e93e3a' ]}

                            margin={{ top: 40, right: 40, bottom: 100, left: 40 }}
                           
                            tooltip={function(t){return (
                                <Card>
                                    <Card.Body>
                                        <div style={{width:'40px', height:'40px',background:t.color}}></div>
                                        Day: {t.day} <br></br>
                                        Emission (tCO<sub>2</sub>e) : {numberFormat(t.value)}
                                    </Card.Body>
                                </Card>

                            )}}

                            weekdayTicks={[1,2,3,4,5,6,0]}
                            dayBorderColor="#ffffff"
                           legends={[
                                {
                                    anchor: 'bottom-right',
                                    direction: 'row',
                                    justify: false,
                                    itemCount: 4,                                 
                                    itemWidth: 42,
                                    itemHeight: 36,
                                    itemsSpacing: 14,
                                    itemDirection: 'right-to-left',
                                    translateX: -60,
                                    translateY: y_possition,
                                    symbolSize: 20
                                }
                            ]}
                        />
                    </Col>

                </Row>


            </Card.Body>
        </Card>
                )

}
