import React from 'react'
import { ResponsiveRadialBar } from '@nivo/radial-bar'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Card} from "react-bootstrap";
import GhgToolTip from "../tooltip/GhgTooltip";
import {getCookie} from 'react-use-cookie';
import {formatNumberPosDecimal} from "../../services/utilsService";

export default function NivoRadial({title,description,height='350px',
                                   palette='paired',
    data
                                   }){

    const region = getCookie('GHG-MEASURE-SEPARATOR') || 'de-DE';

   return (
        <Card>
            <Card.Body style={{'height':height}}>
                <Row>
                    <Col md={11} xl={11}>
                        <div style={{'textAlign':'left', 'fontWeight':'bold'}}>
                            <h5>{title}</h5>
                            </div>
                    </Col>
                    <Col md={1} xl={1} style={{'textAlign':'right'}}>
                        <GhgToolTip title={title}
                                    content_explanation={description}
                        />
                    </Col>
                </Row>
                <ResponsiveRadialBar
                    data={data}
                    colors={{'scheme':palette}}
                    valueFormat={(value)=>{
                          return formatNumberPosDecimal(Number(value),0).toLocaleString(region, {
                            minimumFractionDigits: 0,
                        });
                    }}
                    padding={0.4}
                    cornerRadius={2}
                    enableLabels={false}
                     margin={{ top: 40, right: 120, bottom: 40, left: 40 }}
                    radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
                    circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
                    legends={[
                        {
                            anchor: 'right',
                            direction: 'column',
                            justify: false,
                            translateX: 80,
                            translateY: 0,
                            itemsSpacing: 6,
                            itemDirection: 'right-to-left',
                            itemWidth: 100,
                            itemHeight: 18,
                            itemTextColor: '#999',
                            symbolSize: 18,
                            symbolShape: 'square',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000'
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </Card.Body>
        </Card>


    )

}
