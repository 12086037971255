//REDUX TOOLKIT PROJECT STORE
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import menuReducer from './components/menu/menuSlice'
import loginReducer from './pages/login/loginSlice'
import coreReducer from './coreSlice'

//using persistence storage
import storage from 'redux-persist/lib/storage';

import { 
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'

  const persistConfig = {
    key: 'root',
    timeout: 2000, 
    version: 1,
    storage
  ,whitelist:['login']
  }

//add here the new reducers for each module or individual
// this is the only part to modify
const reducers = combineReducers({
    core: coreReducer,
    menu:menuReducer,
    login:loginReducer,
    
})


//TODO: Expiration of storage specially for user session 

const persistedReducer = persistReducer(persistConfig, reducers)
 

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  })

  export default store
