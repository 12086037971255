//Redux Slice for Menu Component
import { createSlice } from "@reduxjs/toolkit";

const initialState={
    menu:[{}]
   
}

export const menuSlice = createSlice({
  name:'menu',
  initialState,
  reducers:{
    //list of action that could be done 
    menu_set: (state,actions)=>{
        state.menu = actions.payload.menu;       
    },
    menu_close: (state)=>{
        state = initialState
    }
  }
})

export const {menu_set} = menuSlice.actions

export default menuSlice.reducer