//REST API Consumer service version 1.0.0
//first version: Orlin Corea 28-05-2022
//The token is passed as header in the request
import store from '../store';
import {core_start_processing, core_stop_processing} from '../coreSlice'
const axios = require('axios').default;




function getToken(user,pass,token_url){
    axios.post(process.env.API_TOKEN_URL, {
        user: user,
        password: pass
      })
      .then(function (response) {
          //TODO:save the token as cookie or localstorage with expiration
        })
      .catch(function (error) {
          //TODO: Exception collection integration
        console.log(error);
      });
}

async function CallAPI(method , url , payload={}){



  store.dispatch(core_start_processing());
   //verify if required token or key
   let apiToken = "";   
   if (process.env.REACT_APP_API_REQUIRED_TOKEN===true){
       //check if token already exist
       getToken();
   }else{
       apiToken = process.env.REACT_APP_API_KEY;
   }

   let prefixURL = process.env.REACT_APP_API_SERVER;
   let apiResponse = {};
   //Methos allowed POST, PUT, GET , DELETE
    let t0= performance.now(); //start time
    switch (method){
      case 'GET':
        apiResponse =  await axios.get(prefixURL + url, { headers: 
        {"x-api-key": apiToken},
      params:payload
      }).then((response)=>{
               return response
        })
        .catch(function (error) {
            // handle error
            console.log(error);
            }).finally(()=>{
              store.dispatch(core_stop_processing());
                //stop the loading message
            })   
        break       
       
      case 'POST':
        apiResponse = await axios.post(prefixURL + url, payload, { headers: {
            "x-api-key": apiToken
          }})
        .then(function (response) {
        return response;
        })
        .catch(function (error) {
        // handle error
        console.log(error);
        }).finally(()=>{
            //stop the loading message
            store.dispatch(core_stop_processing());
        }) 
        break
    case 'PUT':
        apiResponse =  await axios.put(prefixURL + url, payload, { headers: {
            "x-api-key": apiToken
          }})
        .then(function (response) {   
        return response;
        })
        .catch(function (error) {
        // handle error
        console.log(error);
        })
        .finally(function () {
          store.dispatch(core_stop_processing());
        });
        break
    case 'DELETE':
        apiResponse = await axios.delete(prefixURL + url,{ headers: {
            "x-api-key": apiToken
          }})
        .then(function (response) { 
        return response;
        })
        .catch(function (error) {
        // handle error
        console.log(error);
        })
        .then(function () {
          store.dispatch(core_stop_processing());
        });
        break  
      default :
        return false     

    }
    let t1= performance.now();
    return apiResponse;  
}

export { CallAPI, getToken }
