import React,{useState, useEffect} from "react";
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useQuery, useMutation, gql } from "@apollo/client";
import dialogService from "../../services/dialogService";
import {CallAPI} from "../../services/apiService";
import msgDialog from "../../services/dialogService";
import icon_check from "../../assets/images/check_icon.png";
import icon_error from "../../assets/images/error_icon.png";




export default function ForgotPassword({showComponent}){
    
    const [userId,setUserId] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [showInsertCode, setShowInsertCode] = useState(false);
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [recoveryCode, setRecoveryCode] = useState('')
    const [passwordChanged, setPasswordChanged ] = useState(false);
    const [displayEnterCode, setDisplayEnterCode] = useState(false)


    const password_passed=(value)=>{
        if (RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*+-])(?=.{8,})").test(value))
        {
            return true;
        }else{
            return false;
        }

    }

        const email_passed=(value)=> 
        {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value))
        {
            return (true)
        }
            return (false)
        }



    const handleSubmit = event => {
        event.preventDefault(); //
    }


   const set_new_password = ()=>{
    if (password_passed(newPassword)===false){
        msgDialog.msgError("Password does not comply with the minimun security, please define a new one ");
        return false
      }

     if (recoveryCode.length <1){
        dialogService.msgError("You must provide a valid recovery code");
        return false;
    }

    if (newPassword !== confirmPassword){
        dialogService.msgError("The new password and the confirmation do not match");
        return false;
    }

    CallAPI('POST',"/auth/auth_ghg_core/confirm_recovery_password" ,{
        'email':userId,
        'new_password': newPassword,
        'code': recoveryCode

    } ).then((resp)=>{
        if (resp.status ===200){
            if (resp.data['result']===1 || resp.data['result']==='1'){
                msgDialog.msgSuccess("New password defined successfully, please proceed to sign in");
                showComponent(false);

            }else{
                msgDialog.msgError("The password recovery was not possible: "+resp.data['message']+"");
                return false;
            }

        }
    })
    

   }

    const callRecover=(profile_data,user_data)=>{   
   
        if (email_passed(userId)===false){
            dialogService.msgError("You must provide a valid email");
            return false;
        }
        CallAPI('POST',"/auth/auth_ghg_core/recover_password" ,{
        'email':userId } ).then((resp)=>{
        if (resp.data['code']===200){
            //check if a previous code has been sent
            if (resp.data['previous_code']===0){
                dialogService.msgSuccess("A verification code for password reset has been sent to your email");
                setDisplayEnterCode(true);            
            }else{
                dialogService.msgError("A previous code has been sent to your email, please check your inbox");
                setDisplayEnterCode(true);   
            }
   

        }else{
            dialogService.msgError(resp.data['message']);
        }
     

    }   
    )
}

const back_to_login=()=>{
    showComponent(false);
}

    const show_enter_code=()=>{
        return (
            <Form onSubmit={handleSubmit}>
                  <h2>Set a new password</h2>
            <Form.Group>
                <Form.Label>User</Form.Label>
                <Form.Control readOnly value={userId} type='text'></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>Verification code</Form.Label>
                <Form.Control onChange={e=>setRecoveryCode(e.target.value)} type='text'></Form.Control>
            <Form.Text className='text-mude'>* Please provide the verification code received by email </Form.Text>
            </Form.Group>
            <Form.Group>
                <Form.Label>New password</Form.Label>
                <Row>
                    <Col xs={1} md={1} lg={1}>
                    {password_passed(newPassword)? <img src={icon_check} alt="Icon check" style={{height:'30px'}} />:
                <img src={icon_error} alt="Icon error" style={{height:'30px'}} />
                }
                    </Col>
                    <Col xs={11} md={11} lg={11}>
                    <Form.Control onChange={e=>setNewPassword(e.target.value)} type='password'></Form.Control>
                    </Col>

                </Row>
             
            
               

            </Form.Group>
         <Form.Group style={{marginTop:'6px'}}>
                <Alert variant='info'>The new password must comply with the security requirements: <li>Minimun 8 characters lenght</li><li>Including an upper and lower case</li>
                <li>Including a lower case</li>
                <li>Include a number and a special symbol (!@#$%^&*+-) </li>
             </Alert>
                </Form.Group>
            
         
            <Form.Group>
                <Form.Label>Confirm new password</Form.Label>
                <Form.Control onChange={e=>setConfirmPassword(e.target.value)} type='password'></Form.Control>
            <Form.Text className='text-mude'>*** Re-enter the new password</Form.Text>
            </Form.Group>
            {(newPassword !== confirmPassword) ?<Form.Group>
                <Alert variant='danger'>New password and the confirmation are differents</Alert>
                </Form.Group>
            
            : <></>}
                <Row>            
                    <Col xs={6} md={6} lg={6}>
                        {!passwordChanged ?
                        <>
                         <Button variant="primary" onClick={set_new_password}>
                       Set new password
                   </Button>
                   <Button style={{marginLeft:'20px'}} variant="danger" onClick={back_to_login}>
                       Back to login
                   </Button>
             
             
        
                        </>
                   
                   : <></> }
                    </Col>
                  
                </Row>
           
        </Form>
        )

    }

    
    return (
        <>

        <Row>
            <Col xs={12} md={12} lg={12}>
                {displayEnterCode? 
                show_enter_code():

                <Form>
                <h2>Forgot your password?</h2>
                <span className="text-mude">
                If you forgot your password, we can send you an email to your registered account with instructions to reset your password.
                     </span>
            <Form.Group className="mb-3" controlId="formEmail">
                <Form.Control required type="email" 
                onChange={e => setUserId(e.target.value)}  placeholder="Your email address" >

                </Form.Control>
               
            </Form.Group>
            <Button className="signing" onClick={callRecover} variant="outline-info">Recover password</Button>
            <div style={{'textAlign':'center','marginTop':'20px'}}>
            <a  onClick={()=>{showComponent(false)}}  href="#">Go back to sign in. </a> 
        
            </div>
              
            </Form>
            
            }
           
                
                
          </Col>
           
        </Row>
        </>
    )
}
