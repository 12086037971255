import React, {useState,useEffect}  from 'react'
import {CallAPI} from "../../../services/apiService";
import {getCookie} from 'react-use-cookie';
import {
    convertLeaftMap,
    getLabelMeasurement,
    getUnitMeasurement,
    getValueMeasurement,
    formatNumberPosDecimalRegion
} from "../../../services/utilsService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {Alert} from "react-bootstrap";
import KPICard from "../../../components/statistic/KPICard";
import NivoCalendar from "../../../components/charts/NivoCalendar";
import GhgMap from "../../../components/geomap/GhgMap";
import CarrierSearchBox from "../../../components/carrierSearchBox/CarrierSearchBox";
import {useSelector} from "react-redux";
import PageTitle from '../../../components/pageTitle/pageTitle';
import JRCTable from '../../../components/JRCTable/JRCTable';
import {useSearchParams} from "react-router-dom";
import msgDialog from '../../../services/dialogService';



export default function CarrierProfile(){
    const [searchParams, setSearchParams] = useSearchParams();
    const [startDate, setStartDate]=useState();
    const [endDate, setEndDate]=useState();
    //TODO: Link it with the Login consider using a Cookie for the Login with a expiration of 3 days
    const [client,setClient] = useState('hofer');

    const [carrierSelected, setCarrierSelected]= useState([searchParams.get('carrier_name')]);
    const [productClassSelected, setProductClassSelected]=useState('ALL');
    const [carrierRoutes, setCarrierRoutes] = useState([]);
    const [carrierStat, setCarrierStat] = useState({})
    const [emissionByDate, setEmissionByDate] = useState([])
    const [rdcSelected, setRdcSelected]=useState()
    const [carrierMap, setCarrierMap]=useState({data:[{detail:[]}],supplier:''});
    const [topRoutes, setTopRoutes] = useState(10);
    const [suppliersCarrier, setSupplierCarrier] = useState([]);
    const loginState = useSelector((state)=>state.login);

    




    //TODO: Combinar con valores de la tabla de busqueda
    const [selectedCountry , setSelectedCountry]=useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState.userListCountries[0]['country_code'] );
    const [calculationMethod,setCalculationMethod] = useState('basic');
    const [scope, setScope] = useState('inbound');


  


    //table parameters for supplier location
    const colTableRoutes = [{'header':'State','field':'supplier_state'},
        {'header':'City','field':'supplier_city', 'capitalize':true},
        {'header':'RDC State','field':'destination_state'},
        {'header':'RDC City','field':'destination_rdc', 'capitalize':true},
        {'header':'Average travelled distance (' + getUnitMeasurement('distance') + ')','field':'avg_distance_traveled', 'sorted':true, 'type':'integer'},
        {'header':'Total weight in tonnes','field':'sum_weight_tonnes' , 'sorted':true, 'type':'integer'},
        {'header':'Total emission (tCO2e)','field':'total_emission' , 'sorted':true, 'type':'decimal'},
    ];
   //table suppliers attended by carrier
    const colTableSupplier = [{'header':'Supplier name','field':'supplier_name', 'capitalize':true},
        {'header':'Total emission tCO2e','field':'total_emission', 'sorted':true, 'type':'decimal'},
        {'header':'Average distance (' + getUnitMeasurement('distance') + ')' , 'field':'avg_distance_km', 'type':'integer', 'sorted':true },
        {'header':'Average weight tonnes ' , 'field':'avg_weight_tonnes' , 'type':'decimal' , 'sorted':true }];




    const getCarrierStat=()=>{
        CallAPI('GET',"/api/visual/v2/carrier_stat/",
        {
            client_id:client, start:getCookie('GHG-INITIAL-DATE'), end:getCookie('GHG-END-DATE') ,
            carrier:  carrierSelected[0] || searchParams.get('carrier_name') ,
            level:calculationMethod, scope:scope, country:getCookie('GHG-DEFAULT-COUNTRY'),
            category:productClassSelected,top:topRoutes,
            measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')    
        }

        ).then((resp)=>{
              if (resp.status === 200){
                setCarrierStat(resp.data);
            }})}


    const getSupplierAttended=()=>{
        CallAPI('GET',"/api/visual/v2/carrier_emission_by_supplier/" ,
        {
            client_id:client, start:getCookie('GHG-INITIAL-DATE'), end:getCookie('GHG-END-DATE') ,
            carrier:  carrierSelected[0] || searchParams.get('carrier_name'),
            level:calculationMethod, scope:scope, country:getCookie('GHG-DEFAULT-COUNTRY'),
            category:productClassSelected,top:topRoutes,
            measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')    
        }

        ).then((resp)=>{
            if (resp.status === 200){
                setSupplierCarrier(resp.data);
            }})}

    const getCarrierRoutes=(carrier,top)=>{
        setTopRoutes(top)
        
        let local_parameters={
            client_id:client, start:getCookie('GHG-INITIAL-DATE'), end:getCookie('GHG-END-DATE') ,
            carrier:  carrier[0] || searchParams.get('carrier_name'),
            level:calculationMethod, scope:scope, country:getCookie('GHG-DEFAULT-COUNTRY'),
            category:productClassSelected,top:top,
            measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')  }

        CallAPI('GET',"/api/visual/v2/carrier_routes/" ,local_parameters ).then((resp)=>{
               if (resp.status === 200){
               setCarrierRoutes(resp.data);
                  let series=[
                    {'name':'supplier','latitude_field': 'supplier_latitude',
                        'longitude_field': 'supplier_longitude', 'icon':'hub' ,
                        'state':'supplier_state', 'city': 'supplier_city',
                        'label_type':'Supplier','origin_latitude':'supplier_latitude',
                        'origin_longitude': 'supplier_longitude',
                        'line_color':'#ef5350',
                        'line_color_dark':'#ef5350',
                        'emission_field':'total_emission'
                    },
                    {'name':'rdc','latitude_field': 'rdc_latitude',
                        'longitude_field': 'rdc_longitude', 'icon':'rdc',
                        'state':'destination_state', 'city': 'destination_rdc',
                        'label_type':'RDC',
                        'origin_latitude':'supplier_latitude',
                        'origin_longitude': 'supplier_longitude',
                        'line_color':'#004d00',
                        'line_color_dark':'#004d00',
                        'emission_field':'total_emission'
                    } ];

                let data = convertLeaftMap(resp.data,series);
                setCarrierMap({data: data , supplier:carrierSelected})
            }})}

    const getEmissionByDate=()=>{
        CallAPI('GET',"/api/visual/v2/carrier_emission_by_date/",
        {
            client_id:client, start:getCookie('GHG-INITIAL-DATE'), end:getCookie('GHG-END-DATE') ,
            carrier:  carrierSelected[0] || searchParams.get('carrier_name') ,
            level:calculationMethod, scope:scope, country:getCookie('GHG-DEFAULT-COUNTRY'),
            category:productClassSelected,top:topRoutes,
            measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')    
        }
        ).then((resp)=>{
            if (resp.status === 200){
                setEmissionByDate(resp.data);
            }
        })}


    const getData=(current_carrier)=>{

        if (current_carrier || carrierSelected[0]){
                //get profile data
                getCarrierStat();
                getEmissionByDate();
            getCarrierRoutes(carrierSelected || current_carrier ,topRoutes);
                getSupplierAttended();
        }else{
            msgDialog.msgError("Please select a carrier and then press the button 'Apply filter'")
            return false;

        }
   
    }


    useEffect(()=>{      
        setStartDate(getCookie('GHG-INITIAL-DATE') );
        setEndDate(getCookie('GHG-END-DATE') );   
        if (searchParams.get('carrier_name')){
           getData(searchParams.get('carrier_name'));
        }
    },[])


    return (
        <Row>
                <PageTitle title='Carrier Profile (Inbound)' />  
            <Col md={2} xl={2} lg={2}>
                <CarrierSearchBox func_carrier={setCarrierSelected}
                                   default_value = {searchParams.get('carrier_name') || ''}
                                  func_start_date={setStartDate}
                                  func_end_date={setEndDate}
                                  func_country={setSelectedCountry}
                                  fixed_scope='inbound'
                                  show_scope={false}                                  
                                  initialStartDate={startDate}
                                  initialEndDate={endDate}
                                  
                                  func_product_class={setProductClassSelected}

                func_get_data={getData}
                />

            </Col>
            <Col xs={10} md={10} xl={10}>
                {!carrierSelected[0] ? <Alert variant='danger'>
                     No carrier selected ,  please select the desired filters on the left and click on "Apply filter"</Alert>:<></>}
               {carrierStat.number_shipment_orders === 0 ?
            <Alert variant='danger'>There is not data available for the current applied filters</Alert> : <></>   
            }
                <Row>
                 
                    <Col md={7} xl={7} lg={7}>
                        <Card>
                            <Card.Body>
                      
                                <Row>
                                    <Col xs={6}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(carrierStat.total_emission_last_leg  ,2)}
                                        title='Total emission in tCO2e '
                                        content_explanation='This KPI represents total emission expressed in tCO2e produced by the selected carrier for shipments of the selected product class within the selected time period. '
                                    /></Col>
                                    <Col xs={6}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(carrierStat.number_shipment_orders,2)}
                                        title='Number of shipments attended '
                                        content_explanation='Represents the number of shipments that have been attended for this carrier in the time frame selected '
                                    /></Col>

                                </Row>
                                <Row style={{paddingTop:'10px'}}>
                                    <Col xs={6}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={ formatNumberPosDecimalRegion(getValueMeasurement('distance', carrierStat.avg_distance_traveled),2)}
                                        title={'Average distance from hub to RDC (' + getUnitMeasurement('distance') + ')' }
                                        content_explanation={'Represents the average distance from the Supplier location to the Regional Distribution Center (RDC) expressed in ' + getLabelMeasurement('distance') }
                                    /></Col>
                                    <Col xs={6}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(carrierStat.avg_shipment_weight_tonns,2)}
                                        title='Average shipment weight in tonnes'
                                        content_explanation='Represents the average shipment weight in tonnes, transported from the supplier location to the Regional Distribution Centers (RDC) '
                                    /></Col>
                                </Row>
                                <Row style={{paddingTop:'10px'}}>
                                    <Col xs={6}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={ formatNumberPosDecimalRegion(getValueMeasurement('distance', carrierStat.avg_transportation_activity_thousand),2)}
                                        title={'Average transport activity (tkm) in thousand)' }
                                        content_explanation={'Average transport activity indicador presented in thousand of tkm (km * shipped volume in tonnes)' }
                                    /></Col>
                                    <Col xs={6}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(carrierStat.emission_intensity,2)}
                                        title='Average emission intensity factor (gCO2e / tkm)'
                                        content_explanation='This KPI shows the emission intensity factor resulting in the calculation for road transportation, considering the total emission expressed in gCO2e divided by the tonnes x kilometres.This value represents the amount of emission expected for each tonne-kilometre traveled.'
                                    /></Col>
                                </Row>
                                <Row>
                                    <Col md={12} xl={12} lg={12}>
                                        { emissionByDate.length > 0 ?
                                    
                                            <NivoCalendar data={emissionByDate}
                                              y_possition={-100}
                                                          title={'Emission concentration in tCO2e per day for product class ' + productClassSelected + ' for the carrier ' + carrierSelected }
                                                          description={'This chart shows the heat color representation of emission produced each day for the selected product class. '}
                                            />
                                            :<Card>
                                                <Card.Body>
                                                    <h3 style={{width:'100%', textAlign:'center'}}>No data to show</h3>
                                                </Card.Body>
                                            </Card>
                                        }

                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={5} xl={5} lg={5}>
                        <Card style={{'height':'100%'}}>
                            <Card.Body>
                              
                                <Alert variant='info'>
                                    <h4>
                                    {!carrierSelected[0] ? <span>No Carrier selected</span>:carrierSelected}
           </h4>
                                    <h6>Product class: {productClassSelected}</h6>
                                </Alert>
                                           <JRCTable
                                            columns={colTableSupplier}
                                            export_table={true}
                                            data={suppliersCarrier}
                                            item_by_page={10}
                                            paginate={true}
                                            show_title={true}
                                            show_search={true}
                                            table_max_height='470px'     
                                            table_title={'TOP 10 suppliers related with the carrier: ' + carrierSelected }
                                            />   
                                <div style={{paddingTop:'20px'}}>
                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row style={{paddingTop:'5px'}}>       

                    <Col md={7} xl={7} lg={7}>
                        { 
                     
                        carrierMap.data[0].detail.length > 0 ?   
                        <>
                               <GhgMap  data={carrierMap}  rdc_selected={rdcSelected}
                                                                height={'820px'}
                                                                show_slide={true}
                                                                slide_value={10}
                                                                slide_function={getCarrierRoutes}
                                                                title={'Shipment routes with highest emission for the carrier ' + carrierSelected}

                                                                click_function={(v)=>{setRdcSelected(v) }}
                        />
                        </>
                : 
                <Alert variant='danger'>The routes for the current selection could not be displayed.</Alert>
                }

                    </Col>
                    <Col md={5} lg={5} xl={5}>
                        <Card style={{'height':'100%'}}>
                            <Card.Body>
                                {
                                      carrierMap.data[0].detail.length > 0 ?  
                                      <JRCTable
                                      columns={colTableRoutes}
                                      export_table={true}
                                      data={carrierRoutes}
                                      item_by_page={10}
                                      paginate={true}
                                                                              show_title={true}
                                      table_max_height='550px'
                                      show_search={true}                                         
                                      table_title={'TOP ' + topRoutes + ' shipment routes with highest emission: ' + carrierSelected}
                                      />   

                                      :
                                      <Alert variant='danger'>The routes for the current selection could not be displayed.</Alert>
           
                                }

                                 

                                <div style={{paddingTop:'20px'}}>

                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Col>

        </Row>

    )
}
