import React from 'react';
import Chart from 'react-apexcharts';
import GhgToolTip from "../tooltip/GhgTooltip";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getCookie} from 'react-use-cookie';
import {formatNumberPosDecimal} from "../../services/utilsService";

export default function GhgAreaChartApex({series,mode='light',
base_color,title,subtitle,x_title, y_title,
    default_colors=[],
type='area',
    description='',
height=340,
use_monochrome, palette_name='palette1'}){

    const region = getCookie('GHG-MEASURE-SEPARATOR') || 'de-DE';
    const options ={
        theme: {
            mode: mode,
            palette: palette_name,
            monochrome: {
                enabled: use_monochrome,
                color: base_color,
                shadeTo: mode,
                shadeIntensity: 0.70
            },
        },
        colors: default_colors,
  chart: {
    type: type,
    height: height,
    toolbar: {
      show: true
    },
    stacked: false
  },


  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth'
  },
  grid: {
    borderColor: '#e7e7e7',
    row: {
  // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  fill: {

    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.6,
      opacityTo: 0.9
    }
  },
  legend: {
      position: 'bottom',
      horizontalAlign: 'left',
    offsetX: -10,
    offsetY: -5,
      floating: false,
      fontSize: '12px',
      markers:{
          width: 13,
          height: 13,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: default_colors
      },
    labels: {
        useSeriesColors: false
    }

  },


  subtitle: {
    text: subtitle,
    align: 'left'  
  },
  
  noData: {
    text: 'Not data to show...'
  },  
  xaxis: {
    type: 'datetime',
    title:{
        text: x_title
      }
  },
  yaxis: {
    title: {
      text: y_title
    },
      labels: {
          formatter: function (value) {
              return formatNumberPosDecimal(value,0).toLocaleString(region, {
                  minimumFractionDigits: 0,
              })
          }
      }

  }
}


    return (
        <div style={{textAlign:'right', paddingRight:'5px'}}>
            <Row>
                <Col style={{textAlign:'left', paddingLeft:'30px', 'fontWeight':'bold'}} md={11} lg={11} xs={11}>
                  <h5> {title}</h5>
                   
                </Col>
                <Col md={1} lg={1} xs={1}>
                    <GhgToolTip title={title}
                                content_explanation={description}
                    />
                </Col>

            </Row>

          <Chart  options={options} series={series}   type={type} height={height}  />
        </div>

    )

}
