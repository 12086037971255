//Example of router by module
import React  from "react";
import { Routes, Route } from "react-router-dom";
import InboundDashboard from "./inboundDashboard";
import IntegralDashboard from "./integralDashboard";
import RoadDetailed from "./roadDetailed";
import RailDetail from "./railDetailed";
import SupplierAnalysis from "./road/SupplierAnalysis";
import CarrierAnalysis from "./road/CarrierAnalysis";
import SupplierProfile from "./road/SupplierProfile";
import CarrierProfile from "./road/CarrierProfile";
import SummaryProducts from './road/SummaryProducts';
import DetailProduct from './road/DetailProducts';
import RDCAnalysis from "./road/RDCAnalysis";
import DataExporter from "./exporter/DataExporter";
import OutboundDashboard from "./outboundDashboard";
import RoadDetailedOutbound from "./road/roadDetailedOutbound";
import CarrierRankingOutbound from "./road/CarrierRankingOutbound";
import CarrierProfileOutbound from "./road/CarrierProfileOutbound";
import RDCRankingOutbound from "./road/RDCRankingOutbound";
import RDCProfileOutbound from "./road/RDCProfileOutbound";
import UserManagement from "../users/management/userManagement";
import DetailProductOutbound from "./road/DetailProductsOutbound";
import SummaryProductsOutbound from "./road/SummaryProductsOutbound";
import Methodology from "../methodology/Methodology";


export default function VisualizationRouter(){

    return (
      
        <Routes>        
            <Route path="integral_dashboard" element={<IntegralDashboard/>}/>
             <Route path="inbound_dashboard" element={<InboundDashboard/>}/>
             <Route path="outbound_dashboard" element={<OutboundDashboard/>}/>
            <Route path="road_detailed" element={<RoadDetailed/>} />
            <Route path="road_detailed_outbound" element={<RoadDetailedOutbound/>} />
            <Route path="road_supplier_analysis" element={<SupplierAnalysis/>}/>
            <Route path="road_supplier_profile" element={<SupplierProfile/>}/>
            <Route path="road_carrier_analysis" element={<CarrierAnalysis/>}/>
            <Route path="road_carrier_profile" element={<CarrierProfile/>}/>
            <Route path="road_carrier_profile_outbound" element={<CarrierProfileOutbound/>}/>
            <Route path="rail_detailed" element={<RailDetail/>}/>
            <Route path="road_product_summary" element={<SummaryProducts/>}/>
            <Route path="road_product_summary_outbound" element={<SummaryProductsOutbound/>}/>
            <Route path="road_product_detail" element={<DetailProduct/>}/>
            <Route path="rdc_analysis" element={<RDCAnalysis/>}/>
            <Route path="rdc_outbound_ranking" element={<RDCRankingOutbound/>}/>
            <Route path="outbound_road_product_profile" element={<DetailProductOutbound/>}/>
            <Route path="road_carrier_ranking_outbound" element={<CarrierRankingOutbound/>}/>
            <Route path="road_rdc_profile_outbound" element={<RDCProfileOutbound/>}/>
            <Route path='user_management' element={<UserManagement/>}/>  
            <Route path="road_data_sampler" element={<DataExporter/>}/>
            <Route path="methodology" element={<Methodology/>}/>
            {localStorage.getItem('ghg_role')==='1' ?  
            <>
                <Route path='user_management' element={<UserManagement/>}/>  
                <Route path="road_data_sampler" element={<DataExporter/>}/>
            </>


 : <></>}          
        <Route exact path="*" element={<IntegralDashboard/>}/> 
                       
        </Routes>

    )
}
