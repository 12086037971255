import React,{useState,useEffect}  from 'react'
import { Row, Col, Card, ButtonToolbar, ToggleButtonGroup, ToggleButton, Alert } from 'react-bootstrap'
import SearchBox from "../../../components/searchBox/searchBox";
import PageTitle from '../../../components/pageTitle/pageTitle'
import NivoPie from '../../../components/charts/NivoPie';
import {CallAPI} from "../../../services/apiService";
import {
    convertLeaftMap,
    getLabelMeasurement,
    getUnitMeasurement,
    getValueMeasurement,
    formatNumberPosDecimalRegion
} from "../../../services/utilsService";
import { useSelector } from 'react-redux';
import useCookie,{getCookie} from 'react-use-cookie';
import JRCTable from '../../../components/JRCTable/JRCTable';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import RangeSlider from 'react-bootstrap-range-slider';
import NivoScatter from '../../../components/charts/NivoScatter';
import {
    convertPieNIVO,
   convertScatter,
} from "../../../services/utilsService";


export default function CarrierRankingOutbound(){
    const [client,setClient]=useState('hofer');
    const [startDate, setStartDate ] = useState('2022-01-01');
    const [endDate, setEndDate ] = useState('2022-12-31');
    const [startProcess,setStartProcess] = useState(true);
    const [dataScatter,setDataScatter]=useState([])
    const [scope, setScope] = useState('outbound');
    const [prodClassFiltered, setProdClassFiltered] = useState('ALL');
    const [emissionCarrier, setEmissionCarrier] = useState([])
    const [shipmentCarrier, setShipmentCarrier] = useState([])
    const [distanceCarrier, setDistanceCarrier] = useState([])
    const [weightCarrier, setWeightCarrier] = useState([])
    const [intensityCarrier, setIntensityCarrier] = useState([])
    const [carrierNumber, setCarrierNumber ] = useState(10); //default value
    const [criteriaOrder,setCriteriaOrder]=useState('SHIPMENTS') //default value
    
    const loginState = useSelector((state)=>state.login);
    const [calculationMethod,setCalculationMethod] = useState('basic');
    const [selectedCountry, setSelectedCountry] = useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );
  

    const colCarriers = [{'header':'Carrier Name','field':'carrier_name','capitalize':true},
    {'header':'Number of shipments', 'sorted':true, 'field':'number_shipments', 'type':'integer'},
    {'header':'Total emission (tCO2e)', 'sorted':true, 'field':'total_emission', 'type':'integer'},
    {'header':'Total distance (km)', 'sorted':true, 'field':'total_distance_km', 'type':'integer'},
    {'header':'Total weight (tonnes)', 'sorted':true, 'field':'total_weight_tonnes', 'type':'integer'},
    {'header':'Average distance in km', 'sorted':true, 'field':'avg_distance_km', 'type':'integer'},
    {'header':'Average weight in tonnes', 'sorted':true, 'field':'avg_weight_tonnes', 'type':'decimal'},
    {'header':'Average emission intensity per tkm ', 'sorted':true, 'field':'avg_emission_intensity', 'type':'integer'}, ];


    //function getting information


    const getShipmentsCarriers=()=>{

    
        CallAPI('GET',"/api/visual/v2/top_carrier_outbound/" ,
        {
            client_id:client, start:getCookie('GHG-INITIAL-DATE') || startDate   ,
             end:getCookie('GHG-END-DATE') || endDate ,
            level:calculationMethod, country:selectedCountry,
            product_class:prodClassFiltered,
            top: carrierNumber,criteria:criteriaOrder
        }
        ).then((resp)=>{
            if (resp.status === 200){    
                setIntensityCarrier(resp.data);        
                setShipmentCarrier(convertPieNIVO(resp.data,'carrier_name','carrier_name','number_shipments' ))
                setDistanceCarrier(convertPieNIVO(resp.data,'carrier_name','carrier_name','total_distance_km' ))
                setWeightCarrier(convertPieNIVO(resp.data,'carrier_name','carrier_name','total_weight_tonnes' ))
                setEmissionCarrier(convertPieNIVO(resp.data,'carrier_name','carrier_name','total_emission' ))
                setDataScatter(convertScatter(resp.data,'carrier_name','total_emission','number_shipments'))
            }
        })
    }



 
    const callData=()=>{
  
        if (startProcess===true){
             getShipmentsCarriers();           
            setStartProcess(false);

        }
    }

    const button_effect=(criteria)=>{
        if (criteriaOrder==criteria){
            return {'backgroundColor':"#cc0000","color":"#ffffff"}
        }
        else{
            return {'backgroundColor':"#002b80","color":"#ffffff"}
        }
    }

    useEffect(() => {
        callData();
      }, [startProcess,selectedCountry]);

    return (
        <>
        <Row>
            <PageTitle title='Carrier Ranking (Outbound)'></PageTitle>
            <Col xs={2} md={2} lg={2}>
            <SearchBox client={client} initialStartDate={startDate}
                       initialEndDate={endDate}
                       initialScope={'outbound'}        
                       show_product_class={false}               
                       funcCountry={(value)=>setSelectedCountry(value)}
                       funcProductClass={(value)=>setProdClassFiltered(value)}
                       funcEndDate={(value)=>setEndDate(value)}
                       funcStartDate={(value)=>setStartDate(value)}
                       funcProcess={(value)=>{setStartProcess(value)}}

            />
 
        </Col>
        <Col xs={10} md={10} lg={10}>
            <Row>
                <Col xs={6} md={6} lg={6}>
                <Card>
                   
                   <Card.Body>
                   <h5>Number of carriers to show ordered by {criteriaOrder}</h5>
                
             <ButtonToolbar>
    <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
      <ToggleButton style={button_effect("SHIPMENTS")} key={"SHIPMENTS"} onClick={()=>{setCriteriaOrder("SHIPMENTS"); setStartProcess(true); callData()} }  value={1}>By Shipments</ToggleButton>
      <ToggleButton style={button_effect("EMISSION")}  key={"EMISSION"} onClick={()=>{setCriteriaOrder("EMISSION");setStartProcess(true); callData()}}  value={2}>By Emission</ToggleButton>
      <ToggleButton style={button_effect("WEIGHT")}  key={"WEIGHT"} onClick={()=>{setCriteriaOrder("WEIGHT");setStartProcess(true); callData() } } value={3}>By Weight</ToggleButton>
      <ToggleButton style={button_effect("DISTANCE")}  key={"DISTANCE"} onClick={()=>{setCriteriaOrder("DISTANCE"); setStartProcess(true); callData()}}  value={3}>By Distance</ToggleButton>
    </ToggleButtonGroup>
  </ButtonToolbar>
  <RangeSlider
                 min={1}
                 max={20}
                 step={1}             
                 tooltip={'on'}
                 value={carrierNumber}
                 onChange={e => {setCarrierNumber(e.target.value)
                    setStartProcess(true);
                    callData();
                
                }}
      
               />
           
                   </Card.Body>
               </Card>
                </Col>
       
        
          
            </Row>
    
            {intensityCarrier.length > 0 ? <>

                <Row>
            <Col xs={6} md={6} lg={6}>
            <Card>
                    <Card.Body>
                         
                    <NivoPie show_legend={false}
             palette='pastel1'
              show_arc_labels={true}
              label_value='Emission tCO2e'
             description={'The numbers in bracket represent the emissions (in tonnes of C02e) generated by carrier for Outbound (road) transport.'}
             title={'TOP ' + carrierNumber + ': Distribution of emission in tCO2e by carrier share'} 
             data={emissionCarrier}/>
                        
                    </Card.Body>
                </Card>

            
            </Col>
            <Col xs={6} md={6} lg={6}>
            <Card>
                    <Card.Body>

                    <NivoPie show_legend={false}
    palette='pastel1'
              show_arc_labels={true}
              label_value='Number or shipments'
             description={'The numbers in bracket represent the number of (road) Outbound shipments by carrier.'}
             title={'TOP ' + carrierNumber + ' : Distribution of shipments by carrier share'} 
             data={shipmentCarrier}/>  

        
                    </Card.Body>
                </Card>
                </Col>            
            </Row>
            <Row>
            <Col xs={6} md={6} lg={6}>
            <Card>
                    <Card.Body>
                    <NivoPie show_legend={false}
    palette='pastel1'
              show_arc_labels={true}
              label_value='Total distance in km'
             description={'The number in brackets represents the total distance (in kilometres) traveled by each carrier.'}
             title={'TOP ' + carrierNumber + ': Distribution of distance (' + getUnitMeasurement('distance') + ') by carrier share'} 
             data={distanceCarrier}/>
                    </Card.Body>
                </Card>

            </Col>
            <Col xs={6} md={6} lg={6}>
            <Card>
                    <Card.Body>
                    <NivoPie show_legend={false}
    palette='pastel1'
              show_arc_labels={true}
              label_value='Total weight in tonnes'
             description={'The number in brackets represents the total weight (in tonnes) transported for each carrier.'}
             title={'TOP ' + carrierNumber +  ': Distribution of weight (tonnes) by carrier share'} 
             data={weightCarrier}/>
                    </Card.Body>
                </Card>    
            </Col>            
            </Row>
<Row>

<Col xs={12} md={12} lg={12}>
    <Card>
        <Card.Body>
        <JRCTable
        columns={colCarriers}
         export_table={true}
         data={intensityCarrier}
         
         item_by_page={10}
         paginate={true}
         show_search={true}
         table_max_height='600px'     
         table_title='Average emission intensity factor by carrier'
        />  
        </Card.Body>
    </Card>


</Col>
</Row>
{/* Carrier relation chart*/}

<Row>
    <Col xs={12} md={12} lg={12}>
    <NivoScatter data={dataScatter} 
                            height={440}
                            margin_right_chart={350}
                            palette={'pastel1'}
                            node_size={34}
                            legend_direction={'column'}
                            axis_y_legend="Number of shipments"
                            axis_x_legend="Total emission in tCO2e"
                            title={['Relation of emission in tCO2e and number of shipments processed per carrier']}
                            description={'This graph shows the relation between the number of shipments and the tonnes of C02e emitted by a carrier.'}
                            />
    
    
    </Col>
</Row>

{/* end carrier relation chart */}
            
            </>:
            <Alert variant='danger'> There is not data available for the selected filters, please change it and try again</Alert>
            }





        </Col>
        
        </Row>
       
        
        </>
    )
}