import React from "react";
import { Alert } from 'react-bootstrap';
import './page404.scss'

export default function Page404(){
    return (
      <div className="content_notfound">
          <Alert  variant="danger">
           Resource not found
           </Alert>
      </div>
    )
}