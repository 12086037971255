import React, {useEffect, useState} from 'react'
import PageTitle from '../../../components/pageTitle/pageTitle'
import {  Button, Card, Col, Form, Row } from 'react-bootstrap'
import { convertAPIDateFormat, convertSearchList, getColDataTable } from '../../../services/utilsService';
import  AsyncSelect  from 'react-select/async';
import { CallAPI } from '../../../services/apiService';

import {getCookie} from 'react-use-cookie';   
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import JRCTable from '../../../components/JRCTable/JRCTable';
import { useSelector } from 'react-redux';
 

export default function DataExporter(){

    const [countries, setCountries]=useState([]);
    const [client,setClient] = useState('hofer')
    const [scope, setScope] = useState('inbound');
    const [rdc, setRDC]=useState([]);
    const [selectedRDC, setSelectedRDC] = useState("ALL");
    const [calculationMethod,setCalculationMethod] = useState('basic');
    const [startDate, setStartDate ] = useState();
    const [endDate, setEndDate ] = useState();
    const [carriers,setCarriers] = useState([{}]);
    const [productClass, setProductClass] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('ALL');
    const [productClassSelected, setProductClassSelected]=useState();
    const [inputValue, setValue] = useState(''); 
    const [carrierSelected, setCarrierSelected]= useState(['ALL']);
    const [supplierSelected, setSupplierSelected]= useState('ALL');
    const [suppliers,setSuppliers] = useState([{}]);
    const [top,setTop]=useState(50)
    const [dataResult , setDataResult] = useState([]);
    const [colDataTable,setColDataTable] = useState([]);
    const loginState = useSelector((state)=>state.login);
    const [selectedCountry , setSelectedCountry]=useState(JSON.parse(loginState.userListCountries)[0]['country_code'] );


       // handle input change event
const handleInputChange = value => {
    setValue(value);
  };

  // handle selection
  const handleChange = value => {

    setSelectedProduct(value);

  }

  const fetchProducts = () => {
    if (inputValue){        
        return CallAPI("GET","/api/visual/v2/product_catalog/hofer/" + inputValue,{}).then((result)=>{
            const res =  result.data;
            return res;
        })
    }
  }

  //populate filters

const getCarrierCatalog=()=>{
    CallAPI('GET',"/api/visual/v2/catalog_carriers/" +  client ,{} ).then((resp)=>{
        if (resp.status === 200){
            setCarriers(convertSearchList(resp.data,'carrier_name','carrier_name'));
        }
    })
}



const getProductClassCatalog=()=>{
    CallAPI('GET',"/api/visual/v2/product_class_catalog/" + client ,{} ).then((resp)=>{
        if (resp.status === 200){
            setDataResult(resp.data);
          
          
        }
    })
}

const getRDC=()=>{
    CallAPI('GET',"/api/visual/v2/rdc_catalog/" ,{
        client_id: client,
        country: selectedCountry,
        scope: scope,
        level:calculationMethod
    } ).then((resp)=>{
        if (resp.status === 200){    
            setRDC(resp.data);
            //setSelectedRDC(resp.data[0].rdc);
        }
    })
}

const getSupplierCatalog=()=>{
    CallAPI('GET',"/api/visual/v2/catalogue_supplier_road/" + client ,{} ).then((resp)=>{
        if (resp.status === 200){
            setSuppliers(convertSearchList(resp.data,'supplier_id','supplier_name'));
        }
    })
}



    //functions to extract the data
    const getData=()=>{
        let parameters={'client_id':client,
        'level':calculationMethod,
        'scope': scope,
        'country': selectedCountry,
        'start': startDate,
        'end': endDate,
        'product_class': productClassSelected,
        'top':top,
        'supplier': supplierSelected,
        'product_name': selectedProduct.product_description,
        'carrier_name': carrierSelected,
        'destiny_rdc': selectedRDC       
        
        }
        CallAPI('GET',"/api/visual/v2/sync_data_sampler" ,parameters ).then((resp)=>{
            if (resp.status === 200){
                setDataResult(resp.data)
                setColDataTable(getColDataTable(resp.data[0]));
                            
              
            }
        })

    }
   
    const resetFilter=()=>{
        setCarrierSelected([null]);
        setSelectedProduct(null)
    }

    useEffect(()=>{
        //getCountries();
        setCountries(JSON.parse(loginState.userListCountries));
        getProductClassCatalog();
        setStartDate(getCookie('GHG-INITIAL-DATE') );
        setEndDate(getCookie('GHG-END-DATE') );  
        getRDC()
        getCarrierCatalog()
        getSupplierCatalog()
        
   },[])
   
    return (<>
    <PageTitle title='Road data sample generator'></PageTitle>
    <Row>
        <Col style={{'paddingLeft':'20px','paddingRight':'20px'}} 
        xs={12} md={12} lg={12}>
<Card>
    <Card.Header>
        <Card.Title>Filter definition section</Card.Title>
    </Card.Header>
    <Card.Body>
    
    <Form style={{marginTop:'30px'}}>
        <Row>
            <Col><Form.Group className="mb-3" controlId="calculation_method">
                                <Form.Label className={'text-muted'}>Operation Country</Form.Label>
                                <Form.Select aria-label="Operation Country" onChange={(e)=>setSelectedCountry(e.target.value)} >
                                    { countries.map((item)=>(
                                        <option key={item.country_code} value={item.country_code}>{item.country_name}</option>
                                    )) }

                                </Form.Select>
                            </Form.Group></Col>
                            <Col>
                            <Form.Group className="mb-3" controlId="calculation_method">
                                        <Form.Label className={'text-muted'}>Calculation Method</Form.Label>
                                        <Form.Select aria-label="Calculation Method" onChange={(e)=> setCalculationMethod(e.target.value) } >
                                            <option value='basic'>Basic</option>
                                        </Form.Select>
                                    </Form.Group>
                            </Col>
                            <Col>
                            <Form.Group className="mb-3" controlId="scope">
                                <Form.Label className={'text-muted'}>Scope</Form.Label>
                                <Form.Select aria-label="Scope" onChange={(e)=> setScope(e.target.value)} >
                                    <option value='inbound'>Inbound</option>
                                    <option disabled value='outbound'>Outbound</option>
                                </Form.Select>
                            </Form.Group>
                            </Col>
                            <Col>
                            <Form.Group className="mb-3" controlId="product_class_2">
                            <Form.Label className={'text-muted'}>Product class</Form.Label>
                            <Form.Select aria-label="Product class selection" onChange={(e)=>{
                                setProductClassSelected(e.target.value);
                                          }}>
                                <option value='ALL'>ALL</option>
                                {
                                    productClass.map((item)=>(
                                        <option value={item.product_class}>{item.product_class}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                            </Col>
                            <Col>
                            <Form.Group className="mb-4" controlId="startDate">
                                        <Form.Label className={'text-muted'}>Start date</Form.Label>
                                        <Form.Control value={startDate} controlid="start_date" type="date" placeholder="Enter the start date"
                                        onChange={(e)=>{
                                            setStartDate( convertAPIDateFormat( e.target.value ));
     
                                        }}
                                        />
                                    </Form.Group>
                            </Col>
                            <Col>
                            <Form.Group className="mb-3" controlId="endDate">
                                        <Form.Label className={'text-muted'}>End date</Form.Label>
                                        <Form.Control value={endDate} controlid="end_date" type="date" placeholder="Enter the end date"
                                        onChange={(e)=> {setEndDate(convertAPIDateFormat(e.target.value));
                                                                                 
                                        }
                                        }
                                        />
                                    </Form.Group>
                            </Col>
                        
        </Row>
        <Row>
        <Col>

        <Form.Group>
                        <Form.Label className={'text-muted'}>Destination RDC</Form.Label>
                        <Form.Select aria-label="Regional Distribution Center" onChange={(e)=>setSelectedRDC(e.target.value)} >
                        <option selected key={'ALL'} value={'ALL'}>ALL</option>
                                { rdc.map((item)=>(
                                    <option key={item.rdc} value={item.rdc}>{item.rdc}</option>
                                )) }

                            </Form.Select>

           
                        </Form.Group>
                            
                            </Col>
                            <Col><Form.Group className="mb-3" controlId="supplier">
                                <Form.Label className={'text-muted'}>Supplier Name</Form.Label>
                                <BootstrapSelect style={{'width':'100%'}}
                                    showSearch={true}
                                    onChange={(e)=>{
                                        if (e.selectedValue.length > 0 ){
                                            setSupplierSelected(e.selectedValue[0] );
                                        }
                                    }}
                                    placeholder={'-- select --'}  options={suppliers} />

  
                            </Form.Group></Col>

                            <Col><Form.Group className="mb-3" controlId="carrier">
                                <Form.Label className={'text-muted'}>Carrier Name</Form.Label>
                                
                                <BootstrapSelect style={{'width':'100%'}}
                                defaultOptions={['ALL']}
                                             showSearch={true}                                            
                                          
                                             onChange={(e)=>{
                                     if (e.selectedValue){
                                                 if (e.selectedValue.length >0){
                                                        setCarrierSelected(e.selectedValue[0]);

                                                     }
                                                    
                                                     
                                                 }

                                             }}
                                             placeholder={'-- select --'}  options={carriers} />
                            </Form.Group></Col>
                            <Col>
                            <Form.Group>
                        <Form.Label className={'text-muted'}>Product to Evaluate</Form.Label>
                        
                        <AsyncSelect
                                cacheOptions
                                defaultOptions
                                value={selectedProduct}
                                getOptionLabel={e => e.product_description}
                                getOptionValue={e => e.id}
                                loadOptions={fetchProducts}
                                onInputChange={handleInputChange}
                                onChange={handleChange}
                            />
                        </Form.Group>
                            </Col>
                            <Col>
                          
              
                            <Form.Group className="mb-3" controlId="calculation_method">
                                        <Form.Label className={'text-muted'}>Records to retrieve</Form.Label>
                                        <Form.Select  aria-label="Records to retrieve" onChange={(e)=> setTop(e.target.value) } >
                                            <option value='1'>1</option>
                                            <option value='5'>5</option>
                                            <option value='10'>10</option>
                                            <option value='20'>20</option>
                                            <option selected value='50'>50</option>
                                            <option value='100'>100</option>
                                            <option value='300'>300</option>
                                            <option value='500'>500</option>
                                        </Form.Select>
                                    </Form.Group>
                            </Col>
                           

        </Row>
        <Row>
            <Col xs={2} md={2}>
            <Button style={{marginTop:'20px', width:'100%'}} variant="primary"
                                    onClick={getData}
                                    >Apply filter</Button>
                                    
            </Col>
            <Col xs={2} md={2}>
            <Button style={{marginTop:'20px', width:'100%'}} variant="danger"
                                    onClick={resetFilter}
                                    >Reset filter</Button>
                                    
            </Col>
        </Row>                                   

                      
                        </Form>
        
    
    </Card.Body>
</Card>

        </Col>
    </Row>
    <Row>
        <Col xs={12} md={12} lg={12}>
            <Card>
                <Card.Body>
                <JRCTable
        columns={colDataTable}
        export_table={true}
        data={dataResult}
        show_grid={true}
        export_pdf={false}
        item_by_page={10}
        paginate={true}
        show_title={true}
        show_search={true}
        table_max_height='100%'     
        table_title={'Data export for road transport emission calculation :  ' +   client.toUpperCase() }

        />
                </Card.Body>
            </Card>
      
            
        </Col>
        
   



        
    </Row>
    </>

    )
}