import React from "react";
import { Link } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";

const Breadcrumbs = () => {
  const routes = [
         { path: "/visualization/road_supplier_profile",
          breadcrumb: "Inbound supplier profile" },
          { path: "/visualization/road_supplier_analysis",
          breadcrumb: "Inbound supplier ranking" },
          { path: "/visualization/road_carrier_analysis",
          breadcrumb: "Inbound carrier ranking" },
          { path: "/visualization/road_carrier_profile",
          breadcrumb: "Inbound carrier profile" },
          { path: "/visualization/road_product_summary",
          breadcrumb: "Inbound product ranking" },
          { path: "/visualization/road_product_detail",
          breadcrumb: "Inbound product profile" },
          { path: "/visualization/rdc_analysis",
          breadcrumb: "Inbound RDC analysis" },
          { path: "/visualization/rdc_analysis",
          breadcrumb: "Inbound RDC analysis" },

          { path: "/visualization/outbound_dashboard", breadcrumb: "Outbound dashboard" },
          { path: "/visualization/road_carrier_ranking_outbound", breadcrumb: "Outbound carrier ranking" },
          { path: "/visualization/road_carrier_profile_outbound", breadcrumb: "Outbound carrier profile" },
          { path: "/visualization/road_product_summary_outbound", breadcrumb: "Outbound product ranking" },
          { path: "/visualization/outbound_road_product_profile", breadcrumb: "Outbound product profile" },

          { path: "/visualization/rdc_outbound_ranking", breadcrumb: "Outbound RDC Ranking" },
          { path: "/visualization/road_rdc_profile_outbound", breadcrumb: "Outbound RDC Profile" },
          { path: "/visualization/methodology", breadcrumb: "Methodology" },

          { path: "/visualization",  breadcrumb: "Detailed analysis" },
          { path: "/",
          breadcrumb: "JRC Live" }

        
      ];
  //const breadcrumbs = useBreadcrumbs();
  const breadcrumbs = useBreadcrumbs(routes);


  return (
    <div className="breadcrumbs">
      {breadcrumbs.map(({ breadcrumb, match }, index) => (
  
 
        <div  className="bc" key={index+ '_'+ match.url}>
          <Link key={'link_' + match.pathname} to={ match.pathname || ""}>{breadcrumb}</Link>
          {index < breadcrumbs.length - 1 && " > "}
        </div>
      ))}
    </div>
  );
};

export default Breadcrumbs;
