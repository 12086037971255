import React, {useEffect, useState} from "react";
import { Button, Container, Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import logo from '../../assets/images/jrc-live-white.png'
import '../menu.scss'
import {useDispatch, useSelector, useStore} from 'react-redux';
import { Link } from "react-router-dom";
import {login_closeSession} from "../../pages/login/loginSlice";
import { useNavigate } from "react-router-dom"
import RegionalConfiguration from "../regionalConfiguration/RegionalConfiguration";
import { menuItems } from "./menuItems";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import "react-bootstrap-submenu/dist/index.css";
import Modal from 'react-bootstrap/Modal';
import ChangeJRCPassword from "../../pages/login/changePassword";
import useCookie,  {getCookie} from 'react-use-cookie';



function Menu(){
   
    const [roleMenu,setRoleMenu] = useState(2);
    const loginState = useSelector((state)=>state.login)
    const dispatch = useDispatch()
    const navigation = useNavigate();
    const [showRegional,setShowRegional]=useState(false)
    const [showChangePassword,setShowChangePassword]=useState(false)
    const handleShow = () => setShowRegional(true);
    let navigate = useNavigate()

    const closeSession=()=>{
        dispatch(login_closeSession({}));
        navigate('/')
    }
    const showModalRegion=()=>{
      if (showRegional==true){
          return (<RegionalConfiguration show_modal={true} func_close={setShowRegional} />)
      }else{
          return (<></>)
      }
    }

    const showModalChangePassword=()=>{     
      if (showChangePassword==true){
        return (<ChangeJRCPassword show_modal={true} func_close={setShowChangePassword} />)
    }else{
        return (<></>)
    } 
        
       }
    




    const redirect=(path)=>{
      navigate(path)
    }

    useEffect(()=>{
      const handleTabClose = event => {
        event.preventDefault();
        if (getCookie('GHG-KEEP-LOGGED')==='true'){
            //  console.log('active session');
        }else{
          dispatch(login_closeSession({}));
        }
       

      };
        showModalRegion();
        window.addEventListener('beforeunload', handleTabClose);
  
    },[showRegional])

    //TODO: find how to solve the problem  with the href and the access control, now using the function called redirect

    return (
        <>
            {showModalRegion()}
            {showModalChangePassword()}
            




            <Navbar expand='lg' className="nav-bar">
            <Container fluid >
                <Navbar.Brand href="#">
                <img className="logo_menu" alt="JRC Live logo" src={logo}/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
                <Navbar.Offcanvas
                aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                placement="end">    
    
          <Offcanvas.Body> 
      

          <Nav className="me-auto">
            <Nav.Link as={Link}  to="/">General Dashboard</Nav.Link>
            <NavDropdownMenu  title="Analysis Inbound" id="collasible-nav-dropdown"   >
            <NavDropdown.Item onClick={()=>redirect("/visualization/inbound_dashboard")}>General Overview Inbound</NavDropdown.Item>

            <DropdownSubmenu href="#" title="Carrier Analysis">
                <NavDropdown.Item onClick={()=>redirect("/visualization/road_carrier_analysis")}>Carrier Ranking</NavDropdown.Item>
                <NavDropdown.Item onClick={()=>redirect("/visualization/road_carrier_profile")} >Carrier Profile</NavDropdown.Item>
               </DropdownSubmenu>
               <DropdownSubmenu href="#" title="Product Analysis">
                <NavDropdown.Item onClick={()=>redirect("/visualization/road_product_summary")} >Product Ranking</NavDropdown.Item>
                <NavDropdown.Item onClick={()=>redirect("/visualization/road_product_detail")}>Product Profile</NavDropdown.Item>
               </DropdownSubmenu>
            <NavDropdown.Item onClick={()=>redirect("/visualization/road_detailed")}>Road Emission Detailed</NavDropdown.Item>
            <NavDropdown.Item onClick={()=>redirect("/visualization/rail_detailed")}>Rail Emission Detailed</NavDropdown.Item>
              
            <NavDropdown.Item onClick={()=>redirect("/visualization/rdc_analysis")}>RDC Analysis</NavDropdown.Item>
               <DropdownSubmenu   href="#" title="Supplier Analysis" >
                <NavDropdown.Item  className={"submenu"}
                 onClick={()=>redirect("/visualization/road_supplier_analysis")}>Supplier Ranking</NavDropdown.Item>
                <NavDropdown.Item   className={"submenu"}  onClick={()=>redirect("/visualization/road_supplier_profile")} >Supplier Profile</NavDropdown.Item>
               </DropdownSubmenu>            
            </NavDropdownMenu>
       

            {/*OUTBOUND */}
            { process.env.REACT_APP_SHOW_OUTBOUND === 'true' ?
             <>
   
             <NavDropdownMenu  title="Analysis Outbound" id="collasible-nav-dropdown"   >
             <NavDropdown.Item onClick={()=>redirect("/visualization/outbound_dashboard")}>General Overview Outbound</NavDropdown.Item>
             <DropdownSubmenu href="#" title="Carrier Analysis">
                 <NavDropdown.Item onClick={()=>redirect("/visualization/road_carrier_ranking_outbound")}>Carrier Ranking</NavDropdown.Item>
                 <NavDropdown.Item onClick={()=>redirect("/visualization/road_carrier_profile_outbound")} >Carrier Profile</NavDropdown.Item>
               </DropdownSubmenu>
               <DropdownSubmenu href="#" title="Product Analysis">
                 <NavDropdown.Item onClick={()=>redirect("/visualization/road_product_summary_outbound")} >Product Ranking</NavDropdown.Item>
                 <NavDropdown.Item onClick={()=>redirect("/visualization/outbound_road_product_profile")}>Product Profile</NavDropdown.Item>
               </DropdownSubmenu>
              <DropdownSubmenu href="#" title="RDC Analysis">
                 <NavDropdown.Item onClick={()=>redirect("/visualization/rdc_outbound_ranking")}>RDC Ranking</NavDropdown.Item>
                 <NavDropdown.Item onClick={()=>redirect("/visualization/road_rdc_profile_outbound")} >RDC Profile</NavDropdown.Item>
               </DropdownSubmenu> 
               </NavDropdownMenu>
               </>
             :<></>
          
          }
           
        


             {/* disable option data export
                         <NavDropdownMenu  title="Data Export" id="collasible-nav-dropdown">
             <NavDropdown.Item onClick={()=>redirect("/visualization/road_data_sampler")}>Road export data</NavDropdown.Item>
</NavDropdownMenu>
             
             */}
 {loginState.userRoleId === 1 ?<Nav.Link as={Link}  to="/visualization/user_management">User Management</Nav.Link> : <></> }
<Nav.Link as={Link}  to="/visualization/methodology">Methodology</Nav.Link>
 



       
           
          </Nav>

            


            <Nav className="justify-content-end">
       
                
           
            <NavDropdown align="end"
                 title={
                    <span> <i className="bi bi-person">{loginState.userId} </i></span>}>
                <NavDropdown.Item onClick={closeSession}>Close session </NavDropdown.Item>
                <NavDropdown.Item onClick={()=>setShowChangePassword(true)}>Change password </NavDropdown.Item>
                <NavDropdown.Item onClick={handleShow}>Config. region </NavDropdown.Item>
                <div style={{backgroundColor:'##d0d0e1', marginTop:'20px', textAlign:'center', fontSize:'11px', color:'#004d99'}}>
                  <li>Version {process.env.REACT_APP_APP_ENV} - {process.env.REACT_APP_APP_VERSION}</li>
             </div>
                {/*
                               <NavDropdown.Item href="#action4">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                  Something else here
                </NavDropdown.Item>
                 */}

              </NavDropdown>
              
            </Nav>
            
          </Offcanvas.Body>          
        </Navbar.Offcanvas>      
      
      </Container>

  
    </Navbar>
    <div style={{'alignItems':'left', 'textAlign':'left','width':'100%','backgroundColor': '#109ac4'}} >

    <Breadcrumbs/>
    </div> 
   
    
  

        </>
     
    )
}

export default Menu
