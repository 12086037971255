import React, {useState,useEffect}  from 'react'
import {CallAPI} from "../../../services/apiService";
import {getCookie} from 'react-use-cookie';
import {
    convertLeaftMap,
    getLabelMeasurement,
    getUnitMeasurement,
    getValueMeasurement,
    formatNumberPosDecimalRegion,
    convertlineBarChartData
} from "../../../services/utilsService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {Alert, Form} from "react-bootstrap";
import KPICard from "../../../components/statistic/KPICard";
import CarrierSearchBox from "../../../components/carrierSearchBox/CarrierSearchBox";
import {useSelector} from "react-redux";
import PageTitle from '../../../components/pageTitle/pageTitle';
import JRCTable from '../../../components/JRCTable/JRCTable';
import {useSearchParams} from "react-router-dom";
import msgDialog from '../../../services/dialogService';
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import {convertAPIDateFormat, convertSearchList} from "../../../services/utilsService";
import GhgBarLineChartApex from '../../../components/charts/GhgBarLineChartApex';



export default function RDCProfileOutbound(){
    const [searchParams, setSearchParams] = useSearchParams();
    const [startDate, setStartDate]= useState('2022-01-01');
    const [endDate, setEndDate]= useState('2022-12-31');
    //TODO: Link it with the Login consider using a Cookie for the Login with a expiration of 3 days
    const [client,setClient] = useState('hofer');

    const [carrierNameSelected, setCarrierNameSelected]= useState([searchParams.get('rdc_name')]);
    const [RDCSelected, setRDCSelected]= useState([searchParams.get('rdc_id')]);
    const [productClassSelected, setProductClassSelected]=useState('ALL');
    
    const [carrierStat, setCarrierStat] = useState({})
    const [RDCs,setRDCS] = useState([{}]);
    const loginState = useSelector((state)=>state.login);
    //variables storage chart data
    const [rdcProfile, setRDCProfile]=useState({});
    const [emissionCarrierTable, setEmissionCarrierTable]=useState([])
    const [emissionIntensity, setEmissionIntensity]=useState({series:[], x_axis:[]})
    const [emissionShipments, setEmissionShipments]=useState({series:[], x_axis:[]})
    const [emissionDistance, setEmissionDistance]=useState({series:[], x_axis:[]})
    const [emissionWeight, setEmissionWeight]=useState({series:[], x_axis:[]})

      
    const [selectedCountry , setSelectedCountry]=useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );
    const [calculationMethod,setCalculationMethod] = useState('basic');
    const [scope, setScope] = useState('inbound');

    const colCarrierEmission = [{'header':'Carrier Name',
    'field':'carrier_name','capitalize':true},
        {'header':'Total emission (tCO2e)', 'sorted':true, 
        'field':'total_emission', 'type':'integer'} ];


    let general_parameters={
            client_id:client, start:getCookie('GHG-INITIAL-DATE'), end: getCookie('GHG-END-DATE') ,
            rdc_id:  RDCSelected[0],
            level:calculationMethod, country:  getCookie('GHG-DEFAULT-COUNTRY'),        
            measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')    
        }

    const handleSubmit = event => {
            event.preventDefault(); //
        }
    
    const getRDCCatalog=()=>{
            CallAPI('GET',"/api/visual/v2/cat_outbound_rdc/" ,
            {
                client_id:client, start:( getCookie('GHG-INITIAL-DATE') || '2020-01-01' ),
                 end: ( getCookie('GHG-END-DATE') || '2025-01-01') ,
                rdc_id:  RDCSelected[0],
                level:calculationMethod, country:  getCookie('GHG-DEFAULT-COUNTRY'),        
                measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')    
            }
            ).then((resp)=>{          
                if (resp.status === 200){                                
                    setRDCS(convertSearchList(resp.data,'id','name'));
                }
            })
        }

    const getRDCStat=()=>{
        CallAPI('GET',"/api/visual/v2/stat_by_rdc_outbound/",
        
        {
            client_id:client, start:getCookie('GHG-INITIAL-DATE'), end: getCookie('GHG-END-DATE') ,
            rdc_id:  RDCSelected[0],
            level:calculationMethod, country:  getCookie('GHG-DEFAULT-COUNTRY'),        
            measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')    
        }
        
        ).then((resp)=>{
              if (resp.status === 200){
                setCarrierStat(resp.data);
            }})
        //get the RDC Profile
        CallAPI('GET',"/api/visual/v2/outbound_rdc_profile/",
        {
            client_id:client,  
            rdc_id:  RDCSelected[0],
            level:calculationMethod, country:  getCookie('GHG-DEFAULT-COUNTRY')   
        }  
        
       ).then((resp)=>{
            if (resp.status === 200){
              setRDCProfile(resp.data);
          }})
      //carriers with highest emission
      CallAPI('GET',"/api/visual/v2/top_carrier_outbound_emission/",{
        client_id:client, start:getCookie('GHG-INITIAL-DATE'),
         end: getCookie('GHG-END-DATE') ,
            rdc_id:  RDCSelected[0],
            level:calculationMethod, country:getCookie('GHG-DEFAULT-COUNTRY')

      } ).then((resp)=>{ 
          if (resp.status === 200){
          setEmissionCarrierTable(resp.data);
      }})




        
        }



    const getEmissionIntensity=()=>{
        CallAPI('GET',"/api/visual/v2/evol_outbound_rdc_intensity/" ,general_parameters ).then((resp)=>{
            if (resp.status === 200){
                let resultBar = convertlineBarChartData(resp.data, 'month_year', 
                [{name:'Emissions',type:'column', column_value: 'total_emission',
                columns_description: [{'label':'Total Emission (tCO2e)',
                'value':'total_emission'},
                 {'label':'Emission Intensity Factor','value':'avg_emission_intensity'}]
            
            },
                    {name:'Emission intensity',type:'line', column_value: 'avg_emission_intensity',
                    columns_description: [{'label':'Total Emission (tCO2e)',
                    'value':'total_emission'},
                     {'label':'Emission Intensity Factor','value':'avg_emission_intensity'}]
                }]);
                   setEmissionIntensity(resultBar);
                    
            }})}

    
    const getEmissionShipments=()=>{
                CallAPI('GET',"/api/visual/v2/evol_outbound_rdc_shipments/" ,general_parameters ).then((resp)=>{
                    if (resp.status === 200){
                        let resultBar = convertlineBarChartData(resp.data, 'month_year', [{name:'Emissions',type:'column', column_value: 'total_emission',
                        columns_description: [{'label':'Total Emission (tCO2e)',
                        'value':'total_emission'},
                         {'label':'Number of shipments','value':'number_shipments'}]
                    },
                            {name:'Number of Shipments',type:'line', column_value: 'number_shipments',
                            columns_description: [{'label':'Total Emission (tCO2e)',
                            'value':'total_emission'},
                             {'label':'Number of shipments','value':'number_shipments'}]
                        
                        }]);
                         setEmissionShipments(resultBar);
                            
                    }})}
    
        
    const getEmissionDistance=()=>{
                        CallAPI('GET',"/api/visual/v2/evol_outbound_rdc_distance/" ,general_parameters ).then((resp)=>{
                            if (resp.status === 200){
                                let resultBar = convertlineBarChartData(resp.data, 'month_year', [{name:'Emissions',type:'column', column_value: 'total_emission',
                                columns_description: [{'label':'Total Emission (tCO2e)',
                                'value':'total_emission'},
                                 {'label':'Average Distance in Km','value':'avg_distance_km'}]
                            },
                                    {name:'Average Distance km',type:'line', column_value: 'avg_distance_km',
                                
                                    columns_description: [{'label':'Total Emission (tCO2e)',
                                    'value':'total_emission'},
                                     {'label':'Average Distance in Km','value':'avg_distance_km'}]
                                }]);
                                  setEmissionDistance(resultBar);
                                    
                            }})}


    const getEmissionWeight=()=>{
                                CallAPI('GET',"/api/visual/v2/evol_outbound_rdc_weight/" ,general_parameters ).then((resp)=>{
                                    if (resp.status === 200){
                                        let resultBar = convertlineBarChartData(resp.data, 'month_year', [{name:'Emissions',type:'column', column_value: 'total_emission',
                                        columns_description: [{'label':'Total Emission (tCO2e)',
                                         'value':'total_emission'},
                                          {'label':'Average weight in tonnes','value':'avg_weight_tonnes'}]
                                    
                                    },
                                            {name:'Average weight in tonnes',type:'line', column_value: 'avg_weight_tonnes',
                                            columns_description: [{'label':'Total Emission (tCO2e)',
                                            'value':'total_emission'},
                                             {'label':'Average weight in tonnes','value':'avg_weight_tonnes'}]
                                        
                                        }]);
                                         setEmissionWeight(resultBar);
                                            
                                    }})}




    const getData=(current_carrier)=>{

        if (current_carrier || RDCSelected[0]){
                getEmissionIntensity();
                getEmissionShipments();
                getEmissionDistance();
                getEmissionWeight();
                getRDCStat();
        }else{
            msgDialog.msgError("Please select a valid RDC and then press the button 'Apply filter'")
            return false;

        }   
    }


    useEffect(()=>{
      //  getRDCCatalog();      
        if (searchParams.get('rdc_id')){           
           getData(searchParams.get('rdc_id'));
        }
    },[])


    return (
        <Row>
                <PageTitle title='RDC Profile Outbound Road' />  
            <Col md={2} xl={2} lg={2} style={{marginTop:'17px'}} >
                <CarrierSearchBox func_carrier={setRDCSelected}
                                   default_value = {searchParams.get('rdc_name') || ''}
                                  func_start_date={(value)=>setStartDate(value)}
                                  func_end_date={(value)=>setEndDate(value)}
                                  show_carrier_list={false}
                                  fixed_scope='outbound'
                                  show_product_class={false}
                                  initialStartDate={startDate}
                                  initialEndDate={endDate}
                                  func_country={(country)=>{setSelectedCountry(country);                               
                                    getRDCCatalog();                                  
                                  } }
                                  func_product_class={setProductClassSelected}

                func_get_data={getData}
                />

            </Col>
            <Col xs={10} md={10} xl={10}>
                <Row>
                    <Col xs={6} md={6} xl={6}>
                        <Card>
                            <Card.Body>
                            <Form onSubmit={handleSubmit}>
                        <Row>
                            <Form.Label className={'text-muted'}>RDC Name</Form.Label>
                            <BootstrapSelect style={{'width':'100%'}}
                                             showSearch={true}                                          
                                             onChange={(e)=>{
                                                  if (e.selectedValue){
                                                      if (e.selectedValue.length >0){
                                                                   setRDCSelected(e.selectedKey);                                                            
                                                          setCarrierNameSelected(e.selectedValue);
                                                          //getData(e.selectedKey);                                                    
                                                   }          
                                                 }
                                             }}
                                             placeholder={'-- select --'}  options={RDCs} />
                                             </Row>
                                            </Form>
                            </Card.Body>
                        </Card> 
                        <Card style={{height:'75%', marginBottom :'20px'}}>
                        <Card.Body>
                            <table className={'table table-striped'}>
                                <tr><td style={{textAlign:'left', fontWeight:'bold',width:'30%'}}>RDC City Name:</td><td style={{textAlign:'left'}}>{rdcProfile?.origin_city_rdc}</td></tr>
                                <tr><td style={{textAlign:'left', fontWeight:'bold',width:'30%'}}>RDC Code:</td><td style={{textAlign:'left'}} >{rdcProfile?.origin_id_rdc}</td></tr>
                               {/*
                                 <tr><td style={{textAlign:'left', fontWeight:'bold',width:'30%'}}>RDC Latitude:</td><td style={{textAlign:'left'}}>{rdcProfile?.origin_latitude}</td></tr>
                                <tr><td style={{textAlign:'left', fontWeight:'bold',width:'30%'}}>RDC Longitude:</td><td style={{textAlign:'left'}}>{rdcProfile?.origin_longitude}</td></tr>
               
                               
                               */}
                                           </table>
                            
                        </Card.Body>    
                        </Card>                 
                     </Col>
                     <Col xs={6} md={6} xl={6}>
                        <Card>
                            <Card.Body>
                            
                   <JRCTable
                columns={colCarrierEmission}
                 export_table={true}
                 data={emissionCarrierTable}                 
                 item_by_page={5}
                 paginate={true}
                 show_search={true}
                 table_max_height='600px'     
                 table_title='Top 10 carriers with highest emission (tCO2e)'
                />  

                            </Card.Body>
                        </Card>

                     </Col>
                </Row>


                {!RDCSelected[0] ? <Alert variant='danger'>
                     No RDC selected ,  please select the desired filters on the left and click on "Apply filter"</Alert>:<></>}
                <Row>
                 
                    <Col md={12} xl={12} lg={12}>
                        {/* Maps sections */}
                        <Row>
                            <Col xs={6} md={6} xl={6} lg={6}>
                                <Card>
                                    <Card.Body>
                                        <GhgBarLineChartApex
                                        title={"Evolution of emission intensity (gCO2e/tkm) for the RDC " + carrierNameSelected}
                                        x_axis={emissionIntensity?.x_axis } y_axis={'Emission in tCO2e'}
                                        show_labels={true}
                                        show_legend={true}
                                        content_explanation='This chart shows the development of the total Outbound emission of one RDC in tCO2e within the selected
                                        time frame in relation to the development of the emission intensity factor in gCO2e/tkm. This factor
                                        represents the amount of emission expected for each tonne-kilometre travelled. It is calculated by dividing
                                        total emission by tonnes x kilometres (tkm).'
                                        y2_axis='Emission intensity (gCO2e/tkm)'
                                        height={400} 
                                        series_data={emissionIntensity?.series}
                                        ></GhgBarLineChartApex>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} md={6} xl={6} lg={6}>
                            <Card>
                                    <Card.Body>
                                        <GhgBarLineChartApex
                                        title={"Evolution of shipment of RDC " + carrierNameSelected}
                                        x_axis={emissionShipments?.x_axis } y_axis={'Emission in tCO2e'}
                                        show_labels={true}
                                        show_legend={true}
                                        content_explanation='This chart shows the development of the total Outbound emission of one RDC in tCO2e within the selected
                                        time frame in relation to the development of the number of Outbound shipments during the same months.
                                        Since the total of transport-related emissions highly depends on the total distance travelled, which
                                        correlates strongly with the number of transports, the course of the curves is similar in most cases.'
                                        y2_axis='Number of shipments'
                                        height={400} 
                                        series_data={emissionShipments?.series}
                                        ></GhgBarLineChartApex>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        {/* End map section */}
                   
                    </Col>
                    </Row>
                    <Row>
                 
                 <Col md={12} xl={12} lg={12}>
                     {/* Maps sections */}
                     <Row>
                         <Col xs={6} md={6} xl={6} lg={6}>
                             <Card>
                                 <Card.Body>
                                     <GhgBarLineChartApex
                                     title={"Evolution of average service distance of RDC " + carrierNameSelected}
                                     x_axis={emissionDistance?.x_axis } y_axis={'Emission in tCO2e'}
                                     show_labels={true}
                                     height={400} 
                                     content_explanation='This chart shows the development of the total Outbound emission of one RDC in tCO2e within the selected
                                     time frame in relation to the development of the average service distance of the transports starting at the
                                     RDC. The total of transport-related emissions highly depends on the total distance travelled. The total
                                     distance is made up of the number of shipments and the service distance. A different progression of the
                                     curves of emission and average service distance should therefore only be possible if the curves of total
                                     emission and number of shipments match (in theory).'
                                     show_legend={true}
                                     y2_axis='Average driven service distance (km)'

                                     series_data={emissionDistance?.series}
                                     ></GhgBarLineChartApex>
                                 </Card.Body>
                             </Card>
                         </Col>
                         <Col xs={6} md={6} xl={6} lg={6}>
                         <Card>
                                 <Card.Body>
                                     <GhgBarLineChartApex
                                     title={"Evolution of average tour weight of RDC " + carrierNameSelected}
                                     x_axis={emissionWeight?.x_axis } y_axis={'Emission in tCO2e'}
                                     show_labels={true}
                                     show_legend={true}
                                     content_explanation='This chart shows the development of the total Outbound emission of one RDC in tCO2e within the selected
                                     time frame in relation to the development of the average weight transported from the RDC for these
                                     Outbound shipments.'
                                     y2_axis='Average weight in tonnes'
                                     height={400} 
                                     series_data={emissionWeight?.series}
                                     ></GhgBarLineChartApex>
                                 </Card.Body>
                             </Card>
                         </Col>
                     </Row>

                     {/* End map section */}
                
                 </Col>
                 </Row>

                    <Row>
                    <Col md={12} xl={12} lg={12}>
                    <Card>
                            <Card.Body>
                      
                                <Row>
                                    <Col xs={4}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(carrierStat.total_emission  ,2)}
                                        title='Total emission in tCO2e '
                                        content_explanation='Sum of the Greenhouse gas emission expressed in tCO2e of the selected RDC'
                                    /></Col>
                                    <Col xs={4}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(carrierStat.number_shipment_orders,2)}
                                        title='Number of shipments'
                                        content_explanation='Number of Outbound shipments starting from the selected RDC within the selected time frame.'
                                    /></Col>
                                            <Col xs={4}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={ formatNumberPosDecimalRegion(getValueMeasurement('distance', carrierStat.avg_distance_traveled),2)}
                                        title={'Average tour distance (' + getUnitMeasurement('distance') + ')' }
                                        content_explanation={'Average tour distance of all Outbound transports starting from the selected RDC within the selected time frame' }
                                    /></Col>
                                          

                                </Row>
                                <Row style={{paddingTop:'10px'}}>
                        
                                  
                                    <Col xs={4}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(carrierStat.avg_shipment_weight_tonns,2)}
                                        title='Average shipment weight (tonnes)'
                                        content_explanation='Average shipment weight of all Outbound transports starting from the selected RDC within the
                                        selected time frame'/></Col>
                         
                        
                                <Col xs={4}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={ formatNumberPosDecimalRegion(getValueMeasurement('distance', carrierStat.avg_transportation_activity_thousand),2)}
                                        title={'Average transport activity (tkm) in thousands' }
                                        content_explanation={'The transport activity is a commonly used indicator to measure how much total weight was transported over the total distance, expressed in thousands of tonne-kilometres. For Outbound transports the value is calculated by multiplying kilometres driven with the shipped volume in tonnes (Tonne-kilometre = driven km * shipped volume in tonnes) PER TRANSPORT LEG. The value shows the average transport activity of the Outbound shipment legs. Further details and a calculation example can be found in the methodology tab.' }
                                    /></Col>
                            
                                    <Col xs={4}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(carrierStat.avg_emission_intensity,2)}
                                        title='Average emission intensity (gCO2e / tkm)'
                                        content_explanation='This KPI shows the emission intensity factor resulting from the calculation of Outbound transport
                                        emission of the selected RDC in the selected time frame, where the total emission, expressed in
                                        gCO2e, are divided by the tonnes x kilometres. This value represents the amount of emission
                                        expected for each tonne-kilometre travelled.'
                                    /></Col>
                                </Row>
                       
                            </Card.Body>
                        </Card>
              
                    </Col>
                </Row>
            

            </Col>

        </Row>

    )
}
