import React ,{useState,useEffect} from 'react'
import { useQuery, useMutation,useLazyQuery, gql } from "@apollo/client";
import { Row, Col, Card, Form, Button} from 'react-bootstrap';
import PageTitle from '../../../components/pageTitle/pageTitle';
import JRCTable from '../../../components/JRCTable/JRCTable';
import { CallAPI } from '../../../services/apiService';
import msgDialog from '../../../services/dialogService';
import Multiselect from 'multiselect-react-dropdown';


export default function UserManagement(){

    const userColumns = [{'header':'First Name','field':'first_name'},
                {'header':'Last Name','field':'last_name'},
                {'header':'Email','field':'email'},
            {'header':'User enabled','field':'user_enabled'}
            ]

const [currentUsers,setCurrentUsers] = useState([]);
const [selectedRole, setSelectedRole] = useState(2) // Role by default 
const [existingUser,setExistingUser] = useState(false);
const [countries,setCountries] = useState([])
const [assignedCountries,setAssignedCountries] = useState([]);
const [listUsers,setListUsers] = useState([{}]);
const [countryChecked,setCountryChecked]=useState(false);
const [tempPassword,setTempPassword]=useState("")


//user attributes
const [firstName , setFirstName ] = useState();
const [lastName , setLastName ] = useState();
const [email, setEmail] = useState();
const [roleId,setRoleId ] = useState();
const [selectedCountries, setSelectedCountries] = useState([]);
const [userEnabled,setUserEnabled] = useState(true)

//function to handle the selected record
const handlerSelected=(data)=>{
    setFirstName(data['first_name']);
    setLastName(data['last_name']);
    setEmail(data['email']);
    setRoleId(data['role_id']);
    setSelectedRole(data['role_id']);
    setUserEnabled(data['enabled']);
    let countries_selected=[]
    data['countries'].split(",").map((item1)=>{
        countries.map((item2)=>{
            if (item2.country_id==item1){
                countries_selected.push({'country_id': item1, 'country_name':item2['country_name']})

            }
        })
    });
    setAssignedCountries(countries_selected);

    


}


const getCurrentUsers=()=>{    
    CallAPI('GET',"/api/visual/auth_ghg/user_profile_list",{'client_id':'hofer'} ).then((resp)=>{
         if (resp.status === 200){      
            setListUsers(resp.data);
        }
    })}



const getCountries=()=>{    
        CallAPI('GET',"/api/visual/auth_ghg/register_country").then((resp)=>{
             if (resp.status === 200){
                   setCountries(resp.data);
            }
    })}




const onSelect =(items)=>{
    let temp_list = [];
    items.map((item)=>{
        temp_list.push(item['country_id'])
        return true;

    })
    setSelectedCountries(temp_list);
}




const saveChanges=()=>{

    if (selectedCountries.length === 0){
        msgDialog.msgError("You must associate the user with at least one country");
        return false;
    }
    msgDialog.msgConfirm("User management","Are you sure to save the changes?",()=>{
          
        let parameters ={'name':firstName,
        'family_name': lastName,'email':email,'role_id': selectedRole,
        'client_id':'hofer',
        'enabled': userEnabled,
        'countries': selectedCountries
        }
     
        CallAPI('POST',"/auth/auth_ghg_core/add_user",parameters).then((resp)=>{
            if (resp.status === 200){            
                if (resp.data['reason']==='USER_ALREADY_EXIST' || resp.data['reason']==='CREATE_NEW_USER'  ){
                    CallAPI('POST',"/api/visual/auth_ghg/add_user",parameters).then((resp)=>{
                        if (resp.status === 200){
                            msgDialog.msgSuccess("User permission registered succesfully");
                        }
                    
                })
             
                getCurrentUsers();   
                    return true;
                }
               
        
           }
        })

    },()=>{})
}

const restartPassword = ()=>{
    msgDialog.msgConfirm("User management",
    "Do you want to restart the password for the selected user?",
    ()=>{
        CallAPI('POST',"/auth/auth_ghg_core/restart_user_password",{'email':email}).then((resp)=>{
            if (resp.status === 200){
                msgDialog.msgSuccess("User permission registered succesfully");
                setTempPassword(resp.data['temp_password']);  
           }
        })

    }
    )
}


useEffect(()=>{
    getCurrentUsers();
    getCountries();  
    
},[])

    
return (
    <>
 
    
     <PageTitle title='Users and permission management' />  
    <Row>
        <Col xs={5} md={5} lg={5}>
    <JRCTable columns={userColumns}
    table_title={'Users availables in JRC Live platform'}
     item_by_page={10}
     show_title={true}
     show_search={true}
     select_function={handlerSelected}
     show_select={true}
     table_max_height='470px' 
     paginate={true}
    data={listUsers}/> 
    
    
            
        </Col>
        <Col xs={6} md={6} lg={6} style={{'marginLeft':'15px'}}>
            <Row>

            <Card>
                <Card.Header>
                    <Card.Title>
                        Grant permission to Green House Gas Emission Platform
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Row>
                            <Col xs='4'>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control value={firstName}
                                onChange={e=>setFirstName(e.target.value)}
                                type='text'></Form.Control>
                                                            </Col>
                            <Col xs='4'>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                value={lastName}
                                onChange={e=>setLastName(e.target.value)}
                                type='text'></Form.Control>
                            </Col>
                            <Col xs='4'>
                            <Form.Label>Email</Form.Label>
                                <Form.Control
                                value={email}
                                onChange={e=>setEmail(e.target.value)}
                                type='email'></Form.Control>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs='4'>
                            <Form.Label>
                             Role assigned
                            </Form.Label>
                            <Form.Select defaultValue={selectedRole} aria-label="Scope" onChange={(e)=> setSelectedRole(e.target.value) } >
                                    <option value='1'>Administrator</option>
                                    <option selected value='2'>Reader</option>
                                </Form.Select>
                            </Col>
                            <Col xs='4'>
                            <Form.Label>
                             Contries with permission
                            </Form.Label>

                          <Multiselect 
                          options={countries}
                          displayValue='country_name'
                          onChange={(e)=>onSelect(e)}
                          onRemove={(e)=>onSelect(e)}
                          onSelect={(e)=>onSelect(e)}
                          selectedValues={assignedCountries}
                          ></Multiselect>

            
                            </Col>
                            <Col xs={4} md={4}>
                            <Form.Check
                            type='switch'
                            inline
                            defaultChecked={userEnabled}                            
                            onClick={(e)=>{setUserEnabled(e.target.value ==='on'?true:false)
                           
                            }}
                           label='User enabled'
                        />


                            </Col>
                        </Row>
                        <Row>
                            { email ?
                            <>
                             <Col xs='3'>
                             <Button style={{'marginTop':'30px'}} onClick={saveChanges} variant='primary'>Save changes</Button>
                         </Col>
                                <Col xs='3'>
                                <Button style={{'marginTop':'30px'}} onClick={restartPassword} variant='danger'>Restart password</Button>
                            </Col>
                            <Col xs='3'>
                              {tempPassword ?    <label>Temporal Password: {tempPassword}</label> : <></> }
                            </Col>
                            </>
                            : <></>}
                           {!email ?
                              <Col xs='3'>
                              {!existingUser ?
                              <Button style={{'marginTop':'30px'}} variant='danger'>Create New User</Button>
                              :<></> }
                              
                          </Col>
                           :<></>}
                         
                           
                        </Row>
                    </Form>
                    
                    </Card.Body>
                    </Card>
   

          
            </Row>
            <Row>


            </Row>

     

        </Col>
    </Row>    </>
)

}

