import React,{useState,useEffect}  from 'react'
import { Row, Col, Card, ButtonToolbar, ToggleButtonGroup, ToggleButton, Alert } from 'react-bootstrap'
import SearchBox from "../../../components/searchBox/searchBox";
import PageTitle from '../../../components/pageTitle/pageTitle'
import NivoPie from '../../../components/charts/NivoPie';
import {CallAPI} from "../../../services/apiService";
import { useSelector } from 'react-redux';
import useCookie,{getCookie} from 'react-use-cookie';
import { convertPieNIVO } from "../../../services/utilsService";
import JRCTable from '../../../components/JRCTable/JRCTable';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import RangeSlider from 'react-bootstrap-range-slider';


export default function RDCRankingOutbound(){
    const [client,setClient]=useState('hofer');
    const [startDate, setStartDate ] = useState('2022-01-01');
    const [endDate, setEndDate ] = useState('2022-12-31');
    const [startProcess,setStartProcess] = useState(true);
    const [scope, setScope] = useState('outbound');
    const [prodClassFiltered, setProdClassFiltered] = useState('ALL');
    const [emissionRDC, setEmissionRDC] = useState([])
    const [emissionRDCTable, setEmissionRDCTable] = useState([])


    const [shipmentRDC, setShipmentRDC] = useState([])
    const [shipmentRDCTable, setShipmentRDCTable] = useState([])
    const [distanceCarrier, setDistanceCarrier] = useState([])
    const [weightCarrier, setWeightCarrier] = useState([])
    const [intensityCarrier, setIntensityCarrier] = useState([])
    const [RDCNumber, setRDCNumber ] = useState(8); //default value
    const [ finalValue, setFinalValue ] = React.useState(null);
    const [criteriaOrder,setCriteriaOrder]=useState('SHIPMENTS') //default value
    
    const loginState = useSelector((state)=>state.login);
    const [calculationMethod,setCalculationMethod] = useState('basic');

    const [selectedCountry , setSelectedCountry]=useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] ); 
 
    
    const colRDC = [{'header':'RDC Name','field':'origin_city_rdc','capitalize':true},
    {'header':'Number of shipments', 'sorted':true, 'field':'number_shipments', 'type':'integer'},
    {'header':'Total emission (tCO2e)', 'sorted':true, 'field':'total_emission', 'type':'integer'},
    {'header':'Total distance (km)', 'sorted':true, 'field':'total_distance_km', 'type':'integer'},
    {'header':'Total weight (tonnes)', 'sorted':true, 'field':'total_weight_tonnes', 'type':'integer'},
    {'header':'Average distance in km', 'sorted':true, 'field':'avg_distance_km', 'type':'integer'},
    {'header':'Average weight in tonnes', 'sorted':true, 'field':'avg_weight_tonnes', 'type':'decimal'},
    {'header':'Average emission intensity per tkm ', 'sorted':true, 'field':'avg_emission_intensity', 'type':'integer'}, ];



    //function getting information


    const getEmissionRDC=()=>{
        CallAPI('GET',"/api/visual/v2/top_rdc_outbound/" ,
        {
            client_id:client, start:getCookie('GHG-INITIAL-DATE') || startDate   ,
             end:getCookie('GHG-END-DATE') || endDate ,
            level:calculationMethod, scope:scope, country:getCookie('GHG-DEFAULT-COUNTRY'),
            category:prodClassFiltered,
            top: RDCNumber,criteria:criteriaOrder
        }
        ).then((resp)=>{
            if (resp.status === 200){
                setEmissionRDCTable(resp.data)               
                setEmissionRDC(convertPieNIVO(resp.data,'origin_city_rdc','origin_city_rdc','total_emission' ))
                setShipmentRDC(convertPieNIVO(resp.data,'origin_city_rdc','origin_city_rdc','number_shipments' ))
                setDistanceCarrier(convertPieNIVO(resp.data,'origin_city_rdc','origin_city_rdc','total_distance_km' ))
                setWeightCarrier(convertPieNIVO(resp.data,'origin_city_rdc','origin_city_rdc','total_weight_tonnes' ))
                setIntensityCarrier(resp.data)
            }
        })
    }
 
    const callData=()=>{
  
        if (startProcess===true){
            getEmissionRDC();             
            setStartProcess(false);

        }
    }


    const button_effect=(criteria)=>{
        if (criteriaOrder==criteria){
            return {'backgroundColor':"#cc0000","color":"#ffffff"}
        }
        else{
            return {'backgroundColor':"#002b80","color":"#ffffff"}
        }
    }

    useEffect(() => {
        callData();
      }, [startProcess,selectedCountry]);

    return (
        <>
        <Row>
            <PageTitle title='RDC Ranking (Outbound)'></PageTitle>
            <Col xs={2} md={2} lg={2}>
            <SearchBox client={client} initialStartDate={startDate}
                       initialEndDate={endDate}
                       initialScope={'outbound'} 
                       show_product_class={false}
                       funcCountry={(value)=>setSelectedCountry(value)}
                       funcProductClass={(value)=>setProdClassFiltered(value)}
                       funcEndDate={(value)=>setEndDate(value)}
                       funcStartDate={(value)=>setStartDate(value)}
                       funcProcess={(value)=>{setStartProcess(value)}}

            />
 
        </Col>
        <Col xs={10} md={10} lg={10}>
            <Row>
                <Col xs={6} md={6} lg={6}>
                <Card>
                   
                   <Card.Body>
                   <h5>Number of RDC to show ordered by {}</h5>
                   <ButtonToolbar>
    <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
      <ToggleButton style={button_effect("SHIPMENTS")} key={"SHIPMENTS"} onClick={()=>{setCriteriaOrder("SHIPMENTS"); setStartProcess(true); callData()} }  value={1}>By Shipments</ToggleButton>
      <ToggleButton style={button_effect("EMISSION")}  key={"EMISSION"} onClick={()=>{setCriteriaOrder("EMISSION");setStartProcess(true); callData()}}  value={2}>By Emission</ToggleButton>
      <ToggleButton style={button_effect("WEIGHT")}  key={"WEIGHT"} onClick={()=>{setCriteriaOrder("WEIGHT");setStartProcess(true); callData() } } value={3}>By Weight</ToggleButton>
      <ToggleButton style={button_effect("DISTANCE")}  key={"DISTANCE"} onClick={()=>{setCriteriaOrder("DISTANCE"); setStartProcess(true); callData()}}  value={3}>By Distance</ToggleButton>
    </ToggleButtonGroup>
  </ButtonToolbar>
                   <RangeSlider
                 min={1}
                 max={8}
                 step={1}             
                 tooltip={'on'}
                 value={RDCNumber}
                 onChange={e => setRDCNumber(e.target.value)}
                 onAfterChange={e => {setFinalValue(e.target.value)
                 setStartProcess(true);
                 }}
               />
           
                   </Card.Body>
               </Card>
                </Col>
       
        
          
            </Row>
    
            {intensityCarrier.length > 0 ? <>
                <Row>
      

      <Col xs={6} md={6} lg={6}>
          <Card>
              <Card.Body>
              <NivoPie show_legend={false}
       palette='pastel1'
        show_arc_labels={true}
        label_value='Emission tCO2e'
       description={'The numbers in brackets represent the emissions (in tonnes of C02e) generated by RDC for Outbound (road) transport.'}
       title={'TOP ' + RDCNumber + ': Distribution of emission in tCO2e by RDC share'} 
       data={emissionRDC}/>
              </Card.Body>
          </Card>
      </Col>
      <Col xs={6} md={6} lg={6}>
      <Card>
              <Card.Body>
              <NivoPie show_legend={false}
palette='pastel1'
        show_arc_labels={true}
        label_value='Number of shipments'
       description={'The numbers in brackets represent the number of shipments by RDC for Outbound (road) transport.'}
       title={'TOP ' + RDCNumber + ': Distribution of shipments by RDC share'} 
       data={shipmentRDC}/>
              </Card.Body>
          </Card>
          </Col>            
      </Row>
      <Row>
      <Col xs={6} md={6} lg={6}>
      <Card>
              <Card.Body>
              <NivoPie show_legend={false}
palette='pastel1'
        show_arc_labels={true}
        label_value='Distance in km'
       description={'The numbers in brackets represent the distance driven by RDC for Outbound (road) transport.'}
       title={'TOP ' + RDCNumber + ': Distribution of total distance (km) by RDC share'} 
       data={distanceCarrier}/>
              </Card.Body>
          </Card>

      </Col>
      <Col xs={6} md={6} lg={6}>
      <Card>
              <Card.Body>
              <NivoPie show_legend={false}
palette='pastel1'
        show_arc_labels={true}
        label_value='Weight in tonnes'
       description={'The numbers in brackets represent the total weight (tonnes) transported by RDC for Outbound (road) transport.'}
       title={'TOP ' + RDCNumber + ': Distribution of total weight (tonnes) by RDC share'} 
       data={weightCarrier}/>
              </Card.Body>
          </Card>    
      </Col>            
      </Row>
      <Row>
  <Col xs={12} md={12} lg={12}>
      <Card>
          <Card.Body>
          <JRCTable
          columns={colRDC}
           export_table={true}
           data={intensityCarrier}
           
           item_by_page={10}
           paginate={true}
           show_search={true}
           table_max_height='600px'     
           table_title='Average emission intensity factor by carrier'
          />  
          </Card.Body>
      </Card>


  </Col>
  </Row>
            
            </>:
                
                <Alert variant='danger'>
                    There is not data available with the provided filters, please check them and try again
                </Alert>
                }



        </Col>

        </Row>

        
        </>
    )
}