import React, {useEffect, useState} from 'react'
import {Alert, Button, Form, Modal} from "react-bootstrap";
import useCookie,  {getCookie, setCookie} from 'react-use-cookie';


export default function RegionalConfiguration({show_modal, func_close}){
    const [show, setShow] = useState(show_modal);
    const handleClose = () => {setShow(false)
    func_close(false);
    };

    const [measureSystem, setMeasureSystem] = useCookie('GHG-MEASURE-SYSTEM',  getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL');
    const [measureSeparator, setMeasureSeparator] = useCookie('GHG-MEASURE-SEPARATOR', getCookie('GHG-MEASURE-SEPARATOR') || 'de-DE');
    const [measureDefined, setMeasureDefined] = useCookie('GHG-MEASURE-DEFINED', getCookie('GHG-MEASURE-DEFINED') || null);
    const [system,setSystem]= useState( getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL');
    const [separator,setSeparator] = useState( getCookie('GHG-MEASURE-SEPARATOR') || 'de-DE');


    const saveChanges=()=>{
        setMeasureSystem(system,{
            days:1000
        });
        setMeasureSeparator(separator,{
            days:1000
        });
        setMeasureDefined('YES',
            {
                days:1000
            });
        alert('The page must be reloaded to reflex the changes!!');
        window.location.reload();
        handleClose();
    }

    return (
       <Modal show={show} onHide={handleClose}>
           <Modal.Header closeButton>
               <Modal.Title>Setting regional configuration</Modal.Title>
           </Modal.Header>
           <Modal.Body>
               <Alert variant={'info'}>
                   Measurement System
               </Alert>
               <Form>
               <Form.Group controlId="measureSystem">
                   <Form.Check
                       value="INTERNATIONAL"
                       type="radio"
                       name="measure_group"
                       aria-label="radio 2"
                       label="International (Kilograms, Kilometres,Metric tons)"
                       checked={system==='INTERNATIONAL'?true:false}
                       onChange={(e)=>setSystem(e.target.value)}

                   />
                   <Form.Check
                       value="US_SYSTEM"
                       type="radio"
                       name="measure_group"
                       aria-label="radio 1"
                       checked={system!=='INTERNATIONAL'?true:false}
                       label="US System (Pounds, Miles, Metric tons)"
                       onChange={(e)=>setSystem(e.target.value)}
                   />
               </Form.Group>
                   <Alert variant={'success'}>
                       Number thousand and decimal separators
                   </Alert>
                   <Form.Group controlId="separatorSystem">
                       <Form.Check
                           value="de-DE"
                           type="radio"
                           name="separator_group"
                           checked={separator==='de-DE'?true:false}
                           label="Point (.) for thousand and comma (,) for decimal"
                           onChange={(e)=>setSeparator(e.target.value)}
                       />
                       <Form.Check
                           value="us-EN"
                           type="radio"
                           name="separator_group"
                           checked={separator==='us-EN'?true:false}
                           label="Comma (,) for thousand and point (.) for decimal"
                           onChange={(e)=>setSeparator(e.target.value)}

                       />
                   </Form.Group>

               </Form>

           </Modal.Body>
           <Modal.Footer>
               <Button variant="secondary" onClick={handleClose}>
                   Close
               </Button>
               <Button variant="primary" onClick={saveChanges}>
                   Save Changes
               </Button>
           </Modal.Footer>
       </Modal>
   )


}