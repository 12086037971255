import React,{useState,useEffect} from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import GhgBarDataLabelApex from "../../../components/charts/GhgBarDataLabelApex";

import {CallAPI} from "../../../services/apiService";
import {convertBarDataLabel, convertLeaftMap, formatNumberPosDecimalRegion} from "../../../services/utilsService";
import GhgMap from "../../../components/geomap/GhgMap";
import {useNavigate} from "react-router-dom";
import {getCookie} from 'react-use-cookie';
import { Alert } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';


//TODO: Vincular con la caja de busqueda externa que proporciona los parametros solicitados
export default function DetailSupplierAnalysis({client, start_date, end_date, product_class, start_process,
                                                   finish_process,
                                                    top_routes=20,
                                                   top_suppliers=10}){

    const [emissionSuppliers , setEmissionSuppliers] = useState({data:[],categories:[]})
    const [supplierSelected, setSupplierSelected]=useState('');
    const [rdcSelected, setRdcSelected]=useState()
    const [emissionSupplierDestiny , setEmissionSupplierDestiny] = useState({data:[],categories:[]})
    const [supplierMap, setSupplierMap]=useState({data:[],supplier:''});
    const [topRoutes, setTopRoutes] = useState(top_routes)
    const loginState = useSelector((state)=>state.login);
    const [selectedCountry , setSelectedCountry] = useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );
    const [calculationMethod,setCalculationMethod] = useState('basic');
    const [scope, setScope] = useState('inbound');
    const [supplierProfile,setSupplierProfile] = useState({});

    //Modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
   
    const navigation = useNavigate();

    //General parameters
    let general_parameters={
            client_id:client, start:start_date, end:end_date ,
            level:calculationMethod, scope:scope, country:selectedCountry,
            category:product_class,top:10,
            measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')
    
        }

    //functions get data

    const getEmissionBySupplierDestination=(supplier)=>{
        CallAPI("GET","/api/visual/v2/destiny_by_supplier",
        {
            client_id:client, start:getCookie('GHG-INITIAL-DATE') || start_date, 
            end:getCookie('GHG-END-DATE') || end_date ,
            supplier:supplier,
            level:calculationMethod, scope:scope, country: getCookie('GHG-DEFAULT-COUNTRY') || selectedCountry,
            category:product_class,top:10,
            measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')
    
        }
        ).then((resp)=>{           
            if (resp.status === 200){
                setEmissionSupplierDestiny(convertBarDataLabel(resp.data,'total_emission','destination_rdc'))
            }
        })
    }

    const getEmissionBySupplierDestinationMap=(supplier,top)=>{
        CallAPI("GET","/api/visual/v2/route_map_by_supplier",
        {
            client_id:client, start: getCookie('GHG-INITIAL-DATE') || start_date,
             end: getCookie('GHG-END-DATE') || end_date ,
            supplier:supplier,
            level:calculationMethod, scope:scope, country:getCookie('GHG-DEFAULT-COUNTRY') || selectedCountry,
            category:product_class,top:top,
            measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')
    
        }
        ).then((resp)=>{
            if (resp.status === 200){
                let series=[{'name':'production_facility','latitude_field': 'production_facility_latitude',
                    'longitude_field': 'production_facility_longitude','icon':'production',
                    'state':'production_facility_state', 'city': 'production_facility_city',
                    'label_type':'Production Facility',
                    'origin_latitude':'production_facility_latitude',
                    'origin_longitude': 'production_facility_longitude',
                    'line_color':'white',
                    'line_color_dark':'#333333',
                    'emission_field':null
                },
                    {'name':'supplier','latitude_field': 'supplier_latitude',
                        'longitude_field': 'supplier_longitude', 'icon':'hub' ,
                        'state':'supplier_state', 'city': 'supplier_city',
                        'label_type':'Supplier','origin_latitude':'production_facility_latitude',
                        'origin_longitude': 'production_facility_longitude',
                        'line_color':'#ef5350',
                        'line_color_dark':'#ef5350',
                        'emission_field':'segment_one_emission'
                    },
                    {'name':'rdc','latitude_field': 'rdc_latitude',
                        'longitude_field': 'rdc_longitude', 'icon':'rdc',
                        'state':'destination_state', 'city': 'destination_rdc',
                        'label_type':'RDC',
                        'origin_latitude':'supplier_latitude',
                        'origin_longitude': 'supplier_longitude',
                        'line_color':'#004d00',
                        'line_color_dark':'#004d00',
                        'emission_field':'segment_two_emission'
                    } ];
                 setTopRoutes(top)
                 let data = convertLeaftMap(resp.data['supply_chain'],series);
                 setSupplierMap({data: data , supplier:supplier})
            }
        })
    }

    const getEmissionBySupplier=()=>{
        CallAPI("GET","/api/visual/v2/emission_by_supplier",
        {
            client_id:client, start: getCookie('GHG-INITIAL-DATE') || start_date,
             end: getCookie('GHG-END-DATE') || end_date,
            level:calculationMethod, scope:scope, country:getCookie('GHG-DEFAULT-COUNTRY') || selectedCountry,
            category:product_class,top:10,
            measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')
    
        }
        ).then((resp)=>{
            if (resp.status === 200){
                setEmissionSuppliers(convertBarDataLabel(resp.data,'total_emission','supplier_name'))
            }
        })
    }

    const refreshSupplierMap=()=>{
        if (supplierSelected.length>0){
            getEmissionBySupplierDestination(supplierSelected);
            getEmissionBySupplierDestinationMap(supplierSelected, top_routes);
        }
    }


    const getTopDestinationBySupplier=(value)=>{
        setSupplierSelected(value);
        getEmissionBySupplierDestination(value);
        getEmissionBySupplierDestinationMap(value,top_routes);
    }


    const getData=()=>{
        if (start_process===true){
            refreshSupplierMap();
            getEmissionBySupplier();
            finish_process(false);
        }

    }
    
 const callSupplierDetailPage=()=>{
            navigation({pathname: "/visualization/road_supplier_profile",
            replace: true ,
            search:'?supplier_name=' + supplierProfile?.label});
          
        }

    const redirectSupplierDetail=()=>{
        navigation({pathname: "/visualization/road_supplier_profile",
            search:'?supplier=' + supplierSelected});
    }

    useEffect(() => {
        getData();
    }, [start_process]);



    return (
        <div>
            {
                emissionSuppliers.data.length <1?
                <Alert variant='danger'>There is no data available for the applied filters</Alert>
                : <></>
            }
        

            {/* Modal carrier profile */}

        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Supplier profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <p><span style={{'fontSize':'17px','fontWeight':'bold'}}>Name:</span> {supplierProfile?.label}</p>
         <p><span style={{'fontSize':'17px','fontWeight':'bold'}}>
         Total emission (tCO2e):</span> {formatNumberPosDecimalRegion(supplierProfile?.value,0)}</p>
         <p><span style={{'fontSize':'17px','fontWeight':'bold'}}>
         Percentage of share:</span> {supplierProfile?.formattedValue}</p>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>{
            handleClose();
            callSupplierDetailPage();
          }}>
            Show more details
          </Button>
        </Modal.Footer>
      </Modal>

        <Row>
             <Col xs={6} md={6} lg={6}>
                    <Card style={{'height':'100%'}}>
                        <Card.Body>
                            <GhgBarDataLabelApex
                                title={'TOP 10 suppliers with the highest emission in tCO2e'}
                                subtitle={'This chart shows the destination with higher emission in tCO2e'}
                                data={emissionSuppliers.data}  categories={emissionSuppliers.categories}
                                use_monochrome={true} base_color={'#a7ced4'}
                                mode={'light'}
                                click_function={(v)=>{
                                    getTopDestinationBySupplier(v);
                                }}
                            />
                             <Alert variant="success" style={{ width: "100%" , }}>
                            Click on the supplier you want to analyse
                          </Alert>
                        </Card.Body>

                    </Card>
                </Col>
                <Col xs={6} md={6} lg={6}>
                    <Card  style={{'height':'100%'}}>
                        <Card.Body>
                            <GhgBarDataLabelApex
                                title={'Main destination regional distribution center (RDC) for the supplier ' + supplierSelected}
                                subtitle={'This chart shows the destionation with higher emission in tCO2e for the selected supplier'}
                                data={emissionSupplierDestiny.data}  categories={emissionSupplierDestiny.categories}
                                use_monochrome={true}
                                palette_name={'palette3'}
                                base_color={'#a7ced4'}

                                mode={'light'}
                                click_function={(v)=>{setRdcSelected(v); }}
                            />
                                 <Alert variant="success" style={{ width: "100%" , }}>
                            Click on the RDC to show the position on the map
                             </Alert>
                        </Card.Body>
                    </Card>
                </Col>
        </Row>
     <Row>
                { supplierMap ?
                    <div>

                          <GhgMap   data={supplierMap}  rdc_selected={rdcSelected}
                                  height={'820px'}
                                    show_detail={true}
                                    show_slide={true}
                                    slide_value={topRoutes}
                                    slide_function={getEmissionBySupplierDestinationMap}
                                    title={'TOP ' + topRoutes + ' routes with highest emission for the supplier ' + supplierSelected}
                                    view_function={redirectSupplierDetail}
                                  click_function={(v)=>{setRdcSelected(v) }}
                        />
                    </div>
                : null }

            </Row>


    </div>
    )
}
