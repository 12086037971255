import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { Card } from 'react-bootstrap'
import {InfoCircle} from "react-bootstrap-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GhgToolTip from '../tooltip/GhgTooltip';
import {numberFormat} from "../../services/utilsService";
import { animated } from '@react-spring/web'
import { getCapitalized, formatNumberPosDecimalRegion } from '../../services/utilsService';
export default function NivoPie({data,title,
    pad=1,
    show_arc_labels=true,
    label_value="",
    description,function_click=()=>{}, palette='paired',
height=380, show_legend=true, region=numberFormat()
}){
    let legend_config=[];
    if (show_legend===true){
        legend_config=  [{
            anchor: 'bottom-left',
            direction: 'column',
            justify: false,
            translateX: 0,
            translateY: 0,
            itemWidth: 100,
            itemHeight: 20,
            itemsSpacing: 0,
            symbolSize: 20,
            itemDirection: 'left-to-right'
        }]
    }

    //calculate the total
    let total=0
    data.map((item)=>{
        total+=item['value'];
    });

  return (
    <Card>

        <Card.Body style={{'height':height + 'px'}}>
        <Row>
                <Col md={11} xl={11}>
                <div style={{'textAlign':'left','fontWeight':'bold'}}>
                    <h5>{title}</h5>
                    </div>
                </Col>
                <Col xl={1} md={1} style={{'textAlign':'center'}}>
                  <GhgToolTip title={title}
                  content_explanation={description}
                  />
               </Col>
            </Row>
            <Row style={{marginTop:'10px'}}>
                <Col style={{height:(height - 40) + 'px'}} xs={12} md={12} lg={12}>
                    <ResponsivePie
                        data={data}
                        
                        valueFormat={value =>
                            `${Number(((value/total)*100).toFixed(2)).toLocaleString(region, {
                                minimumFractionDigits: 2,
                            })} %`}
                        colors={{'scheme':palette}}
                        margin={{ top: 25, right: 80, bottom: 50, left: 80 }}
                        innerRadius={0.5}
                        padAngle={pad}
                        sortByValue={true}
                        tooltip={({ datum: { id, formattedValue, value, color } }) => (
                            <div
                                style={{
                                    padding: 12,
                                    color:'#ffffff',
                                    background: '#222222',
                                }}
                            >
                                <strong>{id}</strong>
                                  <br />
                                 <strong>Percentage: {formattedValue}</strong>
                <br />
                               <strong>
                                    {label_value}:  {formatNumberPosDecimalRegion(value,2)}
                                </strong>
                            </div>
                        )}
                        onClick={(item)=> function_click(item)}
                        cornerRadius={4}
                        sliceLabel={(item) => `${item.value}%`}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.6
                                ]
                            ]
                        }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabel={function(e){return getCapitalized(e.id) +" ("+parseInt(e.value).toLocaleString(region, {
                            minimumFractionDigits: 0,
                        })+")"}}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsTextOffset={10}                    

                        arcLabelsSkipAngle={2}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    4
                                ]
                            ]
                        }}
                        enableArcLinkLabels={show_arc_labels}
                        arcLabelsComponent={({ datum, label, style }) => (
                            <animated.g transform={style.transform} style={{ pointerEvents: 'none' }}>
                             
                                <circle fill="#ffffff" stroke={datum.color} strokeWidth={2} r={21} />
                                <text
                                    textAnchor="middle"
                                    dominantBaseline="central"
                                    fill={style.textColor}
                                    style={{
                                        fontSize: 10,
                                        fontWeight: 800,
                                    }}
                                >
                                    {label}
                                    
                                </text>
                            </animated.g>
                        )}

                        legends={legend_config
                        }
                    />
                </Col>

            </Row>


</Card.Body>
</Card>
  )

}
