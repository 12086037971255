import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userId: "guest",
    userName: "Guest",
    userRoleId:-1,
    cognitoSession:null,
    userTokenGraph:"Bearer",
    userAuthenticated:false,
    userCountries:"",
    userSelectedCountry:"",
    userClient:"",
    userListCountries:null
}

export const LoginSlice = createSlice({
    name:"login",
    initialState,
    reducers:{
        //authenticated
        login_authenticated: (state,actions)=>{
            state.userId = actions.payload.userId;
            state.userIdGraph = actions.payload.userIdGraph;
            state.userName = actions.payload.userName;
            state.userRoleId = actions.payload.userRoleId;
            state.cognitoSession = actions.payload.cognitoSession;
            state.userAuthenticated =actions.payload.userAuthenticated;
            state.userCountries = actions.payload.userCountries;
            state.userListCountries = actions.payload.userListCountries;
            state.userSelectedCountry = actions.payload.userSelectedCountry;
            state.userTokenGraph = actions.payload.userTokenGraph;
            state.userClient = actions.payload.userClient;
          
        },
        login_closeSession: (state)=>{
            state.userAuthenticated=false;
            state.userClient='';
            state.userCountries="";
        }
    }

})

export const {login_authenticated , login_closeSession } = LoginSlice.actions

export default LoginSlice.reducer
