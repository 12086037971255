import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { Card } from 'react-bootstrap'
import {InfoCircle} from "react-bootstrap-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GhgToolTip from '../tooltip/GhgTooltip';
import {numberFormat} from "../../services/utilsService";
import { animated } from '@react-spring/web'
import { getCapitalized } from '../../services/utilsService';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';

export default function NivoScatter({data,title,
    pad=1,
    legend_x_position=120,
    axis_y_legend='',
    margin_right_chart=140,
    node_size=16,
    legend_direction='column',
    axis_x_legend ='',
    description,function_click=()=>{}, palette='paired',
height=380, show_legend=true, region=numberFormat()
}){
    let legend_config=[];
    if (show_legend===true){
        legend_config=  [{
            anchor: 'bottom-left',
            direction: legend_direction,
            justify: false,
            translateX: 0,
            translateY: 0,
            itemWidth: 100,
            itemHeight: 20,
            itemsSpacing: 0,
            symbolSize: 20,
            itemDirection: 'left-to-right'
        }]
    }

    //calculate the total
    let total=0
    data.map((item)=>{
        total+=item['value'];
    });

  return (
    <Card>

        <Card.Body style={{'height':height + 'px'}}>
        <Row>
                <Col md={11} xl={11}>
                <h5>{title}</h5>
                </Col>
                <Col xl={1} md={1} style={{'textAlign':'center'}}>
                  <GhgToolTip title={title}
                  content_explanation={description}
                  />
               </Col>
            </Row>
            <Row style={{marginTop:'10px'}}>
                <Col style={{height:(height - 40) + 'px'}} xs={12} md={12} lg={12}>
                     
                    <ResponsiveScatterPlot
        data={data}
        margin={{ top: 60, right: margin_right_chart, bottom: 70, left: 90 }}
        xScale={{ type: 'linear', min: 0, max: 'auto' }}
        xFormat=">-.2f"
        yScale={{ type: 'linear', min: 0, max: 'auto' }}
        yFormat=">-.2f"
        nodeSize={node_size}
        colors={{ scheme: palette }}
        blendMode="multiply"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: axis_x_legend,
            legendPosition: 'middle',
            legendOffset: 46
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: axis_y_legend,
            legendPosition: 'middle',
            legendOffset: -60
        }}
        legends={[
            {
                 toggleSerie: true,
                anchor: 'top-right',
                direction: legend_direction,
                justify: false,
                translateX: legend_x_position,
                translateY: 0,
                itemWidth: 100,
                itemHeight: 12,
                itemsSpacing: 5,
                itemDirection: 'left-to-right',
                symbolSize: 13,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
    />
              
                </Col>

            </Row>


</Card.Body>
</Card>
  )

}
