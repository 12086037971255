import React,{useState,useEffect} from 'react'
import { Alert, Card, Col, Row } from 'react-bootstrap'
import AsyncSelect from 'react-select/async';
import {CallAPI} from "../../../services/apiService";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import GhgBarDataLabelApex from "../../../components/charts/GhgBarDataLabelApex";
import {convertBarDataLabel} from "../../../services/utilsService";
import { convertAPIDateFormat,
 getUnitMeasurement,
 convertlineBarChartData,
 getValueMeasurement, convertLeaftMap, formatNumberPosDecimalRegion } from '../../../services/utilsService';
import msgDialog from '../../../services/dialogService';
import useCookie, {getCookie, setCookie} from 'react-use-cookie';
import GhgMap from "../../../components/geomap/GhgMap";
import PageTitle from '../../../components/pageTitle/pageTitle';
import { useSelector } from 'react-redux';
import GhgBarLineChartApex from '../../../components/charts/GhgBarLineChartApex';
import JRCTable from '../../../components/JRCTable/JRCTable';






export default function DetailProductOutbound(){
const [startDate, setStartDate]=useState(getCookie('GHG-INITIAL-DATE') || '2021-01-01');
const [endDate, setEndDate]=useState(getCookie('GHG-END-DATE') || '2021-06-01');
const [client,setClient] = useState('hofer')
const [countries, setCountries]=useState([]);
const [inputValue, setValue] = useState('');
const [rdcSelected, setRdcSelected]=useState()
const [selectedProduct, setSelectedProduct] = useState(null);
const [productProfile, setProductProfile] = useState(null);
const [emissionSupplier, setEmissionSupplier]=useState({data:[],categories:[]})
const [emissionDestination, setEmissionDestination]=useState({data:[],categories:[]})
const [emissionRoutes,setEmissionRoutes]=useState([]);
const [productMap, setProductMap]=useState({data:[],product:''});
const [topRoutes, setTopRoutes] = useState(10)
const [dataSummary,setDataSummary] = useState([]);
const [cookieInitialDate, setCookieInitialDate] = useCookie('GHG-INITIAL-DATE',null);
const [cookieDefaultCountry, setCookieDefaultCountry] = useCookie('GHG-DEFAULT-COUNTRY',null);
const [cookieEndDate, setCookieEndDate] = useCookie('GHG-END-DATE',null);
const [alertType, setAlertType] = useState('danger');
const [messageAlert,setMessageAlert]=useState('No product selected, please write a product name and select it from the above search box and afte make click on the buttom "Apply filters."')



//TODO: Vincular con caja de busqueda

const [calculationMethod,setCalculationMethod] = useState('basic');
const [scope, setScope] = useState('outbound');
const loginState = useSelector((state)=>state.login);
const [selectedCountry , setSelectedCountry]= useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );

 //variables storage chart data
 const [emissionIntensity, setEmissionIntensity]=useState({series:[], x_axis:[]})
 const [emissionShipments, setEmissionShipments]=useState({series:[], x_axis:[]})
 const [emissionDistance, setEmissionDistance]=useState({series:[], x_axis:[]})
 const [emissionWeight, setEmissionWeight]=useState({series:[], x_axis:[]})

 
 

//table parameters for supplier location
const colTableSummary = [{'header':'Period','field':'month_year'},
{'header':'Total emission kgCO2e','field':'total_emission_kg', 'type':'integer','sorted':true},
{'header':'Total distance in km','field':'total_distance_km', 'type':'integer','sorted':true},
{'header':'Total weight in kg','field':'total_weight_kg', 'type':'integer','sorted':true},
{'header':'Average emission intensity per tkm','field':'avg_emission_intensity', 'type':'decimal','sorted':true}
];


let general_parameters={
 client_id:client, start:startDate, end:endDate ,
 level:calculationMethod, scope:scope, country:selectedCountry,
 product_code:selectedProduct?.product_code,top:topRoutes,
 measurement: (getCookie('GHG-MEASURE-SYSTEM') || 'INTERNATIONAL')

}


const getProductProfile = ()=>{

 if (startDate && endDate && (selectedProduct?.product_code)){
 CallAPI('GET',"/api/visual/v2/product_emission_profile_outbound/" ,general_parameters ).then((resp)=>{
    if (resp.status === 200){
    
    if (resp.data!==null){
        if ( 'product_code' in resp.data){
            setProductProfile(resp.data);
            //getSupplierByProduct();
            getProductEvol();
            }else{
               
            setProductProfile(null)
            setAlertType('danger')
            setMessageAlert('Data not available, for the product ' + selectedProduct.product_description + ' , In the period ' + startDate + ' - ' + endDate + ', please change the criteria filter.') ;
            msgDialog.msgError("Data not found for the searching criteria")
            } 
    }else{
        setProductProfile(null)
        setAlertType('danger')
        setMessageAlert('Data not available, for the product ' + selectedProduct.product_description + ' , In the period ' + startDate + ' - ' + endDate + ', please change the criteria filter.') ;
        msgDialog.msgError("Data not found for the searching criteria")
    }

 }
 })
 }else{
 msgDialog.msgWarning("Please select a product and provide a date range to search")
 }
}




 //Methods get information from the product



const getProductEvol=()=>{
    CallAPI('GET',"/api/visual/v2/evol_outbound_product/" ,general_parameters ).then((resp)=>{
    if (resp.status === 200){
        setDataSummary(resp.data);
                 //emissions intensity
    setEmissionShipments(
        convertlineBarChartData(resp.data, 'month_year', [{name:'Emissions',type:'column', column_value: 'total_emission_kg',
        columns_description: [{'label':'Total Emission (tCO2e)',
        'value':'total_emission_kg'},
         {'label':'Number of shipments','value':'number_shipments'}]
    },
        {name:'Number of Shipments',type:'line', column_value: 'number_shipments',
        columns_description: [{'label':'Total Emission (tCO2e)',
        'value':'total_emission_kg'},
         {'label':'Number of shipments','value':'number_shipments'}]
    }])
        );
         //emissions intensity
    setEmissionIntensity(
            convertlineBarChartData(resp.data, 'month_year', [{name:'Emissions',type:'column', 
            column_value: 'total_emission_kg',
            columns_description: [{'label':'Total Emission (tCO2e)',
                'value':'total_emission_kg'},
                 {'label':'Emission Intensity','value':'avg_emission_intensity'}]
        
        },
            {name:'Emission intensity',type:'line', column_value: 'avg_emission_intensity',
            columns_description: [{'label':'Total Emission (tCO2e)',
            'value':'total_emission_kg'},
             {'label':'Emission Intensity','value':'avg_emission_intensity'}]
        }])
            );

     //distance
    setEmissionWeight(
        convertlineBarChartData(resp.data, 'month_year', 
        [{name:'Emissions',type:'column', column_value: 'total_emission_kg',
        columns_description: [{'label':'Total Emission (tCO2e)',
        'value':'total_emission_kg'},
         {'label':'Average weight in tonnes','value':'total_weight_kg'}]
    
    },
        {name:'Total weight in kilograms',type:'line', column_value: 'total_weight_kg',
        columns_description: [{'label':'Total Emission (tCO2e)',
        'value':'total_emission_kg'},
         {'label':'Average weight in tonnes','value':'total_weight_kg'}]
    }])
        );
    //weight 
    setEmissionDistance(
        convertlineBarChartData(resp.data, 'month_year', [{name:'Emissions',type:'column', column_value: 'total_emission_kg',
        columns_description: [{'label':'Total Emission (tCO2e)',
        'value':'total_emission_kg'},
         {'label':'Average Distance in Km','value':'total_distance_km'}]
    },
        {name:'Total distance km',type:'line', column_value: 'total_distance_km',
        columns_description: [{'label':'Total Emission (tCO2e)',
        'value':'total_emission_kg'},
         {'label':'Average Distance in Km','value':'total_distance_km'}]
    }])
        );
    
    }})}






// handle input change event
const handleInputChange = value => {
 setValue(value);
 };

 // handle selection
 const handleChange = value => {
 setSelectedProduct(value);

 }

 const fetchProducts = () => {
 if (inputValue){
 return CallAPI("GET","/api/visual/v2/product_catalog/" ,{
 client_id: client, product_name: inputValue , country_id: (getCookie('GHG-DEFAULT-COUNTRY') || selectedCountry )
 }).then((result)=>{

 const res = result.data;
 return res;
 })
 } }


 const handleSubmit = event => {
 event.preventDefault(); //
 }

 const check_selected_country =(code)=>{
    if (code === getCookie('GHG-DEFAULT-COUNTRY')){
        return true;
    }else{
        return false;
    }
}


 useEffect(()=>{
 //getCountries();
 setCountries(loginState.userListCountries);
 //set default values for the date
 setStartDate(getCookie('GHG-INITIAL-DATE') );
 setEndDate(getCookie('GHG-END-DATE') ); 
},[selectedProduct])

 return (
 <div>
 <PageTitle title='Product Profile (Outbound)' />
 
 <Row>
 <Col xs={2} md={2} lg={2}>
 <Card>
 <Card.Body>
 <Card.Title>
 Filter options
 </Card.Title>
 <Form onSubmit={handleSubmit} style={{marginTop:'30px'}}>
 <Row>
 <Form.Group className="mb-3" controlId="calculation_method">
 <Form.Label className={'text-muted'}>Operation Country</Form.Label>
 <Form.Select aria-label="Operation Country" onChange={(e)=>{
 setSelectedCountry(e.target.value); 
 setCookieDefaultCountry(e.target.value);
 
 }} >
 { countries.map((item)=>(
 <option  selected={check_selected_country(item.country_code)} key={item.country_code} value={item.country_code}>{item.country_name}</option>
 )) }

 </Form.Select>
 </Form.Group>

 {/* Hidden calculation method
  <Form.Group className="mb-3" controlId="calculation_method">
 <Form.Label className={'text-muted'}>Calculation Method</Form.Label>
 <Form.Select aria-label="Calculation Method" >
 <option value='Basic'>Basic</option>
 </Form.Select>
 </Form.Group>
 
 */}

 
 
 <Form.Group className="mb-3" controlId="scope">
 <Form.Label className={'text-muted'}>Scope</Form.Label>
 <Form.Select aria-label="Scope" >
 <option value='Basic'>Outbound</option>
 </Form.Select>
 </Form.Group>

 <Form.Group className="mb-4" controlId="startDate">
 <Form.Label className={'text-muted'}>Start date</Form.Label>
 <Form.Control required value={startDate } controlid="start_date" type="date" placeholder="Enter the start date"
 onChange={(e)=>{
 setStartDate(convertAPIDateFormat( e.target.value ));
 setCookieInitialDate(convertAPIDateFormat( e.target.value ));
 }}
 />
 <Form.Control.Feedback type="invalid">
 Please select a valid date
 </Form.Control.Feedback>
 </Form.Group>


 <Form.Group className="mb-3" controlId="endDate">
 <Form.Label className={'text-muted'}>End date</Form.Label>
 <Form.Control aria-required={true} required value={endDate} controlid="end_date" type="date" placeholder="Enter the end date"
 onChange={(e)=> {setEndDate(convertAPIDateFormat(
 e.target.value
 ));
 setCookieEndDate(convertAPIDateFormat( e.target.value ));
 }
 //call external function
 } />
 </Form.Group>
 
 <Button style={{marginTop:'35px'}} variant="danger"
 onClick={()=>{

 getProductProfile();

 }}
 >Apply filter</Button>
 </Row>
 </Form>
 </Card.Body>
 </Card> 
 
 </Col>
 <Col xs={10} md={10} lg={10}>
 <Row>
 <Col xs={6} md={6} xl={6}>
 <Card style={{height:'250px'}}>
 <Card.Body>
 <Form.Group>
 <Form.Label className={'text-muted'}>Product to evaluate</Form.Label>
 <AsyncSelect
 cacheOptions
 defaultOptions
 placeholder="Write the product name"
 value={selectedProduct}
 getOptionLabel={e => e.product_description}
 getOptionValue={e => e.id}
 loadOptions={fetchProducts}
 onInputChange={handleInputChange}
 onChange={handleChange}
 />
 </Form.Group>
 </Card.Body>
 </Card>

 </Col>
 <Col xs={6}>
 
 
 <Card style={{height:'250px'}}>
 <Card.Title style={{paddingLeft:'10px', paddingTop:'5px'}}>Product profile (Outboound)</Card.Title>
 <Card.Body >
 <Row>
 <Col style={{'textAlign':'right', 'fontWeight':'bold'}} md={6}>Product class:</Col>
 <Col md={6}>{productProfile?.product_class}</Col> 
 </Row>
 <Row>
 <Col style={{'textAlign':'right', 'fontWeight':'bold'}} md={6}>Product code:</Col>
 <Col md={6}>{productProfile?.product_code}</Col> 
 </Row>
 <Row>
 <Col style={{'textAlign':'right' , 'fontWeight':'bold'}} md={6}>Product description:</Col>
 <Col md={6}>{productProfile?.product_description}</Col> 
 </Row>
 <Row>
 <Col style={{'textAlign':'right' , 'fontWeight':'bold'}} md={6}>Total emission (tCO2e):</Col>
 <Col md={6}>{formatNumberPosDecimalRegion(productProfile?.total_emission,2)}</Col> 
 </Row>
 <Row>
 <Col style={{'textAlign':'right' , 'fontWeight':'bold'}} md={6}>Total shipped volume (t):</Col>
 <Col md={6}>{formatNumberPosDecimalRegion(productProfile?.total_weight_tons,2)}</Col> 
 </Row>
 <Row>
 <Col style={{'textAlign':'right' , 'fontWeight':'bold'}} md={6}>Average distance ({getUnitMeasurement('distance') }):</Col>
 <Col md={6}>{formatNumberPosDecimalRegion(getValueMeasurement('distance',productProfile?.avg_distance_km),0)}</Col> 
 </Row>
 <Row>
 <Col style={{'textAlign':'right' , 'fontWeight':'bold'}} md={6}>Average emission intensity (gCO2e per tkm ):</Col>
 <Col md={6}>{formatNumberPosDecimalRegion(getValueMeasurement('distance',productProfile?.intensity_factor_gram),0)}</Col> 
 </Row>

 </Card.Body>
 </Card>
 
 
 </Col>
 </Row>

 {productProfile == null?
 <Col xs={12} md={12} lg={12}>
 
 <Alert style={{'fontSize':'18px'}} variant={alertType}>{messageAlert}</Alert>
 
 
 </Col>
 : 
 
 

 <Col xs={12} md={12} lg={12}> 
 {/*Bar chart start */}
 
 <Row>
 <Col xs={6} md={6} xl={6}>
 <Card>
 <Card.Body>
 <GhgBarLineChartApex
 title={"Evolution of emission intensity (gCO2e / tkm) of product " + selectedProduct?.product_description}
 x_axis={emissionIntensity?.x_axis } y_axis={'Emission in kilograms CO2e'}
 show_labels={true}
 show_legend={true}
 content_explanation='This chart shows the development of the total Outbound emission for one Product in tCO2e within the selected time frame in relation to the development of the emission intensity factor in gCO2e/tkm. 
 This factor represents the amount of emission expected for each tonne-kilometre travelled. It is calculated by dividing total emission by tonnes x kilometres (tkm).
 '
 y2_axis='Emission intensity (gCO2e)'
 height={400} 
 series_data={emissionIntensity?.series}
 ></GhgBarLineChartApex>
 </Card.Body>
 </Card>
 

 </Col>
 <Col xs={6} md={6} xl={6}>
 <Card>
 <Card.Body>
 <GhgBarLineChartApex
 title={"Evolution of shipments number of product " + selectedProduct?.product_description}
 x_axis={emissionShipments?.x_axis } y_axis={'Emission in kilograms CO2e'}
 show_labels={true}
 content_explanation='This chart shows the development of the total Outbound emission for one Product in tCO2e within the selected time frame in relation to the development of the number of Outbound shipments during the same months. Since the total of transport-related emissions highly depends on the total distance travelled, which correlates strongly with the number of transports, the course of the curves is similar in most cases. '
 show_legend={true}
 y2_axis='Number of shipments'
 height={400} 
 series_data={emissionShipments?.series}
 ></GhgBarLineChartApex>
 </Card.Body>
 </Card>
 

 </Col>
 </Row>
 <Row>
 <Col xs={6} md={6} xl={6}>
 <Card>
 <Card.Body>
 <GhgBarLineChartApex
 title={"Evolution of total driven service distance of product " + selectedProduct?.product_description}
 x_axis={emissionDistance?.x_axis } y_axis={'Emission in kilograms CO2e'}
 show_labels={true}
 height={400} 
 content_explanation='This chart shows the development of the total Outbound emission for one Product in tCO2e within the selected time frame in relation to the development of the average service distance of the transports starting at the RDC. The total of transport-related emissions highly depends on the total distance travelled. The total distance is made up of the number of shipments and the service distance. A different progression of the curves of emission and average service distance should therefore only be possible if the curves of total emission and number of shipments match (in theory).'
 show_legend={true}
 y2_axis='Total driven service distance (km)'

 series_data={emissionDistance?.series}
 ></GhgBarLineChartApex>
 </Card.Body>
 </Card>


 </Col>
 <Col xs={6} md={6} xl={6}>
 <Card>
 <Card.Body>
 <GhgBarLineChartApex
 title={"Evolution of total weight (kilograms) of product " + selectedProduct?.product_description}
 x_axis={emissionWeight?.x_axis } y_axis={'Emission in kilograms CO2e'}
 show_labels={true}
 content_explanation='This chart shows the development of the total Outbound emission for one Product in tCO2e within the selected time frame in relation to the development of the total weight (Kilograms) transported from the RDC for these Outbound shipments. '
 show_legend={true}
 y2_axis='Total weight in kilograms'
 height={400} 
 series_data={emissionWeight?.series}
 ></GhgBarLineChartApex>
 </Card.Body>
 </Card>
 

 </Col>

 </Row>


 {/* End bar chart */}

<Row>
    <Col xs={12} md={12} lg={12}>
        
    <JRCTable
                                    columns={colTableSummary}
                                    export_table={true}
                                    data={dataSummary}
                                    item_by_page={10}
                                    paginate={true}
                                    title={'Detail of emission intensity of product ' + selectedProduct.product_description}
                                    show_title={true}
                                    show_search={true}
                                    table_max_height='100%'     
                                    table_title={'Detail of emission intensity of product ' + selectedProduct.product_description }
                                    /> 
    
    </Col>

</Row>
 

 </Col>
}

 </Col>

 
 
 </Row>

 </div>
 )
}
