import React , {useState,useRef} from 'react';
import {InfoCircle} from 'react-bootstrap-icons'
import {Button, Overlay, OverlayTrigger, Popover} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import GhgToolTip from '../tooltip/GhgTooltip';





export default function KPICard({
background_color, link_color='link-dark',
    title,titleColor,
    value =0,
    value_unit='',
    content_explanation,region='us-EN'

  }) {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    return (
        <div
         style={{
           textDecoration:'none'
         }}
        
         className={`card bg-${background_color} hoverable card-xl-stretch mb-xl-4 ${link_color}`}>
          <div className='card-body'
               style={{
                   maxHeight:'180px',
                   minHeight:'150px',
                backgroundPosition: 'right top',
                backgroundSize: '25% auto',
                backgroundRepeat:'no-repeat'
                   //,
              //  backgroundImage: `url(${process.env.PUBLIC_URL + '/media/svg/shapes/abstract-2.svg' })`,
              }} >
              <Row style={{'marginTop':'0px','marginBottom':'0px','maxHeight':'25px'}}>
                  <Col xs={10} md={10} lg={10}>
                      <p style={{textAlign:'center'}} className='card-title fw-bold text-muted text-hover-primary fs-6'>
                          {title}
                      </p>
                  </Col>
                  <Col xs={2} md={2} lg={2} style={{textAlign:'left' }}>
                                <GhgToolTip title={title}
                                content_explanation={content_explanation}
                                />
                   </Col>
              </Row>
                <div style={{textAlign:'center'}} className={`text-${titleColor} fw-bold fs-2 mb-3 mt-6`}>
                    {value.toLocaleString(region, {
                        minimumFractionDigits: 0,
                    }) + value_unit}
                </div>

          </div>
        </div>
      )
    
}
