import React,{useState, useEffect} from "react"
import { Navigate } from "react-router-dom";
import { useDispatch , useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import  {getCookie, setCookie} from 'react-use-cookie';

export const ProtectedRouter=({children})=>{
    const loginState = useSelector((state)=> state.login);
    if (loginState.userAuthenticated===true && getCookie('GHG-DEFAULT-COUNTRY') ){
                  return children
    }else{
        return (<Navigate to={"/login"}/>)
    }
}
