import React,{useState} from 'react'
import { Form,Modal,Button, Alert } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import msgDialog from '../../services/dialogService'
import { useMutation, gql } from "@apollo/client";
import {CallAPI} from "../../services/apiService";


export default function ChangeJRCPassword({show_modal, func_close}){
    const [show, setShow] = useState(show_modal);
    const [userProfile, setUserProfile] = useState({});
    const [currentPassword,setCurrentPassword] = useState('');
    const [newPassword,setNewPassword] = useState('');
    const [confirmNewPassword,setConfirmNewPassword] = useState('')
    const handleClose = () => {setShow(false)
        func_close(false);
        };
    const loginState = useSelector((state)=>state.login);

    const password_passed=(value)=>{
        if (RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*+-])(?=.{8,})").test(value))
        {
            return true;
        }else{
            return false;
        }

    }





    const saveChanges=()=>{
        CallAPI('POST',"/auth/auth_ghg_core/change_password",
        {'email': loginState.userId,
    'current_password': currentPassword,
    'new_password': newPassword,
    'session': loginState.cognitoSession 
    } ).then((resp)=>{
            if (resp.status === 200){
               let data = resp.data;            
               if (data.result==1){                   
                 msgDialog.msgSuccess("Password was changed successfully!!");
                 setShow(false);
               }   
                  
           }
       })

    }
    const confirmSaveChanges=()=>{
        //saveChanges();
        if (newPassword!==confirmNewPassword ){
            msgDialog.msgError("New password and the confirmation do not match!!");
            return false

        }
        if (password_passed(newPassword)==false){
            msgDialog.msgError("Password does not comply with the minimun security, please define a new one ");
            return false
          }
          saveChanges();

    }
    
    return (
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Change password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
                <Form>
                    <Form.Group>
                        <Form.Label>User</Form.Label>
                        <Form.Control value={loginState.userId} type='text'></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Current password</Form.Label>
                        <Form.Control onChange={e=>setCurrentPassword(e.target.value)} type='password'></Form.Control>
                    <Form.Text className='text-mude'>* Please write your current password </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>New password</Form.Label>
                        <Form.Control onChange={e=>setNewPassword(e.target.value)} type='password'></Form.Control>
                    <Form.Text className='text-mude'>** Register a passsword with at least 8 characters , using letters and numbers </Form.Text>
                    </Form.Group>
                    <Form.Group style={{marginTop:'6px'}}>
                <Alert variant='info'>The new password must comply with the security requirements: <li>Minimum 8 characters length</li><li>Include an upper and lower case</li>
                   <li>Include a number and a special symbol (!@#$%^&*+-) </li>
             </Alert>
                </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirm new password</Form.Label>
                        <Form.Control onChange={e=>setConfirmNewPassword(e.target.value)} type='password'></Form.Control>
                    <Form.Text className='text-mude'>*** Re-enter the new password</Form.Text>
                    </Form.Group>
                    {newPassword!==confirmNewPassword ?<Form.Group>
                        <Alert variant='danger'>New password and the confirmation are differents</Alert>
                        </Form.Group>
                    : <></>}
                   
                </Form>
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={handleClose}>
                   Close
               </Button>
               <Button variant="primary" onClick={confirmSaveChanges}>
                   Change password
               </Button>
           </Modal.Footer>
        </Modal>
    )
}