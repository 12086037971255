import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import CoreRouter from './coreRouter';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <React.StrictMode>
       <CoreRouter/>   
    <ToastContainer/>
  </React.StrictMode>,
  document.getElementById('root')
);