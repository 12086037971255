import React, {useState,useRef} from "react";
import {InfoCircle} from 'react-bootstrap-icons'
import {Button, Overlay, OverlayTrigger, Popover} from "react-bootstrap";



export default function GhgToolTip({title,content_explanation}){
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    return (
        <div>
             <InfoCircle onClick={handleClick} style={{textAlign:'right', color:'#00b2cc'}}   color='#00b2cc' size={'30px'}/>
             <div ref={ref}>
        <Overlay
            show={show}
            target={target}
            placement="bottom"
            container={ref}
            containerPadding={20}
            rootClose
            onHide={() => setShow(false)}
        >
            <Popover id="popover-contained">
                <Popover.Header as="h3"  style={{background:'#90a4ae', textAlign:'center'}}>{title}</Popover.Header>
                <Popover.Body style={{textAlign:'justify'}}>
                    { content_explanation}
                </Popover.Body>
            </Popover>
        </Overlay>
    </div>
        </div>
       
    )

}
