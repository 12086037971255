import React, {useState} from 'react'
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useSearchParams} from "react-router-dom";
import 'react-bootstrap-accordion/dist/index.css'
import DetailProductClass from './road/DetailProductClass';
import SearchBox from "../../components/searchBox/searchBox";
import { useSelector } from 'react-redux';
import useCookie,{getCookie} from 'react-use-cookie';




export default function RoadDetailed(){
    const [searchParams, setSearchParams] = useSearchParams()
    const [client,setClient]=useState('hofer');
    const [startDate, setStartDate ] = useState(searchParams.get('start_date'));
    const [endDate, setEndDate ] = useState(searchParams.get('end_date'));
    const [startProcess,setStartProcess] = useState(true);
    const [prodClassFiltered, setProdClassFiltered] = useState('ALL');
    const loginState = useSelector((state)=>state.login);
    const [selectedCountry, setSelectedCountry] = useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );
    

    return (
        <div>
            <Row>
                <Col xs={2} md={2} lg={2}>
                    <SearchBox client={client} initialStartDate={startDate}
                               initialEndDate={endDate}
                               initialScope='inbound'
                               funcProductClass={(value)=>setProdClassFiltered(value)}
                               funcEndDate={(value)=>setEndDate(value)}
                               funcStartDate={(value)=>setStartDate(value)}
                               funcCountry={(value)=>setSelectedCountry(value)}
                               funcProcess={(value)=>{setStartProcess(value)}}
                    />
                <Card style={{ width: '100%', marginBottom:'10px'}}>

            </Card>
                </Col>
                <Col xs={10} md={10} lg={10}>
                    <Card style={{marginBottom:'5px'}}>
                        <Card.Body>
                                <h4>
                                    Road transportation emission analysis (Inbound)
                                </h4>
                  </Card.Body>
                    </Card>
                <DetailProductClass start_date={startDate}
                                    product_class={prodClassFiltered}
                                    start_process={startProcess}
                                    reset_process={(value)=>setStartProcess(value)}
                                    client={client}
                                    country_code={selectedCountry}
                                    end_date={endDate}/>
                </Col>
            </Row>


           
         </div>
    )

}

