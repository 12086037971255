import React,{useEffect, useState} from 'react'
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card'
import {Alert, Button} from "react-bootstrap";
import { Col, Row } from 'react-bootstrap'
import PageTitle from '../../components/pageTitle/pageTitle'
import { useSelector } from 'react-redux';
import KPICard from "../../components/statistic/KPICard";
import useCookie,  {getCookie} from 'react-use-cookie';
import msgDialog from '../../services/dialogService';
import * as Icon from 'react-bootstrap-icons';
import {CallAPI} from "../../services/apiService";
import NivoPie from '../../components/charts/NivoPie';
import {
    convertAPIDateFormat,
    convertPieChartData,
    convertAreaChartData,
    convertlineBarChartData,
    getLabelMeasurement,
    getValueMeasurement,
    convertPieNIVO, formatNumberPosDecimalRegion, getTopRecordsPie, getUnitMeasurement,
} from "../../services/utilsService";
import NivoBar from '../../components/charts/NivoBar';
import NivoCalendar from '../../components/charts/NivoCalendar';
import GhgAreaChartApex from '../../components/charts/GhgAreaChartApex';

export default function OutboundDashboard(){
    const [startDate, setStartDate ] = useState(getCookie('GHG-INITIAL-DATE') || '2021-01-01');
    const [endDate, setEndDate ] = useState(getCookie('GHG-END-DATE') || '2023-06-30');
    const [countries, setCountries]=useState([]);
    const [client,setClient] = useState('hofer');
    const loginState = useSelector((state)=>state.login);
    const [scope, setScope] = useState('outbound');
    const [statRoad, setStatRoad] = useState({});
    const [topCarrier,setTopCarrier] = useState([])
    const [topCarrierArea,setTopCarrierArea] = useState([])
    const [emissionByDate, setEmissionByDate]= useState([{}]);
    const [topRDC,setTopRDC] = useState([])
    const [emissionProductClassLineBar, setEmissionProductClassLineBar] = useState({series:[], x_axis:[]})
    const [topProductClass,setTopProductClass] = useState([])
    const [calculationMethod,setCalculationMethod] = useState('basic');
    const [emissionProductClassNivo, setEmissionProductClassNivo] = useState([])
    const [dataTableProductClass, setDataTableProductClass] = useState([{}]);
    const [cookieInitialDate, setCookieInitialDate] = useCookie('GHG-INITIAL-DATE',null);
    const [cookieEndDate, setCookieEndDate] = useCookie('GHG-END-DATE',null);
    const [selectedCountry , setSelectedCountry] = useState(getCookie('GHG-DEFAULT-COUNTRY') || loginState?.userListCountries[0]['country_code'] );
    const [dataAvailable, setDataAvailable ] = useState(false); 


    const check_selected_country =(code)=>{
        if (code === getCookie('GHG-DEFAULT-COUNTRY')){
            return true;
        }else{
            return false;
        }
    }



    const check_outbound_exist=()=>{
        CallAPI('GET',
            '/api/visual/v2/check_index_exist/',
            {
                client_id:client,
                level:calculationMethod,
                 scope:'outbound',
                 transport:'road',
                  country:selectedCountry
                        
            } 
            ).then((resp)=>{                          
                if (resp.status === 200){
                    if (resp.data['exist']===true){                                           
                        setDataAvailable(true);
                        getScopeStat();
                        getTopCarrier();
                        getTopRDC();
                        getTopProductClass();
                        getEmissionByProductClass();
                        getEmissionByDate();
                    }else{
                        setDataAvailable(false);
                    }
                                     
                }
        })
    }



    const getScopeStat=()=>{
        CallAPI('GET',
            '/api/visual/v2/stats_inbound_outbound/',
            {
                client_id:client,
                    start:startDate, end:endDate , level:calculationMethod, scope:scope, country:selectedCountry
                        
            } 
            ).then((resp)=>{
                if (resp.status === 200){
                    if (resp.data['total_emission']>0){
                        setStatRoad(resp.data);   
                    }else{
                        setDataAvailable(false);
                    }
                
                                   
                }
        })
    }


    const getTopCarrier=()=>{
        CallAPI('GET',
            '/api/visual/v2/evol_top3_outbound_carriers/',
            { client_id:client, start:startDate, end:endDate , level:calculationMethod, scope:scope, country:selectedCountry
                        
            } 
            ).then((resp)=>{
                if (resp.status === 200){                            
                    setTopCarrier(resp.data);
                    setTopCarrierArea(convertAreaChartData(resp.data['area_data'],'carrier_name','month_number','total_emission'))                    
                } })    }

    const getTopRDC=()=>{
                    CallAPI('GET',
                        '/api/visual/v2/evol_top3_outbound_rdc/',
                        { client_id:client, start:startDate, end:endDate , level:calculationMethod, scope:scope, country:selectedCountry
                                    
                        } 
                        ).then((resp)=>{
                            if (resp.status === 200){                            
                                setTopRDC(resp.data);                    
                            } })    }
    
    const getTopProductClass=()=>{
                                CallAPI('GET',
                                    '/api/visual/v2/evol_emission_product_class/',
                                    { client_id:client, start:startDate, end:endDate , level:calculationMethod, scope:scope, country:selectedCountry } 
                                    ).then((resp)=>{
                                        console.log(resp.data);
                                        if (resp.status === 200){                            
                                            setTopProductClass(resp.data);                    
                                        } })    }


    const getEmissionByProductClass=()=>{
                                     
                                            CallAPI('GET',"/api/visual/v2/outbound_product_class/" ,
                                            { client_id:client, start:startDate, end:endDate , country:selectedCountry , 'level': calculationMethod } 
                                            ).then((resp)=>{
                                                if (resp.status === 200){                                                  
                                                        setEmissionProductClassNivo(convertPieNIVO(resp.data,'product_category','product_category','total_emission' ))
                                                }
                                            })
                                        }

    const getEmissionByDate=()=>{
                                            CallAPI('GET',"/api/visual/v2/emission_by_date",
                                            { category:'ALL', client_id:client, start:startDate,
                                            scope: 'outbound',
                                            end:endDate ,
                                             country:selectedCountry , 'level': calculationMethod } 
                                          
                                            ).then((resp)=>{
                                                if (resp.status === 200){
                                                    setEmissionByDate(resp.data);
                                                }
                                            })
                                    
                                        }

    const callGetData=()=>{
                    if (!startDate || !endDate){
                        msgDialog.msgError("Please provide a valid date for the start and end period");
                        return false;
                    }
                    check_outbound_exist();
            
                    let countries_list = loginState.userListCountries;
                    setCountries(countries_list);
                   
                }
            
    useEffect(()=>{
        if (!client){
            setClient(loginState.userClient);
        }
        callGetData();
        //check_outbound_exist();
           
    },[])




    return (
        <>
        <div>
            <Row>
                <PageTitle title='Outbound emission'></PageTitle>
                <Col xs={2} md={2} lx={2}>
                           {/* Date parameters card */}
       <Card className={'position-fixed'}  style={{ width: '16%' }}>
                <Card.Body>
                    <Card.Subtitle>
                        Filtering options
                    </Card.Subtitle>
                        <Form style={{marginTop:'30px'}}>
                            <Form.Group className="mb-3" controlId="calculation_method">
                                <Form.Label className={'text-muted'}>Operation Country</Form.Label>
                                <Form.Select aria-label="Operation Country" onChange={(e)=>setSelectedCountry(e.target.value)} >
                                    { countries.map((item)=>(
                                        <option selected={check_selected_country(item.country_code)} key={item.country_code} value={item.country_code}>{item.country_name}</option>
                                    )) }

                                </Form.Select>
                            </Form.Group>

                            {/* Hidden calculation method
                            
                                             <Form.Group className="mb-3" controlId="calculation_method">
                                        <Form.Label className={'text-muted'}>Calculation Method</Form.Label>
                                        <Form.Select aria-label="Calculation Method" onChange={(e)=> setCalculationMethod(e.target.value) } >
                                            <option selected  value='basic'>Basic</option>
                                        </Form.Select>
                                    </Form.Group>
                            
                            */}
                            
                   

                        

                            <Form.Group className="mb-3" controlId="scope">
                                <Form.Label className={'text-muted'}>Scope</Form.Label>
                                <Form.Select aria-label="Scope" onChange={(e)=> setScope(e.target.value)} >
                                    <option selected value='outbound'>Outbound</option>
                                </Form.Select>
                            </Form.Group>
                                              
                                    <Form.Group className="mb-4" controlId="startDate">
                                        <Form.Label className={'text-muted'}>Start date</Form.Label>
                                        <Form.Control value={startDate} controlid="start_date" type="date" placeholder="Enter the start date"
                                        onChange={(e)=>{
                                            setStartDate(convertAPIDateFormat( e.target.value ));
                                            setCookieInitialDate(e.target.value );

                                        }}
                                        />
                                    </Form.Group>
                         
                                    <Form.Group className="mb-3" controlId="endDate">
                                        <Form.Label className={'text-muted'}>End date</Form.Label>
                                        <Form.Control value={endDate} controlid="end_date" type="date" placeholder="Enter the end date"
                                        onChange={(e)=> {setEndDate(convertAPIDateFormat(e.target.value));
                                            setCookieEndDate(e.target.value);}
                                        }
                                        />
                                    </Form.Group>
                      
                                    <Button style={{marginTop:'20px', width:'100%'}} variant="danger"
                                    onClick={callGetData}
                                    >Apply filter</Button>
                                    <Form.Group>
                                    <Form.Text className="text-muted">
                                        Please select a calculation method and a time frame
                            </Form.Text>
                            </Form.Group>
                        </Form>
                </Card.Body>
            </Card>
            {/* Ending Card parameters */}
                </Col>

                {/* Section body start */}
                <Col xs={10} md={10} lg={10}>
                <Row style={{marginTop:'0px'}}>
                <Col xs={12} xl={12} md={12}>
                            { dataAvailable === false ?
                      <Alert variant='danger' style={{fontSize:'18px'}}>There is not data available for the current country: {selectedCountry} and the period between {startDate} and {endDate}</Alert>
                 : <>
                    <Card>
                        <Card.Body>
                        <Card.Title>Summary KPIs for road outbound emission</Card.Title>
                            <Row style={{paddingTop:'10px'}}>
                                <Col md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statRoad.outbound?.total_emission,2)}
                                    title='Total emission in tCO2e'
                                    content_explanation='Sum of the Greenhouse gas emission expressed in tCO2e for Outbound (road) transports.'
                                /></Col>

<Col  md={2}  xs={3}><KPICard
                                        className='card-xl-stretch mb-xl-4'
                                        color='white'
                                        background_color='white'
                                        titleColor={'dark'}
                                        link_color='link-light'
                                        descriptionColor={'secondary'}
                                        value={formatNumberPosDecimalRegion(statRoad.outbound?.total_processed_shipments,2)}
                                        title='Number of shipments processed'
                                        content_explanation='Number of (road) Outbound shipments processed in the selected time frame.'
                                    />
                                </Col>

                                          <Col md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion(statRoad.outbound?.avg_transport_activity_thousand,2)}
                                    title='Average transport activity (tkm) in thousands'
                                    content_explanation='The transport activity is a commonly used indicator to measure how much total weight was transported over the total distance, expressed in thousands of tonne-kilometres. For Outbound transports the value is calculated by multiplying kilometres driven with the shipped volume in tonnes (Tonne-kilometre = driven km * shipped volume in tonnes) PER TRANSPORT LEG. The value shows the average transport activity of the Outbound shipment legs. Further details and a calculation example can be found in the methodology tab.'
                                /></Col>
                                   <Col  md={2}  xs={3}><KPICard
                                    className='card-xl-stretch mb-xl-4'
                                    color='white'
                                    background_color='white'
                                    titleColor={'dark'}
                                    link_color='link-light'
                                    descriptionColor={'secondary'}
                                    value={formatNumberPosDecimalRegion( getValueMeasurement('distance', statRoad.outbound?.avg_emission_intensity),2)}
                                    title={'Average emission intensity (gCO2e / tkm)'}
                                    content_explanation={'This KPI shows the emission intensity resulting from the calculation of Outbound (road) transport emission, where the total emission, expressed in gCO2e, are divided by the tonnes x kilometres. This value represents the amount of emission expected for each tonne-kilometre travelled.'}
                                />
                                </Col>
                            </Row>
                            <Row>
                            <Col xs={3}>

                             </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                     {/* charts sections */}
 <Row>
<Col xs={6} md={6} lg={6}>
<Card>
<Card.Body>
<NivoBar
x_legend='Month - Year'
palette='set2'
y_legend='Emission tCO2e'
show_values={false}
x_series={topCarrier?.carriers}
description={'This graph shows the development of the CO2e (in tonnes) released by the three highest emitting carriers for Outbound (road) transport.'}
title={"Emission of TOP 3 carrier with highest emission (tCO2e) "} 
data={topCarrier?.data}></NivoBar>
</Card.Body>
</Card>

</Col>

<Col xs={6} md={6} lg={6}>
<Card>
<Card.Body>
    
<NivoBar
palette='pastel1'
x_legend='Month - Year'
y_legend='Emission tCO2e'
x_series={topRDC?.rdc}
show_values={false}
description={'This graph shows the development of the CO2e (in tonnes) released by the three highest emitting RDCs for Outbound (road) transport.'}
title={"Evolution of emission of TOP 3 RDCs with highest emission (tCO2e)"} 
data={topRDC?.data}></NivoBar>
    </Card.Body>    
    </Card>
</Col>
</Row>

 {/* end charts section */}
       

          

{/* charts sections */}
<Row>
<Col xs={6} md={6} lg={6}>

<Card>
<Card.Body>
<NivoBar
x_legend='Month - Year'
y_legend='Emission tCO2e'
show_values={false}
x_series={topProductClass?.products_category}
description={'This graph shows the development of the CO2e (in tonnes) released by each Product Class for Outbound (road) transport.'}
title={"Evolution of emission of product classes (tCO2e)"} 
data={topProductClass?.data}></NivoBar>

</Card.Body>
</Card>

</Col>
<Col xs={6} md={6} lg={6}>
    <Card>
        <Card.Body>
        <NivoPie show_legend={true}
    palette='paired'
              show_arc_labels={false}
              label_value='Emission tCO2e'
             description={'This chart shows the distribution of emissions for Outbound (road) transport by product class.'}
             title={'Distribution of emission per product class (tCO2e)'} 
             data={emissionProductClassNivo}/>
        </Card.Body>
    </Card>

 

</Col>


</Row>

                 </> 
                }
                 
                </Col>

            </Row>





{/* end charts section */}
           <Row>

            </Row>


                </Col>


                {/*Section body end */}

            </Row>
        </div>
        </>
    )
}