import React from 'react'
import Chart from 'react-apexcharts';
import GhgToolTip from "../tooltip/GhgTooltip";
import {formatNumberPosDecimal, formatNumberPosDecimalRegion} from "../../services/utilsService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getCookie} from 'react-use-cookie';


export default function GhgBarLineChartApex({series_data=[{data:[]}], 
    height=350,
    mode='light',
    show_labels=true,
    title, x_axis, y_axis,
    y2_axis="",
    base_color,
    show_legend=false,
    content_explanation='',
    use_monochrome, palette_name='palette6'
}){

    const region = getCookie('GHG-MEASURE-SEPARATOR') || 'de-DE';
   

      const options ={
        theme: {
            mode: mode, 
            palette: palette_name, 
            monochrome: {
                enabled: use_monochrome,
                color: base_color,
                shadeTo: mode,
                shadeIntensity: 0.70
            },
        },
        chart: {
          height: height,
          type: 'line',
          stacked: false,
            toolbar: {
                show: false
            },
        },
        dataLabels: {
          enabled: show_labels,
          formatter: function (value) {
            return formatNumberPosDecimal(value,0).toLocaleString(region, {
                minimumFractionDigits: 0,
            })
        }
        },
        stroke: {
          width: [1, 4]
        },
         noData: {
          text: 'Not data to show...'
        }, 
        
        legend: {
            show:show_legend,
            offsetX: 0,
            offsetY: 0,
            position: 'bottom',
            horizontalAlign: 'center',
            labels: {         
                useSeriesColors: false
            }
        
          },
        xaxis: {
          categories: x_axis,
        },
        yaxis: [
          {
              offsetY:-60,
              labels: {
                  style: {
                      colors: '#008FFB',
                  },
                  formatter: function (value) {
                      return formatNumberPosDecimal(value,0).toLocaleString(region, {
                          minimumFractionDigits: 0,
                      })
                  }
              },

            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            title: {
              text: y_axis,
              style: {
                color: '#008FFB',
              }
            }            
          },
          {
            offsetY:-60,
            labels: {
                style: {
                    colors: '#e65c00',
                },
                formatter: function (value) {
                    return formatNumberPosDecimal(value,0).toLocaleString(region, {
                        minimumFractionDigits: 0,
                    })
                }
            },

          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#e65c00'
          },
          opposite:true,
          title: {
            text: y2_axis,
            style: {
              color: '#e65c00',
            }
          }            
        }


        ],
        tooltip: {
          custom: function({series, seriesIndex, dataPointIndex, w}) {
             
            let _descriptions = series_data[seriesIndex].descriptions.filter((item)=> item.id===dataPointIndex)
      
       
            return ('<div style="margin-bottom:20px;">' +
            '<div style="text-align:left; margin-left:20px; padding-top:20px; font-size:15px; font-weight:bold">Category: '+   x_axis[dataPointIndex] + '</div></br>' +
            _descriptions.map((item)=>{
              return '<div style="text-align:left; margin-left:20px; margin-top:1px; margin-bottom:0px"><span style="font-weight:bold;">' + item.label + '</span>  : <span style="padding-right:40px">' + formatNumberPosDecimalRegion(item.value,2) + '</span></div>'
            
          }) +
              '</div>')
          },
     
              fixed: {
            enabled: true,
            position: 'topleft', // topRight, topLeft, bottomRight, bottomLeft
         
          },
        }
      };

    return (
        <div style={{textAlign:'right'}}>
            <Row>
                <Col md={11} lg={11} xs={11}>
                <h5  style={{'textAlign':'left', 'fontWeight':'normal'}}>{title}</h5>
   
                </Col>
                <Col md={1} lg={1} xs={1}>
                    <GhgToolTip title={title}
                                content_explanation={content_explanation}
                    />
                </Col>
            </Row>


            <Chart  options={options}
             series={series_data} type='line' height={height}  />
        </div>

    )

}
