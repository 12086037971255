import { FeatureGroup,Popup, Polyline,TileLayer,MapContainer,
Marker} from 'react-leaflet'

import {Alert, Button, Figure} from "react-bootstrap";
import {Icon} from 'leaflet'
import React, {useRef, useState,useEffect} from 'react'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet';
import aldiPNG from './markers/aldi.png';
import factoryMarker from './markers/red_marker.png';
import storageMarker from './markers/green_marker_3.png';
import {Card} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css";
import "leaflet-arrowheads";
import { AntPath, antPath } from 'leaflet-ant-path';
import './map.scss'
import '@elfalem/leaflet-curve';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import RangeSlider from 'react-bootstrap-range-slider';

import useCookie,{getCookie} from 'react-use-cookie';

import 'mapbox-gl/dist/mapbox-gl.css';



export default function GhgMap({data=[{}], height='500px',
                              view_function=()=>{},
                                title,
                                show_detail,
                                show_slide,
                                slide_value,
                                slide_function,
                               rdc_selected=''}
                               ){

    const [map, setMap] = useState(null);
    const polyRef = useRef();
    const mapdata = data.data;
    const [showConnections, setShowConnections]=useState(true);
    const [supplier,setSupplier ] = useState(data.supplier);
    const [ value, setValue ] = useState(slide_value);
    const [ finalValue, setFinalValue ] = React.useState(null);

    const getName=(type)=>{
        if (type==='Supplier'){
           return 'Supplier: ' + data.supplier
       }else{
           return type
       }

   }

    const getIcon=(type, rdc)=>{
        let name=''
        if (type=='production'){
            name=factoryMarker
            return new Icon({iconUrl: name, iconSize: [38, 33], iconAnchor: [12, 41]})
        }
        if (type=='hub'){
            name=storageMarker
            return new Icon({iconUrl: name, iconSize: [26, 30], iconAnchor: [12, 41]})
        }

        if (type=='rdc'){
            name=aldiPNG
            if (rdc === rdc_selected){
                return new Icon({iconUrl: name, iconSize: [38, 43], iconAnchor: [12, 41],
                className:'blinking'
                })
            }
            else {
                return new Icon({iconUrl: name, iconSize: [28, 32], iconAnchor: [12, 41]})
            }
        }

    }


   const showScale=(map)=>{
       if (map){
           L.control.scale({
               position: 'topright'
           }).addTo(map);
       }


   }

    const showPath=(item2)=>{
        if (showConnections){
            return   <Polyline positions={[[item2.origin_latitude,item2.origin_longitude],
                [item2.latitude, item2.longitude]]} color={item2.line_color}  dashArray={'10,5'} weight={1.5} />

        }
        }

    const processSlide=(value)=>{
       slide_function(data.supplier,value);
    }

    //show the center according the selected country

    const getPosition =()=>{
        if (getCookie('GHG-DEFAULT-COUNTRY')==='us'){
            return [34.032928974, -117.641452011]
        }
        if (getCookie('GHG-DEFAULT-COUNTRY')==='at'){
            return [47.8788125, 6.5423944]
        }

    }


    // RandomMarkerInMapBounds
    const RandomMarkerInMapBounds = ({ map }) => {
             useEffect(() => {

            if (!map) return;


            for(let i in map._layers) {
                if(map._layers[i]._path != undefined) {
                    try {
                        map.removeLayer(map._layers[i]);

                    }
                    catch(e) {
                        console.log("problem with " + e + map._layers[i]);
                    }
                }
            }
            for(let i in map._controls) {
                if(map._controls[i]._path != undefined) {
                    try {
                        map.removeLayer(map._controls[i]);

                    }
                    catch(e) {
                        console.log("problem with " + e + map._layers[i]);
                    }
                }
            }


            mapdata.map((item)=>{
              item.detail.map((item2)=>{
                  //funcionality just show

                 // let arrow_line = L.polyline([[item2.origin_latitude, item2.origin_longitude],[item2.latitude, item2.longitude]],
                   //   { color: item2.line_color, weight: 1.5,
                     //     dashArray:[5,6]
                 // })
                     // .arrowheads({ fill: true,  size:'11px' })
                   //   .bindPopup("Test", { maxWidth: 200 });

                 // L.control.layers(null, {
                   //       "Direction ": arrow_line.addTo(map)})

                 // const options = { use: L.polyline, delay: 500, dashArray: [10,20], weight: 3, color: item2.line_color, pulseColor: "#FFFFFF",
                   //   "hardwareAccelerated": true
                 //};
                  //const path = antPath([[item2.origin_latitude, item2.origin_longitude],[item2.latitude, item2.longitude]], options);
                  //path.addTo(map);

                  //Curve
                  const route=['M',[item2.origin_latitude, item2.origin_longitude],
                      'Q',[((item2.origin_latitude + item2.latitude )/2 -0.2 ), (item2.origin_longitude + item2.longitude )/2],
                      [item2.latitude, item2.longitude]]
                  const path2 = antPath(route, {
                      "use": L.curve,

                      "delay": 550,
                      "dashArray": [
                          10,
                          20
                      ],
                      "weight": 3.5,
                      "color": item2.line_color,
                      "pulseColor": "#FFFFFF",
                      "paused": false,
                      "reverse": false,
                      "hardwareAccelerated": false
                  });
                  path2.addTo(map);


              })
            })

        }, []);

        return null;
    };

  const showDetailButton =()=>{
      if (show_detail==true  && data.supplier){
          return ( <Button variant={'success'} onClick={view_function}> View detail</Button>)
      }else{
          return null;
      }
  }

  const showSlider=()=>{
      if (show_slide==true && data.supplier){
          return (
              <div>
                  <span className={'text-danger'}>Move the slider to show more routes (max 50 routes)</span>
                  <RangeSlider
                  min={1}
                  max={50}
                  step={1}
                  tooltip={'on'}
                  value={value}
                  onChange={e => setValue(e.target.value)}
                  onAfterChange={e => {setFinalValue(e.target.value)
                  slide_function(data.supplier,e.target.value);
                  }}
                />
            </div>
          )
      }else{
          return null
      }
  }

    return (
        <div>
          <Card>
              <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  { !mapdata ?
                         <Row>                 
                         <Col xs={12} md={12} lg={12}>
                             <Alert variant='danger'>Not available geographic data to show</Alert>
                         </Col>
                       </Row>  :
                       <>
                              <Row>
                      <Col xs={8} md={8} lg={8} style={{marginLeft:'5px'}}>
                           {showSlider()}
                      </Col>
                      <Col style={{textAlign:'right'}}>
                          {showDetailButton()}

                      </Col>
                  </Row>


                  <MapContainer
                      whenCreated={setMap}
                      id='mapID' center={getPosition()} zoom={4} scrollWheelZoom={true}
                                style={{ height:'550px', width:"100%",marginTop:"20px", marginBottom:'20px'
                                }}
                  >
                      <TileLayer
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      {

                          mapdata.map((item,index)=>(
                              item.detail.map((item2,index2)=>(
                                  <FeatureGroup>
                                  <Marker icon={getIcon(item2.icon, item2.popup.city )}  key={'marker_' + item2.id} position={[item2.latitude, item2.longitude]}>
                                      <Popup id={'popup_'+ item2.id } key={'popup_'+ item2.id } >
                                          <p  id={'p_'+ item2.id } key={'p_'+ item2.id } style={{fontWeight:'bold'}}>{getName(item2.popup.type)}</p>
                                          State:  {item2.popup.state} <br/>
                                          City: {item2.popup.city}<br/>
                                          Emission (tCO2E): {item2.popup.emission}
                                      </Popup>
                                  </Marker>


                                  </FeatureGroup>
                              ))
                          ))
                      }

                        <FullscreenControl />
                      <RandomMarkerInMapBounds map={map} />
                  </MapContainer>  
                  <div>
                      <Row>
                            <Col md={2}>
                              <Figure style={{paddingRight:'12px', textAlign:'center'}}>
                              <Figure.Image
                              width={40}
                              height={50}
                              alt="171x180"
                              src={factoryMarker}
                              />
                              <Figure.Caption>
                              Production facility location
                              </Figure.Caption>
                              </Figure>
                              </Col>
                          <Col md={2}>
                              <Figure style={{paddingRight:'12px', textAlign:'center'}}>
                                  <Figure.Image
                                      width={30}
                                      height={40}
                                      src={storageMarker}
                                  />
                                  <Figure.Caption>
                                      Hub location
                                  </Figure.Caption>
                              </Figure>
                          </Col>
                          <Col md={2}>
                              <Figure style={{paddingRight:'12px', textAlign:'center'}}>
                                  <Figure.Image
                                      width={40}
                                      height={50}
                                      alt="171x180"
                                      src={aldiPNG}
                                  />
                                  <Figure.Caption style={{fontsize:'10px'}}>
                                      RDC
                                  </Figure.Caption>
                              </Figure>
                          </Col>
                           </Row>
                  </div>


                       </>                       
                       }
           
           

            

      
              </Card.Body>
          </Card>

        </div>




    )

}
